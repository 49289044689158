import {BaseModel} from './base.model';
import {UserSessionInterface} from '../interfaces/user-session.interface';
import {UserRoles} from '../configs/user-config';

export class UserSession extends BaseModel implements UserSessionInterface {
  constructor(props?) {
    super(props);

    this.syncProps(props);
  }
  static isChanged: boolean;
  id = null;
  companyId: string;
  companyType: UserRoles;
  functionAuthorities: string;
  locked: boolean;
  loginId: string;
  name: string;
  otherRegionAuthority: number;
  providerBranchId: number;
  userId: number;
  role: string;
  token = '';
  isChanged: boolean;

  // get isSystemAdmin(): boolean {
  //   return this.permission_id === UserRoles.ADMIN;
  // }
  //
  // get isHospitalAdmin(): boolean {
  //   return this.permission_id === UserRoles.HOSPITAL_ADMIN;
  // }
  //
  // get isNormalUser(): boolean {
  //   return this.permission_id === UserRoles.NORMAL_USER;
  // }
  //
  // get isValidUser(): boolean {
  //   return this.lock_status === UserState.INACTIVE;
  // }
  //
  // get isInvalidUser(): boolean {
  //   return this.lock_status === UserState.ACTIVE;
  // }
  //
  // get role(): UserRoles {
  //   return this.permission_id;
  // }

}
