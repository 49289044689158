  <div class=" receipt-form " >
    <div class="row no-gutters align-items-center" style="margin-top: 20px">
      <div class="col text-center">
        <h2 style="font-size: 24px; font-family: 900;">積込情報</h2>
      </div>
    </div>

    <div class="input-group "   >
      <span class="input-group-text custom-text p-2 fontsize" style="height: 40px;border: none">出荷地登録名称<span>:</span></span>
      <input type="text" class="form-control  custom-input custom-border  py-3 ps-0 " aria-label="Username"
             [value]="item.shipmentLocationName" style="font-size: 12px;  height: 40px;border: none !important; width: auto">
    </div>
    <div class="input-group "  >
      <span class="input-group-text custom-text p-2 fontsize" style="height: 40px; border: none">出荷地登録コード<span>:</span></span>
      <input type="text" class="form-control  custom-input custom-border py-3" aria-label="Username"
             style="font-size: 12px; height: 40px; border: none !important;" [value]="item.shipmentLocationCode">
    </div>


    <div class="input-group " style="margin-bottom: 3px" >
      <span class="input-group-text custom-text p-2 fontsize" style="height: 40px; border: none">積込日<span>:</span></span>
      <input type="text" class="form-control  custom-input custom-border  py-3 ps-0 " aria-label="Username"
             [value]="item.deliveryDateTime" style="font-size: 12px;  height: 40px;border: none !important; ">

      <span class="input-group-text custom-text p-2 fontsize" style="height: 40px;border: none">積込終了時間<span>:</span></span>
      <input type="text" class="form-control  custom-input custom-border  py-3 ps-0 " aria-label="Username"
             [value]="item.startTime" style="font-size: 12px;  height: 40px;border: none !important; max-width: 60px">
    </div>

    <div class="row no-gutters items-group align-items-center m-0 border-content mb-1"    *ngIf="!authService.isDelivery">
      <div class="col-12" style="padding-left: 0; border-left: 1px solid; padding-bottom: 5px !important;">
        <div class="d-flex"  >
          <div class="form-right" style="width: 100% !important; padding-bottom: 5px !important;">
            <div class="form-item border-0 m-1">
              <span class="fontsize">【供給会社入力欄】</span>
            </div>
            <div class="form-item border-0 m-1" >
                            <span class="span">
                                <span class="fontsize">仕入先コード</span>
                                <span>:</span>
                            </span>
              <span class="text-overflow-ellipsis"  style="padding-left: 10px;font-size: 12px !important;"> {{item.saleMangementCompanyCode +'&#x3000;' + '&#x3000;' + item.saleMangementCompanyName}}</span>
            </div>

            <div class="form-item border-0 my-2 " style="padding-left: 5px; margin-bottom: 5px">
                            <span class="span">
                                <span class="fontsize">仕入先メモ</span>
                                <span >:</span>
                            </span>
              <span class="text-overflow-ellipsis"  style="padding-left: 10px;width: 100% !important;font-size: 12px !important;">{{item.vendorMemo}}</span>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="input-group" style="margin-bottom: 8px;margin-top: 5px !important;">
      <span class="input-group-text custom-text p-0" style="height: 40px;padding-left: 3px !important;">
        <span class="" style="font-size: 12px; ">品名</span>
      </span>
      <input type="text" class="form-control  custom-input custom-border p-2 " aria-label="Username"
             style="font-size: 16px; height: 40px;" [value]="item.productName">
      <span class="input-group-text custom-text p-0" style="height: 40px; padding-left: 3px !important;">
        <span class="" style="font-size: 12px; ">積込<br>数量</span>
      </span>
      <input type="text" class="form-control  custom-input custom-border-2 text-end " aria-label="Username"
             [value]="item.amountPrint ? item.amountPrint : item.amount" style="max-width: 80px; font-size: 16px; height: 40px;">
      <span class="input-group-text custom-kg fontsize " style="height: 40px;">kg</span>
    </div>


    <div class="row  align-items-stretch  m-0"  >
      <div class="col-12 " style="padding-left: 0;height: 178px;" >
        <div class=" image p-1" style="width: 103%;  border: 1px solid #000 !important" >
          <img *ngIf="item.imagePath" class="image-item "
                style="max-width: 100%;max-height: 100%;object-fit: contain;"
                src="{{urlImgOn}}{{item.imagePath}}">
        </div>
      </div>
    </div>

    <div class="row no-gutters items-group align-items-center m-0 border-content mb-1" style="border-left: 1px solid !important; padding-left: 5px !important;">
      <div class="col-12 p-0" style="font-size: 12px; ">
        <div class="row">
          <div class="col-12" style="padding-left: 5px !important; border-left: 1px solid">
          <div class="d-flex">
            <div class="form-right">
              <div class="form-item border-0 m-1">
                              <span class="span">
                                  <span class="fontsize">配送会社</span>
                                  <span>:</span>
                              </span>
                <span class="" style="padding-left: 10px;">{{item.deliveryCompanyName}}</span>

              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="row">
          <div class="col-5" style="padding-left: 5px !important; border-left: 1px solid">
            <div class="d-flex">
              <div class="form-right">

                <div class="form-item border-0 m-1 " >
                                <span class="width-span">
                                    <span class="fontsize">車番</span>
                                    <span>:</span>
                                </span>
                  <span class=""  style="padding-left: 5px;"> {{item.vehicleCode}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-7" style="padding-left: 0; ">
            <div class="d-flex">
              <div class="form-right">
                <div class="form-item border-0 m-1 " >
                            <span class="width-span" style="  width: 70px; !important;">
                                <span class="fontsize">乗務員名</span>
                                <span>:</span>
                            </span>
                  <span class=""  style="padding-left: 5px;">{{item.userName}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
