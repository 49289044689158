import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/api-service/auth.service';
import {UserRoles} from '../../../configs/user-config';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-detail-layout',
  templateUrl: './detail-layout.component.html',
  styleUrls: ['./detail-layout.component.scss']
})
export class DetailLayoutComponent implements OnInit {
  themeForUser = '';
  userRole = UserRoles;
  titleComponent = '';

  constructor(private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.titleComponent = this.route.snapshot.data.title ? this.route.snapshot.data.title : this.route.snapshot.firstChild.data.title;
    if (localStorage.getItem('typeForm') === 'receipt') {
        this.titleComponent = '受領書';
    } else if (localStorage.getItem('typeForm') === 'non-delivery') {
      this.titleComponent = '未納入報告書';
    }
    if (this.router.url === '/detail/gas-filling-form') {
      this.titleComponent = this.route.snapshot.data.title ? this.route.snapshot.data.title : this.route.snapshot.firstChild.data.title;
    }
  }

}
