import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListLayoutComponent} from './list-layout/list-layout.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ShareModule} from '../../share/share.module';
import { DetailLayoutComponent } from './detail-layout/detail-layout.component';
import { DevelopingComponentComponent } from './developing-component/developing-component.component';

const layouts = [
  ListLayoutComponent,
  NotFoundComponent,
  DetailLayoutComponent
];

@NgModule({
  declarations: [layouts, DevelopingComponentComponent],
  exports: [layouts],
  imports: [
    CommonModule,
    ShareModule
  ]
})
export class LayoutModule {
}
