<div class="modal fade" [ngClass]="{'show show-modal': showEditPass}" *ngIf="userFormGroup" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{'CHANGE_PASSWORD.TITLE'|translate}}</h5>
<!--        <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--          <span aria-hidden="true">&times;</span>-->
<!--        </button>-->
      </div>
      <form #ngForm="ngForm" [formGroup]="userFormGroup" (ngSubmit)="onSubmit()">
      <div class="modal-body">
        <div class="px-mobile-3 px-tablet-6">
<!--          <div class="has-text-centered has-text-danger my-3" *ngIf="error">{{('ERRORS.' + error) | translate}}</div>-->
          <div class="form-group row mt-1">
            <label for="current_password" class="col-sm-4 col-form-label">{{'CHANGE_PASSWORD.USER_NAME'|translate}}:</label>
            <div class="col-sm-8">
              <span>{{userData.name}}</span>
            </div>
          </div>
          <div class="form-group row mt-1">
            <label for="current_password" class="col-sm-4 col-form-label">{{'CHANGE_PASSWORD.OLD_PASSWORD'|translate}}:</label>
            <div class="col-sm-8">
              <input type="password" class="form-control" id="current_password" formControlName="current_password"
                     maxlength="20" noSpace
                     [ngClass]="{'border border-danger': ngForm.submitted && formControl('current_password').errors}">
              <div class="text-danger" *ngIf="ngForm.submitted && formControl('current_password').errors">
                <div *ngIf="userFormGroup.hasError('required', 'current_password')">{{'MESS.CV0001'|translate: {label: errOldPass} }}</div>
              </div>
            </div>
          </div>
          <div class="form-group row mt-1">
            <label for="new_password" class="col-sm-4 col-form-label">{{'CHANGE_PASSWORD.NEW_PASSWORD'|translate}}:</label>
            <div class="col-sm-8">
              <input type="password" class="form-control" id="new_password" formControlName="new_password"
                     maxlength="20" noSpace
                     [ngClass]="{'border border-danger': ngForm.submitted && (formControl('new_password').errors|| userFormGroup.hasError('matchNewPass'))}">
              <div class="text-danger" *ngIf="ngForm.submitted && (formControl('new_password').errors|| userFormGroup.hasError('matchNewPass'))">
                <div *ngIf="userFormGroup.hasError('required', 'new_password')">{{'MESS.CV0001'|translate: {label: errNewPass} }}</div>
                <div *ngIf="formControl('new_password').getError('errFormat')">{{'MESS.MS0014'|translate}}</div>
                <div *ngIf="userFormGroup.get('new_password').valid && userFormGroup.hasError('matchNewPass')">{{'MESS.CV0010' | translate}}</div>
              </div></div>
          </div>
          <div class="form-group row mt-1">
            <label for="password" class="col-sm-4 col-form-label">{{'CHANGE_PASSWORD.NEW_PASSWORD_CONFIRMATION'|translate}}:</label>
            <div class="col-sm-8">
              <input type="password" class="form-control" id="password" formControlName="new_confirm_password"
                     maxlength="20" noSpace
                     [ngClass]="{'border border-danger': ngForm.submitted && (formControl('new_confirm_password').errors || userFormGroup.hasError('matchPass'))}">
              <div class="text-danger" *ngIf="ngForm.submitted && (formControl('new_confirm_password').errors || userFormGroup.hasError('matchPass'))">
                <div *ngIf="userFormGroup.hasError('required','new_confirm_password')">{{'MESS.CV0001'|translate: {label: errNewPasswordConfirmation} }}</div>
                <div *ngIf="userFormGroup.get('new_confirm_password').valid && userFormGroup.hasError('matchPass')">{{'MESS.CV0008' | translate}}</div>
              </div></div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
<!--        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>-->
        <button type="submit" class="btn btn-primary">{{'CHANGE_PASSWORD.SAVE'|translate}}</button>
      </div>
      </form>
    </div>
  </div>
</div>



