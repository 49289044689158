<app-header [titleComponent]="titleComponent"></app-header>
<div class="custom-header-body-content master-css">
  <div id="wrapper">
    <div id="page-content-wrapper">
      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
