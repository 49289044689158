import { AfterViewInit, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { FloatNumberPipe } from './pipe/float-number.pipe';
import { CurrencyNumberPipe } from './pipe/currency-number.pipe';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationComponent } from './components/pagination/pagination.component';
import { GlobalLoadingComponent } from './components/global-loading/global-loading.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { SearchSelectComponent } from './components/search-select/search-select.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { InputNoSpaceDirective } from './directives/input-no-space.directive';
import { TabIndexDirective } from './directives/tab-index.directive';
import { ReceiptFormComponent } from './components/receipt-form/receipt-form.component';
import { HeaderComponent } from './components/header/header.component';
import {
  NgbDateAdapter,
  NgbDateParserFormatter, NgbDatepickerI18n,
  NgbDatepickerModule, NgbModalModule, NgbModule, NgbTimepickerModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { SearchDeliveryComponent } from './components/search-delivery/search-delivery.component';
import { MatDialogModule } from '@angular/material/dialog';
import {
  CustomAdapter,
  CustomDateParserFormatter,
  CustomDatepickerI18n, I18n
} from './service/custom-date-parser-formatter.service';
import { SortableDirective } from './service/sortable.directive';
import { TemplatePrintTableComponent } from './components/template-print-table/template-print-table.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DetailDeliveryPrintComponent } from './components/detail-delivery-print/detail-delivery-print.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NonDeliveryReportComponent } from './components/non-delivery-report/non-delivery-report.component';
import { TemplatePrintReceiptComponent } from './components/receipt-form/template-print-receipt/template-print-receipt.component';
import { TempaltePrintNonDeliveryReportComponent } from './components/non-delivery-report/tempalte-print-non-delivery-report/tempalte-print-non-delivery-report.component';
import { TemplatePrintGasFillingComponent } from './components/gas-filling-form/template-print-gas-filling/template-print-gas-filling.component';
import { DynamicTitlePrintComponent } from './components/dynamic-title-print/dynamic-title-print.component';
import { PrintReportComponent } from './components/detail-delivery-print/print-report/print-report.component';
import { PrintOrderComponent } from './components/detail-delivery-print/print-order/print-order.component';
import { PrintGasComponent } from './components/detail-delivery-print/print-gas/print-gas.component';
import { EditVendorComponent } from './components/gas-filling-form/template-edit-vendor/edit-vendor.component';
import { DeliveryPrintComponent } from './components/detail-delivery-print/print-delivery/delivery-print.component';
import { NoteReceiptComponent } from './components/receipt-form/note-receipt/note-receipt.component';
const components = [
  NumberInputComponent,
  PaginationComponent,
  GlobalLoadingComponent,
  ConfirmationComponent,
  SearchSelectComponent,
  AutocompleteComponent,
  ReceiptFormComponent,
  HeaderComponent,
  SearchDeliveryComponent,
  TemplatePrintTableComponent,
  DetailDeliveryPrintComponent,
  TemplatePrintReceiptComponent,
  NonDeliveryReportComponent,
  BreadcrumbComponent,
  TempaltePrintNonDeliveryReportComponent,
  TemplatePrintGasFillingComponent,
  PrintReportComponent,
  PrintOrderComponent,
  DeliveryPrintComponent,
  PrintGasComponent,
  EditVendorComponent,
];

const directives = [
  InputNoSpaceDirective,
  NumberOnlyDirective,
  TooltipDirective,
  TabIndexDirective,
  SortableDirective
];

const pipes = [
  CurrencyNumberPipe,
  FloatNumberPipe
];

const libraries = [
  CommonModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  TranslateModule,
  OverlayModule,
  NgbTooltipModule,
  NgbDatepickerModule,
  InfiniteScrollModule,
  NgbTimepickerModule,
  NgbModalModule,
  MatDialogModule
];

@NgModule({
  declarations: [
    components,
    directives,
    pipes,
    DynamicTitlePrintComponent,
    NoteReceiptComponent,
  ],
  imports: [
    libraries,
    NgbModule
  ],
  exports: [
    libraries,
    components,
    directives,
    pipes
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
  ]
})
export class ShareModule {
}
