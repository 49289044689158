import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { URL_IMG } from 'src/app/configs/const';
@Component({
  selector: 'app-template-print-receipt',
  templateUrl: './template-print-receipt.component.html',
  styleUrls: ['./template-print-receipt.component.scss']
})
export class TemplatePrintReceiptComponent implements OnInit, AfterViewInit {
  @Input() dataInput: any;
  @Input() titleHeader: any;
  @Input() labelCompanyName: any;
  @Output() printDone = new EventEmitter();
  dataTitle;
  urlImgOn = URL_IMG;
  arrayData = [];
  constructor() {
  }

  ngOnInit(): void {

    for (let i in this.titleHeader) {
      let list = { title: this.titleHeader[i] }
      this.arrayData.push(Object.assign({}, this.dataInput, list))
    }
    if (this.dataInput.quantityMeasuringMethod === 'A') {
      this.dataTitle = "ローリーメーター";
    } else if (this.dataInput.quantityMeasuringMethod === 'B') {
      this.dataTitle = "台貫";
    } else if (this.dataInput.quantityMeasuringMethod === 'C') {
      this.dataTitle = "その他";
    } else if (this.dataInput.quantityMeasuringMethod === 'D') {
      this.dataTitle = "検針";
    }


  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      window.print()
    }, 1000);
  }

  @HostListener('window:afterprint', ['$event.target'])
  afterprint(btn): void {
    setTimeout(() => {
      this.printDone.emit();
    }, 50);
  }

}
