import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { URL_IMG } from 'src/app/configs/const';
import {AuthService} from '../../../../services/api-service/auth.service';
@Component({
  selector: 'app-print-gas',
  templateUrl: './print-gas-v2.component.html',
  styleUrls: ['./print-gas-v2.component.scss']
})
export class PrintGasComponent implements OnInit {
  @Input() detail: boolean;
  @Input() item: any;
  @Input() title: any;
  weekday;
  urlImgOn = URL_IMG;
  constructor(
    public authService: AuthService,
  ) { }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    const day = moment(this.item.deliveryDate).locale('ja');
    this.weekday = day.format('dd');

  }

  showTitle(): string {
    if (this.item.quantityMeasuringMethod === 'A') {
      return 'ローリーメーター';
    } else if (this.item.quantityMeasuringMethod === 'B') {
      return '台貫';
    } else if (this.item.quantityMeasuringMethod === 'C') {
      return 'その他';
    } else if (this.item.quantityMeasuringMethod === 'D') {
      return '検針';
    }
  }

}
