import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { URL_IMG } from 'src/app/configs/const';
import {AuthService} from '../../../../services/api-service/auth.service';
@Component({
  selector: 'app-template-print-gas-filling',
  templateUrl: './template-print-gas-filling.component.html',
  styleUrls: ['./template-print-gas-filling.component.scss']
})
export class TemplatePrintGasFillingComponent implements OnInit, AfterViewInit {
  @Input() detail: boolean;
  @Input() dataInput: any;
  @Input() partnerCompanyName: any;
  @Input() userCompanyName: any;
  @Input() titleHeader: any;
  @Input() labelCompanyName: any;
  @Output() printDone = new EventEmitter();
  dataTitle;
  urlImgOn = URL_IMG;
  arrayData = [];
  constructor(public authService: AuthService) {
  }

  ngOnInit(): void {
    for (let i = 0; i <= this.dataInput.length - 1; i++) {
      // this.dataInput[i].vendorCode = this.dataInput[i].vendorCodeName;
      this.arrayData.push(Object.assign({}, this.dataInput[i]));
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      window.print();
    }, 1000);
  }

  @HostListener('window:afterprint', ['$event.target'])
  afterprint(btn): void {
    setTimeout(() => {
      this.printDone.emit();
    }, 50);
  }

}
