import {AbstractControl, FormArray, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {cloneDeep, findIndex, omit} from 'lodash';

export enum DATA_ORDER {
  ASC, DESC
}

export class BaseModel {
  _form: FormGroup = new FormGroup({});
  private _updateSub = new Subscription();

  /**
   * use if list manage,  quick get current index
   */
  _isFormChange = new BehaviorSubject(false);
  private _changeSub = new Subscription();

  constructor(props?) {
  }

  getControl(controlName): AbstractControl {
    return this._form.get(controlName);
  }

  setControl(controlName, value) {
    this._form.controls[controlName] = value;
  }

  syncProps(props?, updateForm = true) {
    if (props) {
      Object.keys(props).forEach(key => {
        if (this.hasOwnProperty(key)) {
          this[key] = props[key];
        }
      });

      if (updateForm) {
        this.syncForm(props);
      }
    }

    return this;
  }

  syncForm<T>(data: T) {
    this._form.patchValue(data, {emitEvent: false});

    return this;
  }

  syncFormItem(name, value) {
    this._form.get(name).patchValue(value);

    return this;
  }

  setRealtimeUpdate(flag) {
    if (flag) {
      this._updateSub = this._form.valueChanges.subscribe(val => {
        this.syncForm(val);
      });
    } else {
      this._updateSub.unsubscribe();
    }
  }

  updateFormToModel() {
    this.syncForm(this._form.getRawValue());

    return this;
  }

  startDetectChange(flag = false) {
    this._isFormChange.next(flag);
    this._changeSub = this._form.valueChanges.subscribe(() => {
      this._isFormChange.next(true);
    });
  }

  stopDetectChange() {
    this._changeSub.unsubscribe();
    this._isFormChange.next(false);
  }

  rawValue() {
    const del = ['_form', '_updateSub', '_isFormChange', '_changeSub'];
    const loop = (data) => {
      Object.keys(data).map(name => {
        if (data[name] && typeof data[name] == 'object' && 'cloneModel' in data[name]) {
          data[name] = loop(data[name]);
        }
      });

      data = omit(data, del);

      return data;
    };

    return loop(this.cloneModel());
  }

  cloneModel() {
    return cloneDeep(this);
  }
}

// todo change to Generics
export class BaseModelManager {
  items = [];
  streamItem = new BehaviorSubject(this.items);
  _forms: FormArray = new FormArray([]);
  currentItem;
  currentIndex: number;
  onSelectItem = new BehaviorSubject(null);

  fillItems<T extends BaseModel>(data: T[], order = DATA_ORDER.ASC, createForm = false) {
    this.items = [];
    if (data.length) {
      this.appendItems(data, order, createForm);
    } else {
      this.streamItem.next([]);
    }
  }

  appendItems<T extends BaseModel>(data: T[], order = DATA_ORDER.ASC, createForm = false) {
    data.forEach(item => {
      this.addNew(item, order, createForm);
    });
  }

  addNew(item?, order = DATA_ORDER.ASC, createForm = false) {
    if (order == DATA_ORDER.ASC) {
      this.items.push(item);
      if (createForm) {
        this._forms.push(item._form);
      }
    } else {
      this.items.unshift(item);
      if (createForm) {
        this._forms.controls.unshift(item._form);
      }
    }

    this.streamItem.next(this.items);

    return this;
  }

  update<T extends BaseModel>(index: number, item: T, formUpdate = false) {
    this.items[index].syncProps(item, formUpdate);

    return this;
  }


  getItem<T extends BaseModel>(index): T {
    return this.items[index];
  }

  destroy(index: number, removeForm = false) {
    this.items.splice(index, 1);
    if (removeForm) {
      this._forms.removeAt(index);
    }

    this.streamItem.next(this.items);

    return this;
  }

  /**
   * remove selected item
   */
  unselectItem() {
    if (this.currentItem) {
      this.currentItem = null;
      this.currentIndex = null;
      this.onSelectItem.next(null);
    }
    return this;
  }

  /**
   * take item by index in list
   * @param index
   */
  selectItemByIndex(index) {
    if (index >= 0 && this.items.length && this.items.length > index) {
      this.currentItem = this.items[index];
      this.currentIndex = index;

      this.onSelectItem.next(this.currentItem);
    }

    return this;
  }

  /**
   * first item in  list
   * @param id
   */
  selectItemById(id: number) {
    const index = findIndex(this.items, {id});
    if (index >= 0) {
      this.selectItemByIndex(index);
    } else {
      this.selectFirstItem();
    }

    return this;
  }

  selectFirstItem() {
    this.selectItemByIndex(0);

    return this;
  }

  /**
   * Last item in list
   */
  selectLastItem() {
    this.selectItemByIndex(this.items.length - 1);

    return this;
  }

  updateDataSearchPaginate(data, search = false, nextPage = false, focusFirst = false) {
    return new Observable(subscriber => {
      if (search) {
        this.fillItems(data);
        subscriber.next(this.items);
      } else {
        if (nextPage) {
          this.appendItems(data);
        } else {
          this.fillItems(data);
        }

        subscriber.next(this.items);
      }
      setTimeout(() => {
        if (focusFirst) {
          if (!nextPage) {
            this.selectFirstItem();
          }
        }
        subscriber.complete();
      });
    });
  }
}
