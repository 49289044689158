<button type="button" class="btn btn-link"
        [disabled]="pager.currentPage === 1"
        (click)="setPage(1)">
  {{'pagination.first_page' | translate}}
</button>
<button type="button" class="btn btn-link"
        [disabled]="pager.currentPage === 1"
        (click)="setPage(pager.currentPage - 1)">
  {{'pagination.back' | translate}}
</button>

<button type="button" class="btn"
        [ngClass]="pager.currentPage === page ? 'btn-outline-blue': 'btn-link'"
        *ngFor="let page of pager.pages"
        (click)="setPage(page)">
  {{page}}
</button>

<button type="button" class="btn btn-link"
        [disabled]="pager.currentPage === pager.totalPages"
        (click)="setPage(pager.currentPage + 1)">
  {{'pagination.next' | translate}}
</button>
<button type="button" class="btn btn-link"
        [disabled]="pager.currentPage === pager.totalPages"
        (click)="setPage(pager.totalPages)">
  {{'pagination.last_page' | translate}}
</button>
