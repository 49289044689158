import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PagingInterface} from '../../interfaces/paging.interface';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/share/service/loading.service';



@Injectable({
  providedIn: 'root'
})
export class BaseApi {
  constructor(protected http: HttpClient,
              protected router: Router,
              protected loading: LoadingService) { }

  private apiUrl = environment.apiUrl;
  protected prefixURL = '';

  /** region pristine api */
  public get(url, param?: any): Observable<any> {
    return this.http.get(this.fullURL(url) + '?' + this.extendSearchParams(param));
  }

  public post(url: string, body: any, param?: any): Observable<any> {
    return this.http.post(this.fullURL(url), body, {params: param});
  }

  public put(url: string, body: any, param?: any): Observable<any> {
    return this.http.put(this.fullURL(url), body, {params: param});
  }

  protected patch(url: string, body: any, param?: any): Observable<any> {
    return this.http.patch(this.fullURL(url), body, {params: param});
  }

  protected delete(url: string, param?: any): Observable<any> {
    return this.http.delete(this.fullURL(url), {params: param});
  }

  protected download(url: string, body: any = {}): Observable<any> {
    return this.http.post(this.fullURL(url), body, {responseType: 'blob', observe: 'response'});
  }

  protected downloadByGet(url: string): Observable<any> {
    return this.http.get(this.fullURL(url), {responseType: 'blob', observe: 'response'});
  }

  // endregion

  /** region RESTFUL api */

  getAll(pagingParam?: PagingInterface | any): Observable<any> {
    return this.get('', pagingParam);
  }

  getDetail(id: number): Observable<any> {
    return this.get(`${id}`);
  }

  create(newObj): Observable<any> {
    return this.post(``, newObj);
  }

  update(updateObj): Observable<any> {
    return this.put(`${updateObj.id}`, updateObj);
  }

  remove(id): Observable<any> {
    return this.delete(`${id}`);
  }

  // endregion

  /** region Extension */
  public fullURL(url?: string): string {
    let fullUrl = this.apiUrl;
    if (this.prefixURL) {
      fullUrl += '/' + this.prefixURL;
    }
    if (url) {
      fullUrl += '/' + url;
    }
    return fullUrl;
  }

  protected extendSearchParams(param?: object): string {
    const p = new URLSearchParams();
    if (param && typeof param === 'object') {
      Object.keys(param).map(key => {
        p.append(key, param[key]);
      });
    }
    return p.toString();
  }

  // endregion
}
