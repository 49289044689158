import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Injectable({providedIn: 'root'})
export class BasicFunctionService {
  private deliveryCompanySearch: any = {sort: {}, filters: {}};
  private hadRouteToDetail = false;
  private hadCallApi = false;

  getErrStr(err: Array<any>, translate: TranslateService): string {
    if (err.length) {
      return err.map(item => {
        return translate.instant('LINE_X', {line: item.line}) + ':' + '\n' +
          item.lstErrorData.map(v => translate.instant('MESS.' + v.messageError, {label: v.fieldNameJP})).join('\n');
      }).join('\n');
    }
  }

  get getDeliveryCompanySearch(): any {
    return this.deliveryCompanySearch;
  }

  setDeliveryCompanySearch(data: { sort?: object, filters?: object }): void {
    this.deliveryCompanySearch = data;
  }

  get getStatusChangeRoute(): boolean {
    return this.hadRouteToDetail;
  }

  setStatusChangeRoute(data: boolean): void {
    this.hadRouteToDetail = data;
  }

  get getStatusHadCallApi(): boolean {
    return this.hadCallApi;
  }

  setStatusHadCallApi(data: boolean): void {
    this.hadCallApi = data;
  }

}
