<div class="content-form">
  <div class="mt-2">
    <app-breadcrumb [breadcrumbData]="listBreadcrumb"></app-breadcrumb>
  </div>
  <div class="header-content mt-2">
    <div class="act-vertical-buttons" [class]="(checkViewOtherProvider || checkEditOtherProvider)? '' : 'none'">
      <button [disabled]="!statusBtn.editBtn" *ngIf="(!modeEdit || !disableEditFormDelivery) && checkCanEdit"
        class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2"
        (click)="saveReceipt(statusReceipt.REGISTERED)">{{'BTN.REGISTRATION' | translate}}</button>
      <ng-container *ngIf="checkEditOtherProvider">
        <button class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2"
          [disabled]="!statusBtn.editBtn" (click)="activeEdit()"
          *ngIf="modeEdit && disableEditFormDelivery && checkCanEdit">{{'BTN.EDIT'| translate}}</button>
        <!-- <button *ngIf="statusBtn.saveDraftBtnHide  && checkCanEdit" [disabled]="statusBtn.saveDraftBtn"
          (click)="saveReceipt(statusReceipt.TEMPORARY)"
          class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2">{{'BTN.DRAFT' | translate}}</button> -->
        <button class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2" *ngIf="checkCanEdit"
          [disabled]="formReceipt.pristine" (click)="resetForm()">{{'BTN.DISCARD_CHANGE' | translate}}</button>
        <button [disabled]="!statusBtn.acceptBtn || !disableEditFormDelivery"
          class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2"
          *ngIf="globalData.checkPermissionAction(permissionConfig.ACTIONS_CODE.DELIVERIES_HISTORY_CONFIRM)"
          (click)="doConfirmDelivery()">{{'BTN.DELIVERY_CONFIRMATION'| translate}}</button>
        <button [disabled]="!statusBtn.approveBtn || !disableEditFormDelivery"
          class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2"
          *ngIf="globalData.checkPermissionAction(permissionConfig.ACTIONS_CODE.DELIVERIES_HISTORY_APPROVE) && !authService.isCustomer"
          (click)="doAcceptDelivery()">{{'BTN.RECEIPT_APPROVAL'| translate}}</button>
      </ng-container>
      <button [disabled]="isConfirmWeb" (click)="onConfirmWeb()"
              *ngIf="(authService.isSale && isConfirmWeb1) || (authService.isCustomer && isCheckConfirmWeb() && isConfirmWeb1)"
              class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2">{{'BTN.WEB_CONFIRM'|
        translate}}</button>
      <button [disabled]="!modeEdit || !disableEditFormDelivery"
        class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2" *ngIf="checkViewOtherProvider"
        (click)="print()">{{'BTN.PRINTING'| translate}}</button>
    </div>

    <div class="act-vertical-buttons" *ngIf="maxLength > 0">
      <button [disabled]="(index - 1 === 0 || !disableEditFormDelivery)"
        class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2 font-icon"
        (click)="changeIndexReceipt(1)">
        |<img src="../../../../assets/images/backgrounds/left.png" alt="">
      </button>
      <button [disabled]="(index - 1 === 0 || !disableEditFormDelivery)"
        class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2 font-icon"
        (click)="changeIndexReceipt(2)">
        <img src="../../../../assets/images/backgrounds/left.png" alt="">
      </button>
      <button class="button m-2"
        style="width: 234px; font-size: 1.5rem; height: 55px; font-weight: bold; border: none !important; background: transparent; cursor: default">
        {{index + ' / ' + maxLength}}</button>
      <button [disabled]="(maxLength === 1) || index === maxLength || !disableEditFormDelivery"
        (click)="changeIndexReceipt(3)"
        class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2 font-icon"><img
          src="../../../../assets/images/backgrounds/right.png" alt=""></button>
      <button [disabled]="(maxLength === 1) || index === maxLength || !disableEditFormDelivery"
        (click)="changeIndexReceipt(4)"
        class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2 font-icon"><img
          src="../../../../assets/images/backgrounds/right.png" alt="">|</button>
    </div>
  </div>
  <div class="receipt-form mt-1" [ngClass]="{'form-disable': formDisable || disableEditFormDelivery}"
    (click)="(formDisable || disableEditFormDelivery) ? $event.preventDefault(): ''">
    <div class="row justify-content-center">
      <div class="border border-dark col-lg-7 col-md-12 col-sm-12 bg-light mb-4">
        <h2 class="mt-4 mb-2">
          <label class="header">未納入報告書</label>
        </h2>
        <form class="mx-3 mt-2" [formGroup]="formReceipt">
          <div class="mx-3 mt-2 align-items-center">
            <div class="row">
              <div class="col-12 input-group  pl-0 fs">
                <div *ngIf="suggestProvider || isSystemAdmin" class="auto-width">
                  <span class="req" *ngIf="!id || !disableEditFormDelivery">※</span>
                  <app-autocomplete [formControl]="companyNameText" [options]="{apiUrl: 'company/suggest',
                                    displayKey: {param1:'companyCode', param2:'companyName'},
                                    searchKey:'searchKey',
                                    getData: 'companyList',
                                    paramPlus: paramPlus
                                    }" [hasError]="(submittedFormDelivery && form('customerCompanyName').errors)"
                    [background]='!disableEditFormDelivery' [maxLength]="16" [id]="'management_delivery_company_code'"
                    (chooseVal)="getValueCompany($event)" [placeholder]="'B4.COMPANY_NAME'" [formReceipt]="true"
                    [disable]="(formDisable || disableEditFormDelivery)" [checkDeliveryFinish]="true"></app-autocomplete>
                </div>
                <div *ngIf="suggestDelivery" class="auto-width">
                  <span class="req" *ngIf="!id || !disableEditFormDelivery">※</span>
                  <app-autocomplete [formControl]="companyNameText" [options]="
                                    {apiUrl: 'company/customer/suggest',
                                    displayKey: {param1:'companyCode', param2:'companyName'},
                                    searchKey:'searchKey',
                                    getData: 'companyList',
                                    paramPlus: paramPlusDelivery
                                    }" [hasError]="(submittedFormDelivery && form('customerCompanyName').errors)"
                                    [background]='!disableEditFormDelivery' [maxLength]="16" [id]="'management_delivery_company_code'"
                                    (chooseVal)="getValueCompany($event)"
                                    [placeholder]="'B4.COMPANY_NAME'" [formReceipt]="true"
                                    [disable]="(formDisable || disableEditFormDelivery)"
                                    [checkDeliveryFinish]="true"
                  ></app-autocomplete>
                </div>
                <div *ngIf="suggestProviderT1" class="auto-width">
                  <span class="req" *ngIf="!id || !disableEditFormDelivery">※</span>
                  <app-autocomplete [formControl]="companyNameText" [options]="
                                    {apiUrl: 'company/suggest',
                                    displayKey: {param1:'companyCode', param2:'companyName'},
                                    searchKey:'searchKey',
                                    getData: 'companyList',
                                    paramPlus: paramPlus
                                    }" [hasError]="(submittedFormDelivery && form('customerCompanyName').errors)"
                    [background]='!disableEditFormDelivery' [maxLength]="16" [id]="'management_delivery_company_code'"
                    (chooseVal)="getValueCompany($event)" [placeholder]="'B4.COMPANY_NAME'" [formReceipt]="true"
                    [disable]="(formDisable || disableEditFormDelivery)" [checkDeliveryFinish]="true"></app-autocomplete>
                </div>
                <span class="none-padding margin-custom text-end fontsize"
                  [class]="{'text-code' : disableEditFormDelivery}"
                  style="line-height: 40px;">&nbsp;&nbsp;&nbsp;殿</span>
              </div>
              <div style="text-align: right; padding-right: 20px">
                <div class="company-code1 fs border-code">
                  <span>コード <span class="req-1" *ngIf="!id || !disableEditFormDelivery">※</span>&nbsp;&nbsp;</span>
                  <div *ngIf="suggestProvider || isSystemAdmin">
                    <app-autocomplete [formControl]="companyCodeText" [options]="{apiUrl: 'company/suggest',
                                    displayKey: {param1:'companyCode', param2:'companyName'},
                                    searchKey:'searchKey',
                                    getData: 'companyList',
                                    paramPlus: paramPlus
                                    }" [hasError]="(submittedFormDelivery && form('customerCompanyName').errors)"
                      [background]='!disableEditFormDelivery' [maxLength]="16" [id]="'management_delivery_company_code'"
                      (chooseVal)="getValueCompany($event)" [placeholder]="'B4.COMPANY_CODE'" [formReceipt]="true"
                      [disable]="(formDisable || disableEditFormDelivery)" [checkDeliveryFinish]="true"></app-autocomplete>
                  </div>
                  <div *ngIf="suggestDelivery">
                    <app-autocomplete [formControl]="companyCodeText" [options]="
                                    {apiUrl: 'company/customer/suggest',
                                    displayKey: {param1:'companyCode', param2:'companyName'},
                                    searchKey:'searchKey',
                                    getData: 'companyList',
                                    paramPlus: paramPlusDelivery
                                    }" [hasError]="(submittedFormDelivery && form('customerCompanyName').errors)"
                      [background]='!disableEditFormDelivery' [maxLength]="16" [id]="'management_delivery_company_code'"
                                      [checkDeliveryFinish]="true" (chooseVal)="getValueCompany($event)"
                      [placeholder]="'B4.COMPANY_CODE'" [formReceipt]="true"
                      [disable]="(formDisable || disableEditFormDelivery)"></app-autocomplete>
                  </div>
                  <div *ngIf="suggestProviderT1">
                    <app-autocomplete [formControl]="companyCodeText" [options]="
                                    {apiUrl: 'company/suggest',
                                    displayKey: {param1:'companyCode', param2:'companyName'},
                                    searchKey:'searchKey',
                                    getData: 'companyList',
                                    paramPlus: paramPlus
                                    }" [hasError]="(submittedFormDelivery && form('customerCompanyName').errors)"
                      [background]='!disableEditFormDelivery' [maxLength]="16" [id]="'management_delivery_company_code'"
                      (chooseVal)="getValueCompany($event)" [placeholder]="'B4.COMPANY_CODE'" [formReceipt]="true"
                      [disable]="(formDisable || disableEditFormDelivery)" [checkDeliveryFinish]="true"></app-autocomplete>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mx-3 mt-2 m-0">
            <div class="col-11">
              <div class=" d-flex align-items-center">
                <label class="col-form-label  fontsize" style="padding-top: 10px;"> <span
                    [class]="{'width-disable': id && disableEditFormDelivery}">訪問日</span> <span class="req-1"
                    *ngIf="!id || !disableEditFormDelivery">※</span> <span
                    style="margin-left:21px ; margin-right: 10px;">:</span></label>
                <div class="input-group align-items-end " style="margin-top: 8px;">
                  <input type="text" aria-label="First name" style="max-width: 50px" formControlName="deliveryYear"
                    class="form-control border-0 none-padding text-end  fontsize" (click)="dp1.toggle()" readonly
                    [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}">
                  <span class=" none-padding fontsize"
                        (click)="dp1.toggle()"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}">{{'B4.YEAR'|
                    translate}}</span>
                  <input type="text" aria-label="Last name" style="max-width: 30px;" formControlName="deliveryMonth"
                    class="form-control border-0 none-padding text-end fontsize" (click)="dp1.toggle()" readonly
                    [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}">
                  <span class=" none-padding fontsize"
                        (click)="dp1.toggle()"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}">{{'B4.MONTH'|
                    translate}}</span>
                  <input type="text" aria-label="Last name" style="max-width: 30px; " formControlName="deliveryDateDay"
                    class="form-control border-0 none-padding text-end fontsize" (click)="dp1.toggle()" readonly
                    [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}">
                  <span class=" none-padding fontsize"
                        (click)="dp1.toggle()"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}">{{'B4.DAY'|
                    translate}}</span>
                  <span class=" none-padding fontsize"
                        (click)="dp1.toggle()"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}">&nbsp;&nbsp;&nbsp;{{dayOfWeek
                    ? '(' + dayOfWeek + ')' : ''}}</span>

                  <input class="form-control border-0 text-center fontsize" name="dp1" ngbDatepicker
                    formControlName="deliveryDate" #dp1="ngbDatepicker" readonly style="display: none"
                    [maxDate]="maxDate" [minDate]="minDate"
                    [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                    (dateSelect)="updateDate($event)">&nbsp;&nbsp;&nbsp;
                </div>
                <div class="d-flex text-endtime" [class]="{'end-time': disableEditFormDelivery}">
                  <span class="req-1" *ngIf="!id || !disableEditFormDelivery">※</span>
                  <input type="text" aria-label="Last name"
                    [ngClass]="{'hasError': submittedFormDelivery && form('endTime').errors ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}"
                    class="form-control fontsize none-padding text-center border-0" formControlName="endTime"
                     appNumberOnly [isNumber]="true" id="endTimeNondelivery" [maxLength]="4" [isInteger2]="true"
                     (focus)="onFocus('endTime')" (blur)="onBlurTime('endTime')"
                    [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null">
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mx-3 mt-4 m-0">
            <div class="col-8">
              <div class="input-group">
                <span class="input-group-text  none-padding fontsize"
                  [ngClass]="{'hasError': submittedFormDelivery && form('delivererUserId').errors}"><span
                    [class]="{'width-disable1': id && disableEditFormDelivery}">乗務員名</span>
                  <span class="req-1" *ngIf="!id || !disableEditFormDelivery" style="margin-right: 4px;">※</span><span
                    style=" margin-right: 10px;">:</span> </span>
                <select formControlName="delivererUserId" (change)="updateDataVehicleUser()" *ngIf="!suggestDelivery"
                  style="text-align-last: center"
                  [attr.disabled]="(!form('customerCompanyId').value || formDisable || disableEditFormDelivery) ? '' : null"
                  [ngClass]="{'hasError': submittedFormDelivery && form('delivererUserId').errors ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}"
                  id="delivererUserId" class="form-select  custom-input-line none-padding input-group-text fontsize">
                  <option *ngFor="let item of listDeliveryUser" [value]="item?.userId">{{item?.userName}}</option>
                  <option [value]="delivererUserId"
                    *ngIf="listDeliveryUser?.length === 0 && id  || !checkUserName && id">
                    {{this.form('delivererUserName').value}}</option>
                  <option
                    *ngIf="(listDeliveryUser?.length===0 && this.form('delivererUserName').value && !id) || (listDeliveryUser?.length===0 && !this.form('delivererUserName').value && id)"
                    disabled>
                    {{'MESS.CM0003'| translate}}</option>
                </select>
                <img class="drop" src="assets/images/backgrounds/drop.png" alt="" *ngIf="!isDelivery">
                <input type="text" class="form-control custom-input-line  none-padding input-group-text fontsize"
                  [ngClass]="{'background' : suggestDelivery && !id || !disableEditFormDelivery}" aria-label="Username"
                  disabled *ngIf="suggestDelivery" aria-describedby="basic-addon1" formControlName="delivererUserName">

              </div>
            </div>
            <div class="col-4">
              <div class="input-group">
                <span class="input-group-text none-padding fontsize"
                  [ngClass]="{'hasError': submittedFormDelivery && form('vehicleCode').errors}">車番 <span class="req-1"
                    *ngIf="!id || !disableEditFormDelivery">※</span> <span style="margin-right: 10px;">:</span></span>

                <select formControlName="vehicleCode" id="vehicleCode" (change)="updateDataVehicle()"
                  style="text-align-last: center"
                  [attr.disabled]="(!form('customerCompanyId').value ||  !form('customerCompanyId').value || formDisable || disableEditFormDelivery) ? '' : null"
                  [ngClass]="{'hasError': submittedFormDelivery && form('vehicleCode').errors ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}"
                  class="form-select custom-input-line none-padding  input-group-text fontsize">
                  <option *ngFor="let item of listVehicle" [value]="item?.vehicleCode">{{item?.vehicleCode}}</option>
                  <option
                    *ngIf=" (listVehicle?.length === 0 && id && suggestProvider) || (listVehicle?.length != 0 && id && suggestProvider && disableEditFormDelivery) || (listVehicle?.length === 0 && !suggestProvider && id) || (listVehicle?.length != 0 && !suggestProvider && id && disableEditFormDelivery) "
                    [value]="vehicleCode">{{vehicleCode}}
                  </option>
                  <option
                    *ngIf="(listDeliveryUser?.length===0 && !form('vehicleCode').value && id) || (listDeliveryUser?.length===0 && form('vehicleCode').value && !id) "
                    disabled>
                    {{'MESS.CM0003'| translate}}</option>
                </select>
                <img class="drop" src="assets/images/backgrounds/drop.png" alt="">
              </div>
            </div>
          </div>
          <div class="row align-items-center mx-3 mt-4 m-0">
            <div class="col-12">
              <div style="height: 30px;">
                <label class="col-form-label none-padding input-group-text text-start fontsize"
                  *ngIf="form('saleBillingCompanyName').value != '―'"
                  [ngClass]="{'hasError': submittedFormDelivery && form('saleBillingCompanyName').errors}">{{'B4.SALE_FONT_COMPANY_NAME'|
                  translate}}
                  <span style="margin-left: 20px; margin-right: 10px;">:</span>
                  <span style="width:100%; height:24px;  padding-left: 15px;"
                    [class]="{'background' :!disableEditFormDelivery}">{{form('saleBillingCompanyName').value}}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row align-items-center mx-3 mt-4 m-0">
            <div class="col-5">
              <div class="input-group align-items-end ">
                <span class="input-group-text none-padding width-tank fontsize" style="width:109px; display:block;"
                  [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageBefore').errors}">
                  タンク在庫<span style=" margin-right: 10px;">:</span></span>
                <input type="text" class="form-control none-padding text-end fontsize" aria-label="Username"
                  (blur)="convertDecimal($event)" formControlName="tankPercentageBefore" appNumberOnly
                  [isInteger2]="true" [isInteger1]="true" [isNumber]="true" maxlength="3"
                  [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                  [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageBefore').errors ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}">
                <span class="input-group-text none-padding fontsize"
                  [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageBefore').errors}">&nbsp;&nbsp;&nbsp;%</span>
              </div>
            </div>
          </div>

          <div class="row align-items-center mx-3 mt-2 text-center fontsize">
            <h5>今回は以下の理由により未納入となりました。</h5>
          </div>
          <div class="row align-items-center mx-3 mt-1">
            <div class="col-3"></div>
            <div class="col-6 d-flex justify-content-center">
              <span class="req-1" *ngIf="!id || !disableEditFormDelivery">※</span>
              <select formControlName="nonDeliveryReasonId" (change)="updateDataVehicle()" class="form-select fontsize"
                [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                [class]="{'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}"
                style="text-align-last: center">
                <option *ngFor="let item of listReason" [value]="item?.reasonInt">{{item?.reasonName}}</option>
                <option *ngIf="listReason?.length === 0" disabled>{{'MESS.CM0003'| translate}}</option>
              </select>
            </div>
          </div>

          <div class="row align-items-center mx-3 mt-2 m-0">
            <div class="">
              <div class="custom-textarea">
                <textarea class="form-control border border-dark fontsize"
                  [class]="{'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}"
                  [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null" rows="10"
                  formControlName="note"></textarea>
              </div>
            </div>
          </div>
          <div class="row align-items-center mx-3 mt-2 fontsize">
          </div>

          <div class="row align-items-center mx-3 mt-2">
            <label class="col-form-label text-start fontsize d-flex" style="padding-top: 0px !important;"
              [ngClass]="{'hasError': submittedFormDelivery && form('deliveryOfficialName').errors}">
              <span>{{'B4.DELIVERY_COMPANY_NAME'|translate}} &nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span style="width:100%; height:24px; display: block; padding-left: 15px;"
                [class]="{'background' :!disableEditFormDelivery}">
                <span *ngIf="form('deliveryOfficialName').value">
                  {{form('deliveryOfficialName').value }}
                </span>
                <span *ngIf="form('deliveryOfficeName').value">
                  {{form('deliveryOfficeName').value}}
                </span>
              </span>
            </label>
          </div>
          <div class="row align-items-center  mt-2">
            <div class="py-2 text-end fontsize">
              <label>伝票番号:
                <span style="width:100%; height:24px;" [class]="{'background' :!disableEditFormDelivery}">
                  <span *ngIf=" form('receiptNumber').value">
                    {{' ' +form('receiptNumber').value}}</span>
                </span>
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isPrintList">
  <app-tempalte-print-non-delivery-report [dataInput]="dataPrint" [deliveryday]="this.formReceipt.value.deliveryDate"
    (printDone)="printDoneList()">
  </app-tempalte-print-non-delivery-report>
</div>
<ng-template #endTime>
  <ngb-timepicker [(ngModel)]="endTimeData" (ngModelChange)="actionUpdateTime($event, 'endTime')"></ngb-timepicker>
</ng-template>
