import {Component, Input, OnInit} from '@angular/core';
import {BreadcrumbModel} from '../../../models/breadcrumb.model';
import {Router} from '@angular/router';
import {GlobalDataService} from '../../../services/global-data.service';
import {ConfirmationModalService} from '../../service/modal/confirmation-modal.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() breadcrumbData: Array<BreadcrumbModel>;
  @Input() showTotal: false;
  @Input() totalRecords = 0;
  @Input() currentRecords = 0;
  @Input() totalKg = 0;
  @Input() totalKg2 = 0;
  @Input() typeMenu: number;

  constructor(private router: Router,
              private confirmPopup: ConfirmationModalService,
              private globalData: GlobalDataService) {
  }

  ngOnInit(): void {
  }

  filterData(data: Array<BreadcrumbModel>): Array<BreadcrumbModel> {
    return data.filter(x => x.url !== '');
  }

  routing(url): void {
    const dataHasChange = this.globalData.getStatusDataEdit();
    const dataHasChangeTab = this.globalData.getStatusDataEditTab();
    this.globalData.statusFormDetail = false;
    this.globalData.clickSearch = false;
    if (dataHasChange || dataHasChangeTab) {
      this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'MESS.CD0005', 'BTN.OK', 'BTN.CANCEL', () => {
        this.globalData.setStatusDataEdit(false);
        this.globalData.setStatusDataEditTab(false);
        this.globalData.clickFlag = false;
        this.router.navigateByUrl(url).then();
      });
    }else {
        this.router.navigateByUrl(url).then();
    }
  }
}
