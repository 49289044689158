<div class="custom-template">
  <nav class="navbar navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand cursor-pointer">
        <span class="font-weight-bold">{{'APP_NAME' | translate}}</span>
        <img [src]="'assets/images/logos/logo.png'" alt="" width="30" height="24"
             class="d-inline-block align-text-top img-fluid">
      </a>
    </div>
  </nav>
  <div class="text-center mb-3">
    <h3 class="custom-title">{{title| translate}}</h3>
  </div>
  <div class="custom-content">
    <table class="table">
      <thead>
      <tr>
        <th *ngFor="let item of header">
          <div class="pr-2 text-center custom-header">
            {{item?.name}}
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let body of contentTable">
        <td class="tbody-td text-center" *ngFor="let item of header; let i = index">{{body[item?.param]}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
