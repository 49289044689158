import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../services/api-service/auth.service';
import {NetworkService} from '../../services/network.service';
import {ConfirmationModalService} from '../../share/service/modal/confirmation-modal.service';
import {GlobalDataService} from '../../services/global-data.service';

@Injectable({providedIn: 'root'})
export class ChangeFormHelper implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private network: NetworkService,
    private confirmPopup: ConfirmationModalService,
    private globalData: GlobalDataService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.checkFormChange();
  }

  checkFormChange(): Observable<boolean> {
    return new Observable<any>(subscriber => {
      const dataHasChange = this.globalData.getStatusDataEdit();
      const dataHasChangeTab = this.globalData.getStatusDataEditTab();
      if (dataHasChange || dataHasChangeTab) {
        this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'MESS.CD0005', 'BTN.OK', 'BTN.CANCEL', () => {
          this.globalData.setStatusDataEdit(false);
          this.globalData.setStatusDataEditTab(false);
          this.globalData.clickFlag = false;
          this.globalData.tabChange = false;
          subscriber.next(true);
          subscriber.complete();
        }, () => {
          history.go(1);
        });
      } else {
        subscriber.next(true);
        subscriber.complete();
      }
    });
  }
}
