import {ElementRef, Injectable, Injector} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {DynamicOverlay} from './dynamic-overlay.service';
import {GlobalLoadingComponent} from '../components/global-loading/global-loading.component';
import {LOADING_DATA} from '../tokens/loading-data.token';

interface LoadingConfig {
  type?: string;
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  positionStrategy?;
  scrollStrategy?;
}

const DEFAULT_CONFIG: LoadingConfig = {
  type: 'round',
  hasBackdrop: true,
  // backdropClass: 'dark-backdrop',
  // panelClass: 'loading-preview-dialog'
};

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private appLoading;

  constructor(private overlay: Overlay, private dynamicOverlay: DynamicOverlay, private _injector: Injector) {
  }

  showAppLoading(): void {
    if (this.appLoading && this.appLoading.hasAttached) {
      return;
    }

    this.appLoading = this.open({type: DEFAULT_CONFIG.type});
  }

  hideAppLoading(): void {
    if (this.appLoading) {
      this.appLoading.close();
    }
  }

  private getOverlayConfig(config: LoadingConfig): OverlayConfig {

    if (!config.positionStrategy) {
      config.positionStrategy = this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically();
    }

    if (!config.scrollStrategy) {
      config.scrollStrategy = this.overlay.scrollStrategies.block();
    }

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: config.scrollStrategy,
      positionStrategy: config.positionStrategy
    });
  }

  public createInjector(data): PortalInjector {

    const injectorTokens = new WeakMap<any, any>([
      [LOADING_DATA, data],
    ]);

    return new PortalInjector(this._injector, injectorTokens);
  }

  private createOverlay(config: LoadingConfig, overlay?: Overlay): any {
    // Returns an OverlayConfig
    const overlayConfig = this.getOverlayConfig(config);

    let overlayRef;
    if (overlay) {
      overlayRef = overlay.create(overlayConfig);
    } else {
      overlayRef = this.overlay.create(overlayConfig);
    }

    overlayRef.attach(new ComponentPortal(GlobalLoadingComponent, null, this.createInjector({type: config.type})));

    return overlayRef;
  }

  open(config: LoadingConfig = {}): LoadingOverlayRef {
    const dialogConfig = {...DEFAULT_CONFIG, ...config};
    const overlayRef = this.createOverlay(dialogConfig);

    return new LoadingOverlayRef(overlayRef);
  }

  openInBox(el: ElementRef, config: LoadingConfig = {}): LoadingOverlayRef {
    this.dynamicOverlay.setContainerElement(el.nativeElement);

    config.scrollStrategy = this.dynamicOverlay.scrollStrategies.reposition();
    config.positionStrategy = this.dynamicOverlay.position().global().centerHorizontally().centerVertically();

    const dialogConfig = {...DEFAULT_CONFIG, ...config};

    const overlayRef = this.createOverlay(dialogConfig, this.dynamicOverlay);

    return new LoadingOverlayRef(overlayRef);
  }
}


export class LoadingOverlayRef {

  constructor(private overlayRef: OverlayRef) {
  }

  close(): void {
    this.overlayRef.dispose();
  }
}
