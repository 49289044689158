import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './module/layout/not-found/not-found.component';
import {ListLayoutComponent} from './module/layout/list-layout/list-layout.component';
import {AuthGuardHelper} from './helpers/authen/auth-guard.helper';
import {LoginComponent} from './module/auth/login/login.component';
import {LoginPageGuardHelper} from './helpers/authen/login-page-guard.helper';
import {TopComponent} from './module/list/top/top.component';
import {DetailLayoutComponent} from './module/layout/detail-layout/detail-layout.component';
import {UserRoles} from './configs/user-config';
import {ChangeFormHelper} from './helpers/authen/change-form.helper';

const routes: Routes = [
  {path: '', redirectTo: '/top', pathMatch: 'full'},
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginPageGuardHelper],
    data: {
      title: 'X-DAT'
    }
  },
  {
    path: 'top',
    canActivate: [AuthGuardHelper, ChangeFormHelper],
    component: TopComponent,
    pathMatch: 'full',
    data: {
      role: [UserRoles.PROVIDER_COMPANY, UserRoles.SALE_COMPANY, UserRoles.DELIVERY_COMPANY, UserRoles.CUSTOMER, UserRoles.SYSTEM_ADMIN],
      title: 'トップメニュー'
    }
  },
  {
    path: '',
    component: DetailLayoutComponent,
    canActivate: [AuthGuardHelper, ChangeFormHelper],
    loadChildren: () => import('src/app/module/details/details.module').then(m => m.DetailsModule)
  },
  {
    path: '',
    component: ListLayoutComponent,
    canActivate: [AuthGuardHelper, ChangeFormHelper],
    loadChildren: () => import('src/app/module/list/list.module').then(m => m.ListModule)
  },
  {path: 'not-found', component: NotFoundComponent, data: { title: 'X-DAT'}},
];

routes.push({path: '**', redirectTo: 'not-found'});

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
