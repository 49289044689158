import {Injectable, NgZone} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LoadingService} from '../../share/service/loading.service';

import {NavigationStart, Router} from '@angular/router';
import {AuthService} from '../../services/api-service/auth.service';
import {ConfirmationModalService} from '../../share/service/modal/confirmation-modal.service';
import {NetworkService} from '../../services/network.service';
import {GlobalDataService} from '../../services/global-data.service';

@Injectable()
export class ErrorInterceptorHelper implements HttpInterceptor {
  constructor(
    private loading: LoadingService,
    private router: Router,
    private zone: NgZone,
    private authService: AuthService,
    private network: NetworkService,
    private globalData: GlobalDataService,
    private confirmPopup: ConfirmationModalService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        console.log(err);

        this.loading.hideAppLoading();
        if (err.status === 401 && err.error.message) {
          this.zone.run(() => {
            this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.ER0105', 'OK', () => {
              this.authService.clearToken();
              this.authService.redirectLogin();
            });
          });
        } else if (err.status === 403  && err.error.message) {
          this.confirmPopup.confirm('COMMON_DIALOG.TT0002', `MESS.${err.error.message}`, 'BTN.OK', '', () => {
            // redirect to top
            this.router.navigateByUrl('/top', {replaceUrl: true}).then();
          });
        } else if (err.error.error === 'ERR_TIME_OUT') {
          this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.CD0054', 'OK', () => {
            this.globalData.setStatusDataEdit(false);
            this.globalData.clickFlag = false;
            this.globalData.tabChange = false;
            this.authService.clearToken();
            return this.authService.redirectLogin();
          });
        } else if (err.statusText === 'Unknown Error' && this.network.online) {
          this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.ER0134', 'BTN.OK', () => {
            this.authService.clearToken();
            return this.authService.redirectLogin();
          });
        } else if (err.statusText === 'Unknown Error' && !this.network.online) {
          this.confirmPopup.confirm('COMMON_DIALOG.TT0002', 'MESS.CD0053', 'BTN.OK', '');
        }
        // else if (err.status === 401 || err.status === 423) {
        //   this.authService.clearToken();
        //   this.confirmPopup.notify('', 'MESS.' + err.error.message, 'ok', () => {
        //   this.authService.redirectLogin();
        //   });
        // }
        return throwError(err.error);
      })
    );
  }
}
