import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/api-service/auth.service';
import { CompanyType, UserProviderRoles, UserRoles } from '../../../configs/user-config';
import { BreadcrumbModel } from '../../../models/breadcrumb.model';
import { LoadingService } from '../../service/loading.service';
import { ConfirmationModalService } from '../../service/modal/confirmation-modal.service';
import { BasicFunctionService } from '../../service/basicFunction.service';
import { ReceiptApiService } from '../../../services/api-service/receipt.api.service';
import * as moment from 'moment';
import {LOGIN_ID_VALIDATOR, MAX_SIZE_IMG, OFFSET_PAGE, STATUS_RECEIPT, URL_IMG, WEEKDAYS} from '../../../configs/const';
import { ProductService } from '../../../services/api-service/product.service';
import { GlobalDataService } from '../../../services/global-data.service';
import { CustomValidators } from '../../utils/custom-validators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PERMISSION_CONFIG } from 'src/app/configs/permision-config';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { NoteReceiptComponent } from './note-receipt/note-receipt.component';
import { debounceTime, distinctUntilChanged, elementAt } from 'rxjs/operators';
import { LoginPageGuardHelper } from 'src/app/helpers/authen/login-page-guard.helper';
import {CustomerCompanyModel} from '../../../models/customer-company.model';
import {CustomerApi} from '../../../services/api-service/customer.api';

@Component({
  selector: 'app-receipt-form',
  templateUrl: './receipt-form.component.html',
  styleUrls: ['./receipt-form.component.scss']
})
export class ReceiptFormComponent implements OnInit, AfterViewInit {
  @Output() newItemEvent = new EventEmitter<number>();
  @Input() isNumber = false;
  @ViewChild('inputFile') myInputVariable: ElementRef;
  maxDate = { day: 31, month: 12, year: (new Date()).getFullYear() + 15 };
  minDate = { year: 2021, month: 1, day: 1 };
  dataTitle: string;
  dataDetail;
  flag = true;
  id = '';
  formReceipt: FormGroup;
  modeEdit = false;
  errProviderCompanyId = '';
  submittedFormDelivery = false;
  disableBtnEditFormDelivery = false;
  companyNameText = new FormControl('');
  companyCodeText = new FormControl('');
  oldValueForm: any;
  disableEditFormDelivery = false;
  isProvider = false;
  userRole = UserRoles;
  roundingMethod: number;
  listBreadcrumb: Array<BreadcrumbModel> = [];
  companyType = CompanyType;
  providerCompanyId = '';
  partnerCompanyId = '';
  textAmount = '';
  dayOfWeek = '';
  isDeliveryParent;
  vehicleCode: number;
  weekDay = WEEKDAYS;
  suggestProvider = false;
  suggestProviderT1 = false;
  listVehicle = [];
  maxLength = null;
  companyCode;
  deliveryDate;
  counter;
  temperature;
  index = null;
  listDeliveryUser = [];
  fileUpload: File = null;
  imageURL: string | ArrayBuffer = '';
  startTimeData: any;
  endTimeData: any;
  dateEndTime: any;
  dateEndTimeNow: any;
  currenUserLogin: any;
  counter1;
  statusReceipt = STATUS_RECEIPT;
  labelCompanyName = '';
  textLenght = 103;
  checkStartTime = false;
  statusBtn = {
    editBtn: false,
    saveDraftBtn: false,
    discardChangeBtn: false,
    acceptBtn: false,
    approveBtn: false,
  };
  formDisable = false;
  userProviderRoles = UserProviderRoles;
  checkBoxOption1 = '受領書';
  checkBoxOption2 = '納品書';
  customerCompanyCode;
  titlePrint = '';
  titlePrint1 = '';
  titleHeader;
  dataForPrint: any;
  isPrintList = false;
  messError;
  updateStartTime;
  updateEndTime;
  userId;
  textErrTankPercentageBefore;
  counterType = 0;
  urlImgOn = URL_IMG;
  textErrEndTime = '';
  textErrStartTime = '';
  textErrCounterFlowAmount: '';
  textErrPressureBefore = '';
  textErrcounterFlowAmount = '';
  textErrcounterFlowAmountNumber = '';
  textErrDate = '';
  textErrVehicle = '';
  textErrDeliveryUser = '';
  isSystemAdmin = false;
  suggestDelivery = false;
  delivererUserId;
  checkImage;
  editDate;
  delivererUserName;
  checkUserName = false;
  editDay;
  editMonth;
  conversionRate;
  permissionConfig = PERMISSION_CONFIG;
  setProviderCompanyId = 0;
  amount;
  vehicle;
  customerCompanyId;
  counterFlowAmount;
  nowTime: any;
  isClick = true;
  deliveryCompanyOfficeName;
  deliveryCompanyOfficialName;
  productBillingName;
  productName;
  deliveryCompanyId;
  receiptNumber;
  maxLengthTime = 4;
  isConfirmWeb = false;
  isConfirmWeb1 = false;
  paramPlus: any;
  paramPlusDelivery: any;
  validStartTime = true;
  userRoleSale = false;
  userRoleCustomer = false;
  constructor(
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public loadingService: LoadingService,
    public receiptService: ReceiptApiService,
    public confirmPopup: ConfirmationModalService,
    public translateService: TranslateService,
    public authService: AuthService,
    public router: Router,
    public basicFunction: BasicFunctionService,
    public productService: ProductService,
    public globalData: GlobalDataService,
    public modalService: NgbModal,
    public cp: CurrencyPipe,
    public datePipe: DatePipe,
    public el: ElementRef,
    public dialog: MatDialog,
    public renderer: Renderer2,
    private customerApi: CustomerApi
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      this.getDataReceipt(this.id);
    }
    this.isClick = true;
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const url = event.url;
        const path = '/detail/deliveries-form/';

        if (url.search(path) !== -1) {
          const arr = event.url.split('/');
          if (!this.isClick) {
            const listIndex = JSON.parse(localStorage.getItem('listIdReceipt'));
            const type = listIndex.find(val => val.deliveryId == arr[3])?.type;
            this.getMinMaxReceipt(arr[3]);
            if (!type) {
              this.getDataReceipt(arr[3]);
            } else {
              this.isClick = true;
            }
            // tslint:disable-next-line:radix
            this.newItemEvent.emit(parseInt(arr[3]));
          }
        } else {
          this.isClick = true;
        }
      }
    });


    this.isProvider = this.authService.isProvider;
    this.isSystemAdmin = this.authService.isSystemAdmin;

    const historyFilter = localStorage.getItem('history-filter') ? JSON.parse(localStorage.getItem('history-filter')) : null;
    if (historyFilter) {
      if (historyFilter.typeMenu === 3){
        this.listBreadcrumb = [
          { url: '/top', breadcrumb: 'トップメニュー' },
          { url: '/list/deliveries-history', breadcrumb: '合算履歴' },
          { url: '', breadcrumb: '受領書' },
        ];
      }else {
        this.listBreadcrumb = [
          { url: '/top', breadcrumb: 'トップメニュー' },
          { url: '/list/deliveries-history', breadcrumb: '納入履歴' },
          { url: '', breadcrumb: '受領書' },
        ];
      }
    }else {
      this.listBreadcrumb = [
        { url: '/top', breadcrumb: 'トップメニュー' },
        { url: '/list/deliveries-history', breadcrumb: '納入履歴' },
        { url: '', breadcrumb: '受領書' },
      ];
    }

    this.currenUserLogin = this.authService.currentUserValue;

    if (this.authService.isSystemAdmin || this.authService.isEditProviderOther) {
      this.providerCompanyId = '';
    } else {
      this.providerCompanyId = this.currenUserLogin.providerCompanyId;
    }
    this.partnerCompanyId = this.currenUserLogin.partnerCompanyId;
    this.buildForm();
    if (this.authService.isProvider && this.authService.isEditProviderOther) {
      this.suggestProvider = true;
    }
    if (this.authService.isDelivery || this.authService.isSale || this.authService.isCustomer) {
      this.suggestDelivery = true;
    }
    if (this.authService.isProvider && !this.authService.isEditProviderOther) {
      this.suggestProviderT1 = true;
    }
    this.messError = {
      productName: this.translateService.instant('MESS.CV0001', { label: translateService.instant('B4.PRODUCT_NAME') }),
      saleFrontCompanyName: this.translateService.instant('MESS.CV0001', { label: translateService.instant('B4.SALE_FONT_COMPANY_NAME') }),
      customerCompanyName: this.translateService.instant('B4.COMPANY_NAME_VALIDATE'),
      deliveryCompanyName: this.translateService.instant('MESS.CV0001', { label: '運送会社名' }),
      vehicleCode: this.translateService.instant('MESS.CV0001', { label: '車番' }),
      delivererUserId: this.translateService.instant('MESS.CV0001', { label: '乗務員名' }),
      startTime: this.translateService.instant('MESS.CV0001', { label: '開始時間' }),
      endTime: this.translateService.instant('MESS.CV0001', { label: '終了時間' }),
      temperature: this.translateService.instant('MESS.MS4013'),
      pressureAfter: this.translateService.instant('MESS.MS4015'),
      pressureBefore: this.translateService.instant('MESS.MS4014'),
      tankPercentageBefore: this.translateService.instant('MESS.MS0419'),
      tankPercentageAfter: this.translateService.instant('MESS.MS0421'),

    };

    this.textErrStartTime = this.translateService.instant('MESS.CM0006');
    this.textErrDate = this.translateService.instant('MESS.ER0205');
    this.textErrTankPercentageBefore = this.translateService.instant('MESS.CV0015');
    this.textErrPressureBefore = this.translateService.instant('MESS.MS0408');
    this.textErrCounterFlowAmount = this.translateService.instant('MESS.ER0139');
    this.textErrVehicle = this.translateService.instant('MESS.ER0204');
    this.textErrDeliveryUser = this.translateService.instant('MESS.ER0203');
    this.textErrcounterFlowAmount = this.translateService.instant('MESS.MS0420');
    this.textErrcounterFlowAmountNumber = this.translateService.instant('MESS.ER0138');
    this.textAmount = this.translateService.instant('MESS.CV0001', { label: translateService.instant('B4.AMOUNT') });
    this.temperature = this.translateService.instant('MESS.MS4013');
    this.conversionRate = this.translateService.instant('MESS.ER0140');

    this.userRoleSale = this.userRole.SALE_COMPANY === this.authService.currentUserValue.role;
    this.userRoleCustomer = this.userRole.CUSTOMER === this.authService.currentUserValue.role;
  }

  ngOnInit(): void {
    if (this.userRoleCustomer){
      this.getDetailCustomerCompany();
    }
    if (this.userRoleSale){
      this.getListCustomer();
    }
    this.globalData.title = '受領書';
    this.basicFunction.setStatusChangeRoute(true);
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.globalData.clickSearch) {
      this.globalData.statusFormDetail = true;
    }

    if (!this.id) {
      localStorage.removeItem('isDeliveryParent');
      this.updateNowTime();
      if (this.authService.isDelivery) {
        this.formReceipt.get('delivererUserId').setValue(this.currenUserLogin.userId, { emitEvent: false });
        this.formReceipt.get('delivererUserName').setValue(this.currenUserLogin.name, { emitEvent: false });
        this.form('deliveryCompanyId').setValue(this.currenUserLogin.partnerCompanyId);
        this.form('deliveryCompanyName').setValue(this.currenUserLogin.companyName);
        this.form('deliveryOfficialName').setValue(this.currenUserLogin.companyOfficalName);
      }
      const date = {
        day: moment().date(),
        month: moment().month() + 1,
        year: moment().year()
      };
      this.updateDate(date);
      this.checkStatusBtnCreate();
    } else {
      this.modeEdit = true;
      this.getMinMaxReceipt();
      this.isClick = true;
    }

    if (this.authService.isProvider) {
      this.paramPlus = {saleOrder: '', companyType: this.companyType.CUSTOMER, providerCompanyId: '', checkRole: true};
    }

    if (this.authService.isDelivery) {
      this.paramPlusDelivery = {deliveryId: this.partnerCompanyId , providerCompanyId: this.providerCompanyId, checkRole: true};
    }
  }

  getListCustomer(): void {
    const offset = 100;
    const body = {
      filters: {
        customerCompanyName: '',
        deliveryCompanyName: '',
        productName: '',
        providerCompanyIds: [],
        saleCompanyName: ''
      },
      pageNumber: 0,
      offset,
      sort: {
        sortDirection: 1,
        sortKey: ''
      }
    };
    this.customerApi.customerCompanySearch.sort = body.sort;
    this.customerApi.customerCompanySearch.filters = body.filters;
    this.customerApi.getListCustomer(body).subscribe((data: any) => {
      const dataCustomer: Array<CustomerCompanyModel> = data.data;
      dataCustomer.map((item) => {
        item.saleList?.filter(it => {
          // tslint:disable-next-line:max-line-length
          if ((it?.saleCompanyOrder === 2 || it?.saleCompanyOrder === 4 || it?.saleCompanyOrder === 5) && it?.saleCompanyCode === this.authService.currentUserValue.companyCode && it?.isWebSaleOrder === true) {
            this.isConfirmWeb1 = true;
          }
        });
      });
    }, (error: { success: boolean, message: string }) => {
    }, () => {
    });
  }


  getDetailCustomerCompany(): void {
    this.customerApi.getDetail(this.authService.currentUserValue.partnerCompanyId).subscribe((data) => {
      if (data.isAcceptanceRequired && this.userRoleCustomer){
        this.isConfirmWeb1 = true;
      }
    });
  }


  get checkCanEdit(): boolean {
    return this.globalData.checkPermissionAction(this.permissionConfig.ACTIONS_CODE.DELIVERIES_HISTORY_EDIT);
  }

  get checkViewOtherProvider(): boolean {
    if (!this.id || this.authService.currentUserValue.companyCode === '70001') {
      return true;
    }

    return this.globalData.checkViewOtherProvider(this.setProviderCompanyId, false);
  }

  get checkEditOtherProvider(): boolean {
    if (!this.id || this.authService.currentUserValue.companyCode === '70001') {
      return true;
    }
    return this.globalData.checkEditOtherProvider(this.setProviderCompanyId, false);
  }

  changeIndexReceipt(index): void {
    if (this.formReceipt.dirty) {
      this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'MESS.CD0005', 'BTN.OK', 'BTN.CANCEL', () => {
        this.globalData.setStatusDataEdit(false);
        this.isClick = true;
        const listIndex = JSON.parse(localStorage.getItem('listIdReceipt'));
        let rawIndex = 0;
        switch (index) {
          case 1:
            rawIndex = 0;
            this.index = 1;
            break;
          case 2:
            rawIndex = this.index - 2;
            break;
          case 3:
            rawIndex = this.index;
            break;
          case 4:
            rawIndex = this.maxLength - 1;
            this.disableEditFormDelivery = false;
            break;
        }
        this.id = listIndex[rawIndex].deliveryId;
        this.router.navigateByUrl('detail/deliveries-form/' + listIndex[rawIndex].deliveryId).then();
        this.getMinMaxReceipt(listIndex[rawIndex].deliveryId);
        if (!listIndex[rawIndex].type) {
          this.getDataReceipt(listIndex[rawIndex].deliveryId);
        }
        this.newItemEvent.emit(listIndex[rawIndex].deliveryId);
      }, () => {
      });
    } else {
      this.isClick = true;
      const listIndex = JSON.parse(localStorage.getItem('listIdReceipt'));
      let rawIndex = 0;
      switch (index) {
        case 1:
          rawIndex = 0;
          this.index = 1;
          break;
        case 2:
          rawIndex = this.index - 2;
          break;
        case 3:
          rawIndex = this.index;
          break;
        case 4:
          rawIndex = this.maxLength - 1;
          this.disableEditFormDelivery = false;
          break;
      }
      this.id = listIndex[rawIndex].deliveryId;
      this.router.navigateByUrl('detail/deliveries-form/' + listIndex[rawIndex].deliveryId).then();
      this.getMinMaxReceipt(listIndex[rawIndex].deliveryId);
      if (listIndex[rawIndex].isCombinada !== 2 && !listIndex[rawIndex].type) {
        this.getDataReceipt(listIndex[rawIndex].deliveryId);
      }
      this.newItemEvent.emit(listIndex[rawIndex].deliveryId);
    }
  }

  actionUpdateNowTime(type = 'startTime'): void {
    this.updateNowTime(type);
    this.formReceipt.markAsDirty();
  }
  updateNowTime(type = 'startTime'): void {
    const time = new Date();
    const year = this.datePipe.transform(time, 'yyyy');
    const month = this.datePipe.transform(time, 'MM');
    const day = this.datePipe.transform(time, 'dd');

    const nowTime = {
      hour: time.getHours(), minute: time.getMinutes(), second: 0
    };
    this.dateEndTimeNow = day + '-' + month + '-' + year;
    if (this.id) {
      let newDay = '';
      if (parseInt(day) < 10) {
        newDay = '0' + day;
      }
      this.formReceipt.get('deliveryDateYear').setValue(year);
      this.formReceipt.get('deliveryDateMonth').setValue(month);
      this.formReceipt.get('deliveryDateDay').setValue(newDay ? newDay : day);
    }

    if (type === 'startTime') {
      this.startTimeData = nowTime;
      this.endTimeData = nowTime;
      this.updateTime(nowTime, 'startTime', '');
      this.updateTime(nowTime, 'endTime', '');
    } else if (type === 'endTime') {
      this.endTimeData = nowTime;
      this.updateTime(nowTime, 'endTime', '', this.dateEndTimeNow);
    }
  }

  getMinMaxReceipt(id?: string): void {
    this.maxLength = this.globalData.getListIdReceipt().length;
    this.index = this.globalData.getIndexReceiptInList(parseInt(id ? id : this.id, 10)) + 1;
  }

  getDayOfWeek(day: string): void {
    this.dayOfWeek = '';
    const weekDayName = moment(day).format('d');
    for (const item of this.weekDay) {
      if (+weekDayName === item.value) {
        this.dayOfWeek = item.text;
      }
    }
  }

  buildForm(data?): void {
    if (data) {
      this.setProviderCompanyId = data.providerCompanyId;
      if (!this.checkViewOtherProvider && !this.checkEditOtherProvider && this.authService.currentUserValue.companyCode !== '70001') {
        this.globalData.processPermissionFalse();
      }
    }
    this.formReceipt = this.formBuilder.group({
      customerCompanyId: new FormControl(data ? data.customerCompanyId : '', []),
      customerCompanyCode: new FormControl(data ? data.customerCompanyCode : ''),
      customerCompanyName: new FormControl(data ? data.customerCompanyName : ''),
      deliveryDate: new FormControl(this.editDate),
      deliveryDateYear: new FormControl(data ? data.deliveryDateYear : ''),
      deliveryDateMonth: new FormControl(this.editMonth),
      deliveryDateDay: new FormControl(this.editDay),
      productId: new FormControl(data ? data.productId : ''),
      productName: new FormControl(this.productBillingName),
      productBillingName: new FormControl(this.productBillingName),
      amount: new FormControl(this.amount),
      counterFlowAmount: new FormControl(this.counterFlowAmount),
      conversionRate: new FormControl(data ? data.conversionRate : ''),
      saleFrontCompanyId: new FormControl(data ? data.saleFrontCompanyId : ''),
      saleFrontCompanyCode: new FormControl(data ? data.saleFrontCompanyCode : ''),
      saleFrontCompanyName: new FormControl(data ? data.saleFrontCompanyName : ''),
      receiptConfirmationPrint: new FormControl(data ? data.receiptConfirmationPrint : ''),
      vehicleCode: new FormControl(data ? data.vehicleCode : ''),
      startTime: new FormControl(data ? data.startTime : '', [Validators.required]),
      endTime: new FormControl(data ? data.endTime : '', [Validators.required]),
      tankPercentageBefore: new FormControl(data ? data.tankPercentageBefore : ''),
      pressureBefore: new FormControl(data ? data.pressureBefore : ''),
      tankPercentageAfter: new FormControl(data ? data.tankPercentageAfter : ''),
      pressureAfter: new FormControl(data ? data.pressureAfter : ''),
      temperature: new FormControl(data ? data.temperature : ''),
      imagePath: new FormControl(data ? data.imagePath : ''),
      quantityMeasuringMethod: new FormControl(data ? data.quantityMeasuringMethod : ''),
      note: new FormControl(data ? data.note : ''),
      deliveryCompanyId: new FormControl(data ? data.deliveryCompanyId : ''),
      deliveryCompanyName: new FormControl(data ? data.deliveryCompanyName : ''),
      customerSignature: new FormControl(data ? data.customerCompanyName : ''),
      counterType: new FormControl(data ? data.counterType : ''),
      deliveryStatus: new FormControl(data ? data.deliveryStatus : ''),
      receiptNumber: new FormControl(data ? data.receiptNumber : ''),
      delivererUserId: new FormControl(data ? data.delivererUserId : ''),
      delivererUserName: new FormControl(data ? data.delivererUserName : ''),
      systemOwnerId: new FormControl(data ? data.systemOwnerId : this.currenUserLogin.systemOwnerId),
      acceptedByUserId: new FormControl(data ? data.acceptedByUserId : ''),
      declaredAt: new FormControl(data ? data.declaredAt : ''),
      declaredByUserId: new FormControl(data ? data.declaredByUserId : ''),
      acceptedAt: new FormControl(data ? data.acceptedAt : ''),
      customerCompanyBranchName: new FormControl(data ? data.customerCompanyBranchName : ''),
      isAcceptanceRequired: new FormControl(data ? data.isAcceptanceRequired : ''),
      saleFrontBillingCompanyName: new FormControl(data ? data.saleFrontBillingCompanyName : ''),
      deliveryOfficeName: new FormControl(this.deliveryCompanyOfficeName),
      deliveryOfficialName: new FormControl(this.deliveryCompanyOfficialName),
      saleBillingCompanyName: new FormControl(data ? data.saleBillingCompanyName : ''),
    });
  }


  getDataReceipt(id?: string): void {
    this.loadingService.showAppLoading();
    this.isConfirmWeb = false;
    this.receiptService.getReceipt(id).subscribe((data) => {
      this.getQuantityMeasuringMethodLabel(data.quantityMeasuringMethod);
      this.dataDetail = data;
      if (!data.isAcceptanceRequired || !data.isConfirmationWeb || ( (data.isConfirmationWebCustomer || data.isConfirmationWebSale)
        && data.confirmationWebAt && data.confirmationWebByUserId)) {
        this.isConfirmWeb = true;
      }
      this.customerCompanyCode = data.customerCompanyCode;
      this.roundingMethod = data.roundingMethod;
      this.delivererUserId = data.delivererUserId;
      this.userId = data.delivererUserId;
      if (this.suggestDelivery) {
        localStorage.removeItem('isDeliveryParent');
        localStorage.setItem('isDeliveryParent', data.isDeliveryParent);
      }
      this.vehicle = data.vehicleCode;
      this.deliveryCompanyId = data.deliveryCompanyId;
      this.productBillingName = data.productBillingName;
      this.delivererUserName = data.delivererUserName;
      this.counterType = data.counterType;
      this.counter = data.counterType;
      this.updateStartTime = data.startTime;
      this.updateEndTime = data.endTime;
      this.customerCompanyId = data.customerCompanyId;
      this.deliveryDate = data.deliveryDate;
      this.vehicleCode = data.vehicleCode;
      this.receiptNumber = data.receiptNumber;
      this.amount = this.cp.transform(data.amount, '', '', '0.0');
      this.counterFlowAmount = this.cp.transform(data.counterFlowAmount, '', '', '0.0');
      this.checkImage = data.imagePath;
      this.disableEditFormDelivery = true;
      this.formReceipt.get('productName').setValue(data.productBillingName);
      this.formReceipt.get('vehicleCode').setValue(data.vehicleCode);
      this.formReceipt.get('delivererUserId').setValue(data.delivererUserId);
      this.editDate = moment(data.deliveryDate, 'DD-MM-YYYY');

      if (data.deliveryCompanyOfficeName || data.deliveryCompanyOfficialName) {
        this.deliveryCompanyOfficeName = data.deliveryCompanyOfficeName;
        this.deliveryCompanyOfficialName = data.deliveryCompanyOfficialName;
      } else {
        this.deliveryCompanyOfficeName = '';
        this.deliveryCompanyOfficialName = '';
      }

      if (data.deliveryDateMonth < 10) {
        this.editMonth = '0' + data.deliveryDateMonth;
      } else {
        this.editMonth = data.deliveryDateMonth;
      }
      if (data.deliveryDateDay < 10) {
        this.editDay = '0' + data.deliveryDateDay;
      } else {
        this.editDay = data.deliveryDateDay;
      }
      this.buildForm(data);
      this.oldValueForm = JSON.parse(JSON.stringify(data));
      this.companyNameText.setValue(data.customerCompanyName);
      this.companyCodeText.setValue(data.customerCompanyCode);
      if (data.customerCompanyId) {
        this.getCompanyByCodeUpdateType(data.customerCompanyCode);
      }
      // if (this.isProvider && data.delivererUserId) {
      //   this.getUserByDeliveryId(data.delivererUserId);
      // }
      if (data.deliveryDate) {
        const day = data.deliveryDate.slice(0, 2);
        const month = data.deliveryDate.slice(2, 6);
        const year = data.deliveryDate.slice(6, 10);
        const date = year + month + day;
        this.getDayOfWeek(date);
      }
      this.startTimeData = data.startTime ? this.formatTimeGetServe(data.startTime) : this.startTimeData;
      this.endTimeData = data.endTime ? this.formatTimeGetServe(data.endTime) : this.endTimeData;
      this.updateTime(this.startTimeData, 'startTime', '');
      this.updateTime(this.endTimeData, 'endTime', '');
      if (data.isAcceptanceRequired === false && data.acceptedAt != null && data.acceptedByUserId != null ||
        data.isAcceptedByProxy === true && data.isAcceptanceRequired === true && data.acceptedAt != null) {
        this.labelCompanyName = '＊ ';
      } else if (data.acceptedAt == null && data.acceptedByUserId == null) {
        this.labelCompanyName = '― ';
      } else if (data.isAcceptanceRequired === true && data.isAcceptedByProxy === false
        && data.acceptedAt != null && data.acceptedByUserId != null) {
        this.labelCompanyName = '〇 ';
      }

      if (data.isAcceptanceRequired && (data.isConfirmationWebCustomer || data.isConfirmationWebSale)
        && data.confirmationWebAt && data.confirmationWebByUserId) {
        this.labelCompanyName = '●';
      }
      if (!data.imagePath) {
        this.imageURL = '';
      } else {
        this.imageURL = this.urlImgOn + data.imagePath;
      }
      this.checkStatusBtn(data);
      this.checkStatusForm(data.deliveryStatus);
      this.loadingService.hideAppLoading();
    }, ((error: { success: boolean, message: string }) => {
      this.loadingService.hideAppLoading();
      this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.CD0092', 'BTN.OK', () => {
        this.router.navigateByUrl('list/deliveries-history');
      });
    }), () => {
      this.loadingService.hideAppLoading();
      this.isClick = false;
      this.globalData.setStatusDataEdit(false);
    });
  }
  getUserByDeliveryId(id: any, Check?: boolean): void {
    this.listVehicle = [];
    if (id) {
      this.receiptService.getUserByDeliveryId(id).subscribe((res) => {
        this.listVehicle = res.vehicleList;
        if (Check === true && !this.id) {
          this.counterType = this.listVehicle[0]?.counterType;
          this.formReceipt.get('counterType').setValue(this.listVehicle[0]?.counterType);
          this.formReceipt.get('vehicleCode').setValue(this.listVehicle[0]?.vehicleCode);
        }
        if (!Check && this.id) {
          this.formReceipt.get('vehicleCode').setValue(this.vehicle);
        }
        const vehicle = this.listVehicle.filter(x => x.vehicleCode === this.vehicleCode);
        if (!this.id) {
          if ((this.listDeliveryUser.length === 0 || this.listDeliveryUser === null) && this.listVehicle.length === 0) {
            const content = [this.textErrDeliveryUser, this.textErrVehicle].join('\n');
            this.confirmPopup.notify('COMMON_DIALOG.TT0003', content);
            return;
          }
          if (this.listDeliveryUser.length === 0 || this.listDeliveryUser === null) {
            this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.ER0203');
            return;
          }
        }
        if (vehicle.length >= 1 && this.id && Check != false) {
          this.formReceipt.get('vehicleCode').valueChanges.subscribe(res => {
            const couterType = this.listVehicle.find(x => x.vehicleCode === res);
            this.counterType = couterType?.counterType;
          });
          this.formReceipt.get('vehicleCode').setValue(this.vehicleCode);
          this.formReceipt.get('counterType').setValue(this.counterType);
        } else if (vehicle.length === 0 && this.id && !this.suggestDelivery) {
          this.vehicleCode = this.listVehicle[0]?.vehicleCode;
          this.formReceipt.get('counterType').setValue(this.listVehicle[0]?.counterType);
          this.counterType = this.formReceipt.get('counterType').value;
          this.formReceipt.get('vehicleCode').setValue(this.listVehicle[0]?.vehicleCode);
        }
        if (this.suggestDelivery && vehicle.length === 0 && this.id) {
          this.formReceipt.get('vehicleCode').setValue(this.vehicleCode);
          this.formReceipt.get('counterType').setValue(this.counterType);
        }
        if (this.listVehicle.length === 0) {
          this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.ER0204');
          return;
        }
      });
    } else {
      this.formReceipt.get('vehicleCode').setValue('');
    }
  }
  formatTimeGetServe(time: string): { hour: number; minute: number; second: number } {
    if (!time) {
      return;
    }
    const data = { hour: 0, minute: 0, second: 0 };
    const rawTime = time.split(' ')[1];
    data.hour = parseInt(rawTime.split(':')[0], 10);
    data.minute = parseInt(rawTime.split(':')[1], 10);
    return data;
  }
  activeEdit(): void {
    this.disableEditFormDelivery = false;
    this.formDisable = false;
    (this.id) && this.onListenFormChange();
  }
  addValidateForm(): void {
    this.formReceipt.get('customerCompanyId').setValidators([CustomValidators.required()]);
    this.formReceipt.get('customerCompanyId').updateValueAndValidity();
    this.formReceipt.get('customerCompanyCode').setValidators([CustomValidators.required()]);
    this.formReceipt.get('customerCompanyCode').updateValueAndValidity();
    this.formReceipt.get('customerCompanyName').setValidators([CustomValidators.required()]);
    this.formReceipt.get('customerCompanyName').updateValueAndValidity();
    this.formReceipt.get('deliveryDate').setValidators([CustomValidators.required()]);
    this.formReceipt.get('deliveryDate').updateValueAndValidity();
    this.formReceipt.get('productId').setValidators([CustomValidators.required()]);
    this.formReceipt.get('productId').updateValueAndValidity();
    this.formReceipt.get('productName').setValidators([CustomValidators.required()]);
    this.formReceipt.get('productName').updateValueAndValidity();
    this.formReceipt.get('saleFrontCompanyId').setValidators([CustomValidators.required()]);
    this.formReceipt.get('saleFrontCompanyId').updateValueAndValidity();
    this.formReceipt.get('saleFrontCompanyCode').setValidators([CustomValidators.required()]);
    this.formReceipt.get('saleFrontCompanyCode').updateValueAndValidity();
    this.formReceipt.get('startTime').setValidators([CustomValidators.required()]);
    this.formReceipt.get('startTime').updateValueAndValidity();
    this.formReceipt.get('deliveryCompanyId').setValidators([CustomValidators.required()]);
    this.formReceipt.get('deliveryCompanyId').updateValueAndValidity();
    this.formReceipt.get('deliveryOfficialName').setValidators([CustomValidators.required()]);
    this.formReceipt.get('deliveryOfficialName').updateValueAndValidity();
    this.formReceipt.get('vehicleCode').setValidators([CustomValidators.required()]);
    this.formReceipt.get('vehicleCode').updateValueAndValidity();
    this.formReceipt.get('delivererUserId').setValidators([CustomValidators.required()]);
    this.formReceipt.get('delivererUserId').updateValueAndValidity();
    this.formReceipt.get('temperature').setValidators([this.validateTemperature()]);
    this.formReceipt.get('temperature').updateValueAndValidity();
    this.formReceipt.get('pressureBefore').setValidators([this.validatePressure()]);
    this.formReceipt.get('pressureBefore').updateValueAndValidity();
    this.formReceipt.get('pressureAfter').setValidators([this.validatePressure()]);
    this.formReceipt.get('pressureAfter').updateValueAndValidity();
    this.formReceipt.get('endTime').setValidators([CustomValidators.required()]);
    this.formReceipt.get('endTime').updateValueAndValidity();
    this.formReceipt.get('tankPercentageBefore').setValidators([this.validateTankPercentage()]);
    this.formReceipt.get('tankPercentageBefore').updateValueAndValidity();
    this.formReceipt.get('tankPercentageAfter').setValidators([this.validateTankPercentage()]);
    this.formReceipt.get('tankPercentageAfter').updateValueAndValidity();
    this.formReceipt.get('deliveryOfficeName').setValidators([CustomValidators.required()]);
    this.formReceipt.get('deliveryOfficeName').updateValueAndValidity();
    this.formReceipt.get('saleFrontBillingCompanyName').setValidators([CustomValidators.required()]);
    this.formReceipt.get('saleFrontBillingCompanyName').updateValueAndValidity();
    this.formReceipt.get('conversionRate').setValidators([CustomValidators.required()]);
    this.formReceipt.get('conversionRate').updateValueAndValidity();
    this.formReceipt.get('saleBillingCompanyName').setValidators([CustomValidators.required()]);
    this.formReceipt.get('saleBillingCompanyName').updateValueAndValidity();
  }

  resetValidate(): void {
    this.formReceipt.get('customerCompanyId').clearValidators();
    this.formReceipt.get('customerCompanyId').updateValueAndValidity();
    this.formReceipt.get('customerCompanyCode').clearValidators();
    this.formReceipt.get('customerCompanyCode').updateValueAndValidity();
    this.formReceipt.get('customerCompanyName').clearValidators();
    this.formReceipt.get('customerCompanyName').updateValueAndValidity();
    this.formReceipt.get('deliveryDate').clearValidators();
    this.formReceipt.get('deliveryDate').updateValueAndValidity();
    this.formReceipt.get('productId').clearValidators();
    this.formReceipt.get('productId').updateValueAndValidity();
    this.formReceipt.get('productName').clearValidators();
    this.formReceipt.get('productName').updateValueAndValidity();
    this.formReceipt.get('saleFrontCompanyId').clearValidators();
    this.formReceipt.get('saleFrontCompanyId').updateValueAndValidity();
    this.formReceipt.get('saleFrontCompanyCode').clearValidators();
    this.formReceipt.get('saleFrontCompanyCode').updateValueAndValidity();
    this.formReceipt.get('saleFrontCompanyName').clearValidators();
    this.formReceipt.get('saleFrontCompanyName').updateValueAndValidity();
    this.formReceipt.get('startTime').clearValidators();
    this.formReceipt.get('startTime').updateValueAndValidity();
    this.formReceipt.get('deliveryCompanyId').clearValidators();
    this.formReceipt.get('deliveryCompanyId').updateValueAndValidity();
    this.formReceipt.get('deliveryCompanyName').clearValidators();
    this.formReceipt.get('deliveryCompanyName').updateValueAndValidity();
    this.formReceipt.get('deliveryOfficeName').clearValidators();
    this.formReceipt.get('deliveryOfficeName').updateValueAndValidity();
    this.formReceipt.get('amount').clearValidators();
    this.formReceipt.get('amount').updateValueAndValidity();
    this.formReceipt.get('vehicleCode').clearValidators();
    this.formReceipt.get('vehicleCode').updateValueAndValidity();
    this.formReceipt.get('delivererUserId').clearValidators();
    this.formReceipt.get('delivererUserId').updateValueAndValidity();
    this.formReceipt.get('temperature').clearValidators();
    this.formReceipt.get('temperature').updateValueAndValidity();
    this.formReceipt.get('pressureBefore').clearValidators();
    this.formReceipt.get('pressureBefore').updateValueAndValidity();
    this.formReceipt.get('pressureAfter').clearValidators();
    this.formReceipt.get('pressureAfter').updateValueAndValidity();
    this.formReceipt.get('endTime').clearValidators();
    this.formReceipt.get('endTime').updateValueAndValidity();
    this.formReceipt.get('tankPercentageAfter').clearValidators();
    this.formReceipt.get('tankPercentageAfter').updateValueAndValidity();
    this.formReceipt.get('tankPercentageBefore').clearValidators();
    this.formReceipt.get('tankPercentageBefore').updateValueAndValidity();
    this.formReceipt.get('saleFrontBillingCompanyName').clearValidators();
    this.formReceipt.get('saleFrontBillingCompanyName').updateValueAndValidity();
  }

  focusErrorField(): void {
    setTimeout(() => {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
        '.harErrorBorder'
      );
      if (firstInvalidControl) {
        firstInvalidControl.focus();
      }
    });
  }
  saveReceipt(statusDelivery?: number): void {
    this.submittedFormDelivery = true;
    if (statusDelivery === 1) {
      this.addValidateForm();
      if (this.formReceipt.invalid || this.validateTankDiff || this.validateConversionRate ||
        // tslint:disable-next-line:max-line-length
        this.validateTimeDiff || this.validateAmountNumber || this.validateAmount || this.validateUpdateTimeDiff || this.validateCounterFlowAmountNumber || this.validateCounterFlowAmount || this.validateTemperatureForm) {
        const rawContent = [];
        const fields = [
          'customerCompanyName',
          'productName',
          'delivererUserId',
          'vehicleCode',
          'saleFrontCompanyName',
          'startTime',
          'endTime',
          'temperature',
          'pressureAfter',
          'pressureBefore',
          'tankPercentageBefore',
          'tankPercentageAfter',
          'deliveryCompanyName',
        ];
        fields.map(field => {
          if (this.form(field).errors) {
            rawContent.push(this.messError[field]);
          }
        });
        if (this.validateAmountNumber) {
          rawContent.push(this.textErrCounterFlowAmount);
        }
        if (this.validateAmount) {
          rawContent.push(this.textAmount);
        }
        if (this.validateConversionRate) {
          rawContent.push(this.conversionRate);
        }
        if (this.validateTemperatureForm) {
          rawContent.push(this.temperature);
        }
        if (this.validateTimeDiff) {
          rawContent.push(this.textErrStartTime);
        }
        if (this.validateCounterFlowAmount && this.counterType === 0) {
          rawContent.push(this.textErrcounterFlowAmount);
        }
        if (this.validateCounterFlowAmountNumber) {
          rawContent.push(this.textErrcounterFlowAmountNumber);
        }
        if (this.validateUpdateTimeDiff) {
          rawContent.push(this.textErrDate);
        }
        if (this.validateTankDiff) {
          rawContent.push(this.textErrPressureBefore);
        }
        if (rawContent.length > 0) {
          const content = rawContent.join('\n');
          this.confirmPopup.notify('COMMON_DIALOG.TT0003', content, 'BTN.OK', () => {
            this.focusErrorField();
            return;
          });
        }
      }
    }

    const deliveryDate = new Date(this.form('deliveryDateYear').value + '-' + this.form('deliveryDateMonth').value + '-' + this.form('deliveryDateDay').value);
    const amountTmp = this.formReceipt.get('amount').value.replace(',', '');
    const bodyCheck = {
      amount: amountTmp,
      customerCompanyCode: this.formReceipt.get('customerCompanyCode').value,
      deliveryDate: moment(deliveryDate).format('DD-MM-YYYY'),
      deliveryId: this.id ? this.id : 0
    };

    this.loadingService.showAppLoading();
    this.receiptService.checkUnique(bodyCheck).subscribe(value => {
      this.loadingService.hideAppLoading();
      const flag = value.isDuplicate;
      let message = '';
      if (flag && !this.formReceipt.controls.imagePath.value) {
        message = this.translateService.instant('MESS.WARNING0003');
      } else if (flag && this.formReceipt.controls.imagePath.value) {
        message = this.translateService.instant('MESS.WARNING0002');
      } else if (!flag && !this.formReceipt.controls.imagePath.value) {
        message = this.translateService.instant('MESS.CD0081');
      } else {
        message = statusDelivery === 0 ? this.translateService.instant('MESS.AM0402') :  this.translateService.instant('MESS.CD0001');
      }

      this.confirmPopup.confirm(value.isDuplicate || this.formReceipt.controls.imagePath.value === '' ? 'COMMON_DIALOG.TT0002' : 'COMMON_DIALOG.TT0001', message,
        flag ? 'BTN.OK' : (statusDelivery === 0 ? 'BTN.DRAFT' : 'BTN.OK'), 'BTN.CANCEL', () => {
          this.doSaveReceipt(statusDelivery);
        });
    }, (error: { success: boolean, message: string }) => {
      this.loadingService.hideAppLoading();
    });
  }
  doSaveReceipt(statusDelivery): void {
    const amountTmp = this.formReceipt.get('amount').value.replace(',', '');
    this.formReceipt.get('deliveryStatus').setValue(statusDelivery);
    const body = this.formReceipt.value;
    const startTime = this.form('startTime').value;
    const endTime = this.form('endTime').value;
    const endTimeFirst = Number(endTime.slice(0, 2)) + 24;
    const endTimeLast = endTime.slice(3, 5);
    const startTimeVali: any = startTime.replace(':', '');
    const endTimeVali: any = endTimeFirst + '' + endTimeLast;
    if ((endTimeVali - startTimeVali) <= 200) {
        this.checkStartTime = true;
      }
    body.customerSignature = body.customerCompanyName;
    body.productName = this.productName;
    body.productBillingName = this.productBillingName;
    body.systemOwnerId = this.form('systemOwnerId').value ?
        this.form('systemOwnerId').value : this.currenUserLogin.systemOwner;
    body.deliveryDate = this.form('deliveryDateDay').value + '-' + this.form('deliveryDateMonth').value + '-' + this.form('deliveryDateYear').value;
    body.amount = amountTmp;
    if (this.checkStartTime) {
        // tslint:disable-next-line:max-line-length no-shadowed-variable
        const deliveryDate = new Date(this.form('deliveryDateYear').value + '-' + this.form('deliveryDateMonth').value + '-' + this.form('deliveryDateDay').value);
        deliveryDate.setDate(deliveryDate.getDate() - 1);
        const date = moment(deliveryDate).format('DD-MM-YYYY');
        body.startTime = date + ' ' + body.startTime;
      } else {
        body.startTime = body.deliveryDate + ' ' + body.startTime;
      }
    if (this.dateEndTimeNow && this.id) {
        body.deliveryDate = this.dateEndTimeNow;
        body.endTime = this.dateEndTimeNow + ' ' + body.endTime;
      } else {
        body.endTime = body.deliveryDate + ' ' + body.endTime;
      }
    body.amount = this.formReceipt.value.amount.replaceAll(',', '');
    if (this.formReceipt.value.counterFlowAmount) {
        body.counterFlowAmount = this.formReceipt.value.counterFlowAmount.replaceAll(',', '');
      }
    if (this.id) {
        // this.getCompanyByCode(this.companyCode, '')
        this.loadingService.showAppLoading();
        this.receiptService.updateReceipt(this.id, body).subscribe((data) => {
          this.disableEditFormDelivery = true;
          this.loadingService.hideAppLoading();
          this.formReceipt.markAsPristine();
        }, (error: { success: boolean, message: string }) => {
          this.loadingService.hideAppLoading();
          this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
        }, () => {
          this.globalData.setStatusDataEdit(false);
          this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.CD0094', 'BTN.OK', () => {
            this.getDataReceipt(this.id);
          });
        });
      } else {
        let deliveryId = null;
        this.loadingService.showAppLoading();
        this.receiptService.createReceipt(body).subscribe((data) => {
          this.globalData.setListIdReceipt([{ deliveryId: data.deliveryId, type: false }]);
          deliveryId = data.deliveryId;
          this.disableEditFormDelivery = true;
          this.formReceipt.markAsPristine();
          this.loadingService.hideAppLoading();
        }, (error: { success: boolean, message: string }) => {
          this.loadingService.hideAppLoading();
          this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
        }, () => {
          this.globalData.setStatusDataEdit(false);
          this.confirmPopup.notify('COMMON_DIALOG.TT0002', statusDelivery === 0 ? 'MESS.CD0057' : 'MESS.CD0050', 'BTN.OK', () => {
            this.router.navigateByUrl('detail/deliveries-form/' + deliveryId).then();
          });
        });
    }
  }
  form(control: string): AbstractControl {
    return this.formReceipt.get(control);
  }

  getValueCompany(data): void {
    if (!data) {
      this.formReceipt.get('customerCompanyId').setValue('', { emitEvent: false });
      this.formReceipt.get('customerCompanyCode').setValue('', { emitEvent: false });
      this.formReceipt.get('customerCompanyName').setValue('', { emitEvent: false });
      return;
    }
    this.companyCode = data.companyCode;
    this.companyCodeText.setValue(data.companyCode, { emitEvent: false });
    this.companyNameText.setValue(data.companyName, { emitEvent: false });
    this.formReceipt.get('customerCompanyId').setValue(data.companyId, { emitEvent: false });
    this.formReceipt.get('customerCompanyCode').setValue(data.companyCode, { emitEvent: false });
    this.formReceipt.get('customerCompanyName').setValue(data.companyName, { emitEvent: false });
    if (this.customerCompanyId === data.companyId) {
      this.formReceipt.get('delivererUserId').setValue(this.delivererUserId);
      this.formReceipt.get('vehicleCode').setValue(this.form('vehicleCode').value);
      this.getCompanyByCode(data.companyCode, 'checkCompanyId');
    } else {
      this.getCompanyByCode(data.companyCode);
    }
    this.formReceipt.markAsDirty();
  }

  getCompanyByCode(companyCode?, type?): void {
    this.listDeliveryUser = [];
    if (type === 'checkCompanyId') {
      this.receiptService.getCompanyByCode(companyCode).subscribe((res) => {
        this.productName = res.productName;
        this.productBillingName = res.productBillingName;
        this.getQuantityMeasuringMethodLabel(res.quantityMeasuringMethod);
        if (!res) {
          return;
        }
        this.form('saleFrontCompanyId').setValue(res.saleFrontCompanyId);
        this.form('saleFrontCompanyCode').setValue(res.saleFrontCompanyCode);
        this.form('saleFrontCompanyName').setValue(res.saleFrontCompanyName);
        this.form('saleBillingCompanyName').setValue(res.saleBillingCompanyName);
        this.form('productId').setValue(res.productId);
        this.form('productName').setValue(res.productBillingName);
        this.form('conversionRate').setValue(res.conversionRate);
        this.form('quantityMeasuringMethod').setValue(res.quantityMeasuringMethod);
        this.form('customerCompanyBranchName').setValue(res.customerCompanyBranchName);
        if (this.id) {
          this.listDeliveryUser = res.userDeliveryList?.filter(elm => elm.deliveryCompanyId === this.deliveryCompanyId);
          this.getUserByDeliveryId(this.userId);
        } else {
          this.listDeliveryUser = res.userDeliveryList ? res.userDeliveryList : [];
        }
      });
    } else {
      this.receiptService.getCompanyByCode(companyCode).subscribe((res) => {
        this.listDeliveryUser = res.userDeliveryList;
        this.productName = res.productName;
        this.productBillingName = res.productBillingName;
        this.getQuantityMeasuringMethodLabel(res.quantityMeasuringMethod);
        if (!res) {
          return;
        }
        this.form('saleFrontCompanyId').setValue(res.saleFrontCompanyId);
        this.form('saleFrontCompanyCode').setValue(res.saleFrontCompanyCode);
        this.form('saleFrontCompanyName').setValue(res.saleFrontCompanyName);
        this.form('saleBillingCompanyName').setValue(res.saleBillingCompanyName);
        this.form('productId').setValue(res.productId);
        this.form('productName').setValue(res.productBillingName);
        this.form('conversionRate').setValue(res.conversionRate);
        this.form('quantityMeasuringMethod').setValue(res.quantityMeasuringMethod);
        this.form('customerCompanyBranchName').setValue(res.customerCompanyBranchName);
        if (this.id) {
          if (companyCode != this.customerCompanyCode) {
            const deliveryCompanyId = this.listDeliveryUser?.find(x => x.deliveryCompanyId === this.deliveryCompanyId);
            if (deliveryCompanyId?.deliveryCompanyId === this.deliveryCompanyId) {
              this.form('delivererUserId').setValue(this.delivererUserId);
              this.form('delivererUserName').setValue(this.formReceipt.get('delivererUserName').value);
              this.form('deliveryCompanyId').setValue(this.formReceipt.get('deliveryCompanyId').value);
              this.form('deliveryCompanyName').setValue(this.formReceipt.get('deliveryCompanyName').value);
              this.form('deliveryOfficialName').setValue(this.formReceipt.get('deliveryOfficialName').value);
              this.form('deliveryOfficeName').setValue(this.formReceipt.get('deliveryOfficeName').value);
              this.getUserByDeliveryId(this.formReceipt.get('delivererUserId').value, true);
            } else {
              this.getUserByDeliveryId(this.listDeliveryUser[0].userId, true);
              this.form('delivererUserId').setValue(this.listDeliveryUser[0].userId);
              this.form('delivererUserName').setValue(this.listDeliveryUser[0].userName);
              this.form('deliveryCompanyId').setValue(this.listDeliveryUser[0].deliveryCompanyId);
              this.form('deliveryCompanyName').setValue(this.listDeliveryUser[0].deliveryCompanyName);
              this.form('deliveryOfficialName').setValue(this.listDeliveryUser[0].deliveryOfficialName);
              this.form('deliveryOfficeName').setValue(this.listDeliveryUser[0].deliveryOfficeName);
            }
          } else {
            this.listDeliveryUser = res.userDeliveryList?.filter(elm => elm.deliveryCompanyId === this.deliveryCompanyId);
            this.formReceipt.get('delivererUserId').setValue(this.delivererUserId);
            this.formReceipt.get('vehicleCode').setValue(this.vehicleCode);
          }

        } else {
          this.roundingMethod = res.roundingMethod;
          this.listDeliveryUser = res?.userDeliveryList;
          if (res.userDeliveryList != null) {
            if (this.authService.isDelivery) {
              this.getUserByDeliveryId(this.listDeliveryUser[0]?.userId, true);
              for (const item of this.listDeliveryUser) {
                if (item.userId === parseInt(this.form('delivererUserId').value, 10)) {
                  this.form('deliveryCompanyId').setValue(item.deliveryCompanyId);
                  this.form('deliveryCompanyName').setValue(item.deliveryCompanyName);
                  this.form('deliveryOfficialName').setValue(item.deliveryOfficialName);
                  this.form('deliveryOfficeName').setValue(item.deliveryOfficeName);
                }
              }
            } else {
              this.form('delivererUserId').setValue(this.listDeliveryUser[0].userId);
              this.form('delivererUserName').setValue(this.listDeliveryUser[0].userName);
              this.form('deliveryCompanyId').setValue(this.listDeliveryUser[0].deliveryCompanyId);
              this.form('deliveryCompanyName').setValue(this.listDeliveryUser[0].deliveryCompanyName);
              this.form('deliveryOfficialName').setValue(this.listDeliveryUser[0].deliveryOfficialName);
              this.form('deliveryOfficeName').setValue(this.listDeliveryUser[0].deliveryOfficeName);
            }
            if (!this.authService.isDelivery) {
              this.getUserByDeliveryId(this.listDeliveryUser[0].userId, true);
            } else {
              this.getUserByDeliveryId(this.authService.currentUserValue.userId);
            }
          } else {
            if (this.authService.isDelivery) {
              this.getUserByDeliveryId(this.listDeliveryUser[0]?.userId, true);
              for (const item of this.listDeliveryUser) {
                if (item.userId === parseInt(this.form('delivererUserId').value, 10)) {
                  this.form('deliveryCompanyId').setValue('');
                  this.form('deliveryCompanyName').setValue('');
                  this.form('deliveryOfficialName').setValue('');
                  this.form('deliveryOfficeName').setValue('');
                }
              }
            } else {
              this.form('delivererUserName').setValue('');
              this.form('delivererUserId').setValue('');
              this.form('deliveryCompanyId').setValue('');
              this.form('deliveryCompanyName').setValue('');
              this.form('deliveryOfficialName').setValue('');
              this.form('deliveryOfficeName').setValue('');
            }
            if (!this.authService.isDelivery) {
              this.getUserByDeliveryId('');
            } else {
              this.getUserByDeliveryId('');
            }
          }
        }
      });
    }
  }
  getCompanyByCodeUpdateType(companyCode?): void {
    if (this.id) {
      this.listDeliveryUser = [];
      this.receiptService.getCompanyByCode(companyCode).subscribe((res) => {
        if (!res) {
          return;
        }
        this.listDeliveryUser = res?.userDeliveryList?.filter(elm => elm.deliveryCompanyId === this.deliveryCompanyId);
        if (this.listDeliveryUser) {
          for (const item of this.listDeliveryUser) {
            if (this.delivererUserId === item.userId || this.delivererUserId != item.userId) {
              this.checkUserName = true;
              break;
            } else {
              this.checkUserName = false;
            }
          }
        }
        if (!this.authService.isDelivery) {
          this.getUserByDeliveryId(this.dataDetail.delivererUserId);
        } else {
          const check = localStorage.getItem('isDeliveryParent');
          if (check !== 'true') {
            this.getUserByDeliveryId(this.delivererUserId);
          } else {
            this.getUserByDeliveryId(this.authService.currentUserValue.userId);
          }
        }
      });
    }

  }
  convertDecimalMpa(parameter, type): void {
    const formName: string = parameter.target.attributes.formcontrolname.nodeValue;
    const regExp = new RegExp(/^[0-9０-９.]{1,}$|^[-]?[0-9]+(\.[0-9]+)?|[ー]?[０-９]+(\．[０-９]+)?$/);
    const reg = new RegExp(/[ぁ-んァ-ン一-龥\D]{1,}/);
    let ckeckNumber = regExp.test(parameter.target.value);
    const checkFullWidth = reg.test(parameter.target.value);
    if (parameter.target.value === '') {
      ckeckNumber = true;
    }

    if (ckeckNumber === false) {
      if (!ckeckNumber) {
        this.form(formName).setValue('', { emitEvent: false });
      }
    } else {
      const value = this.formReceipt.get(formName).value.replace(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0))
        .replace(/\u3000/g, '\u0020').replaceAll('-', '');

      this.form(formName).setValue(value, { emitEvent: false });
    }
  }

  convertDecimalC(parameter, type): void {
    const formName: string = parameter.target.attributes.formcontrolname.nodeValue;
    const regExp = new RegExp(/^\s*[+-]|[\ー]|[0-9]|[０-９]|[\-]\s*$/);
    let ckeckNumber = regExp.test(parameter.target.value);
    if (parameter.target.value === '') {
      ckeckNumber = true;
    }
    if (ckeckNumber === false) {
      this.form(formName).setValue('', { emitEvent: false });
    } else {
      const value = this.formReceipt.get(formName).value.replace(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0))
        .replace(/\u3000/g, '\u0020').replaceAll('ー', '-').replaceAll('ｰ', '-');
      const reg = new RegExp(/^[-+]$|^[+-]?[0-9]+$/);
      const check = reg.test(value);
      if (check) {
        this.form(formName).setValue(value, { emitEvent: false });
      } else {
        this.form(formName).setValue('', { emitEvent: false });
      }
    }
  }

  convertDecimal(parameter, type): void {
    const formName: string = parameter.target.attributes.formcontrolname.nodeValue;
    const regExp = new RegExp(/^[0-9０-９]+$/);
    let ckeckNumber = regExp.test(parameter.target.value);
    if (parameter.target.value === '') {
      ckeckNumber = true;
    }
    if (ckeckNumber === false) {
      this.form(formName).setValue('', { emitEvent: false });
    } else {
      const value = this.formReceipt.get(formName).value.replace(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0))
        .replace(/\u3000/g, '\u0020').replaceAll('.', '');
      this.form(formName).setValue(value, { emitEvent: false });
    }
  }
  convertDecimalKg(parameter?, type?): void {
    switch (type) {
      case 'amount':
        setTimeout(() => {
          if (this.formReceipt.get('amount').value) {
            const value = this.formReceipt.get('amount').value.replaceAll(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0))
              .replaceAll(/\u3000/g, '\u0020').replaceAll(',', '').replaceAll('-', '').replaceAll('.', '');
            const reg = new RegExp(/^[0-9]+$/);
            const check = reg.test(value);
            if (check) {
              this.formReceipt.get('amount').setValue(this.cp.transform(value, '', '', '0.0'), { emitEvent: false });
            } else {
              this.formReceipt.get('amount').setValue('', { emitEvent: false });
            }
          }
        }, 0);
        break;
      case 'counterFlowAmount':
        if (this.formReceipt.get('counterFlowAmount').value) {
          setTimeout(() => {
            const value = this.formReceipt.get('counterFlowAmount').value.replaceAll(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0))
              .replaceAll(/\u3000/g, '\u0020').replaceAll(',', '').replaceAll('-', '').replaceAll('.', '');
            const reg = new RegExp(/^[0-9]+$/);
            const check = reg.test(value);
            if (check) {
              this.formReceipt.get('counterFlowAmount').setValue(this.cp.transform(value, '', '', '0.0'), { emitEvent: false });
            } else {
              this.formReceipt.get('counterFlowAmount').setValue('', { emitEvent: false });
            }
          }, 0);
          if (parameter.target.value !== '' && this.formReceipt.value.conversionRate != null && this.formReceipt.value.conversionRate !== '') {
            const valueAmount = parameter.target.value.replaceAll(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0))
              .replaceAll(/\u3000/g, '\u0020').replaceAll('-', '');
            const amount = Math.round(this.formReceipt.value.conversionRate * valueAmount.replaceAll(',', '') * 10000) / 10000;
            if (this.roundingMethod === 1) {
              this.formReceipt.get('amount').setValue(this.cp.transform(Math.round(amount), '', '', '0.0'));
            } else {
              this.formReceipt.get('amount').setValue(this.cp.transform(Math.floor(amount), '', '', '0.0'));
            }
            if (parameter.target.value === '') {
              this.formReceipt.get('amount').setValue('');
            }
          }
        }
        break;
    }
  }
  resetForm(): void {
    this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'MESS.CD0002', 'BTN.OK', 'BTN.CANCEL', () => {
      this.resetValidate();
      this.globalData.setStatusDataEdit(false);
      this.resetFile();
      if (this.id) {
        if (!this.checkImage) {
          this.imageURL = '';
        }
        if (this.checkImage) {
          this.imageURL = this.urlImgOn + this.checkImage;
        }
        this.formReceipt.get('imagePath').setValue(this.checkImage);
        this.buildForm(this.oldValueForm);
        this.companyNameText.setValue(this.oldValueForm.customerCompanyName);
        this.companyCodeText.setValue(this.oldValueForm.customerCompanyCode);
        if (this.oldValueForm.deliveryDate) {
          this.getDayOfWeek(this.oldValueForm.deliveryDate);
        }
        this.startTimeData = this.oldValueForm.startTime ? this.formatTimeGetServe(this.oldValueForm.startTime) : this.startTimeData;
        this.endTimeData = this.oldValueForm.endTime ? this.formatTimeGetServe(this.oldValueForm.endTime) : this.endTimeData;
        this.updateTime(this.startTimeData, 'startTime', '');
        this.updateTime(this.endTimeData, 'endTime', '');
      } else {
        this.getQuantityMeasuringMethodLabel('remove');
        this.formReceipt.reset();
        this.companyCodeText.setValue('', { emitEvent: false, onlySelf: true });
        this.companyNameText.setValue('', { emitEvent: false, onlySelf: true });
        this.imageURL = '';
        this.updateNowTime();
        const date = {
          day: moment().date(),
          month: moment().month() + 1,
          year: moment().year()
        };
        this.updateDate(date);
      }
      // this.getUserByDeliveryId(this.userId, false);
      this.getCompanyByCode(this.customerCompanyCode, 'checkCompanyId');
      if (this.authService.isDelivery && !this.id) {
        this.formReceipt.get('delivererUserName').setValue(this.currenUserLogin.name);
        this.formReceipt.get('delivererUserId').setValue(this.currenUserLogin.userId);
      } else if (this.authService.isDelivery && this.id) {
        this.form('delivererUserName').setValue(this.delivererUserName);
      } else {
        this.form('delivererUserName').setValue(this.delivererUserName);
        this.form('delivererUserId').setValue(this.userId);

      }
      this.vehicleCode = this.vehicle;
      this.delivererUserId = this.userId;
      this.counterType = this.counter;
      this.form('amount').setValue(this.amount);
    });

  }
  updateDataVehicleUser(): void {
    this.delivererUserId = this.form('delivererUserId').value;
    this.getUserByDeliveryId(this.delivererUserId, true);
    for (const item of this.listDeliveryUser) {
      if (parseInt(this.delivererUserId, 10) === item.userId) {
        this.form('delivererUserName').setValue(item?.userName);
        this.form('deliveryCompanyId').setValue(item?.deliveryCompanyId);
        this.form('deliveryCompanyName').setValue(item?.deliveryCompanyName);
        this.form('deliveryOfficialName')?.setValue(item?.deliveryOfficialName);
        this.form('deliveryOfficeName').setValue(item?.deliveryOfficeName);
        break;
      }
    }
  }

  updateDataVehicle(): void {
    this.vehicleCode = this.form('vehicleCode').value;
    for (const item of this.listVehicle) {
      if (item.vehicleCode === this.vehicleCode) {
        this.counterType = item.counterType;
        this.formReceipt.get('counterFlowAmount').setValue('', { emitEvent: false });
        this.formReceipt.get('counterType').setValue(this.counterType);
        break;
      }
    }
  }

  resetFile(): void {
    this.myInputVariable.nativeElement.value = '';
  }

  handleFileInput(files?: File, event?): void {
    this.fileUpload = files[0];
    if (!this.fileUpload) {
      return;
    }
    if (this.fileUpload.type !== 'image/png' && this.fileUpload.type !== 'image/ipg' &&
      this.fileUpload.type !== 'image/jpeg' && this.fileUpload.type !== 'image/bmp') {
      this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.ER0401');
      return;
    }
    if (this.fileUpload.size > MAX_SIZE_IMG) {
      this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.MS0405');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    const oldImageURL = this.imageURL;
    // tslint:disable-next-line:no-shadowed-variable
    reader.onload = (event) => {
      this.imageURL = event.target.result;
    };
    this.loadingService.showAppLoading();
    this.receiptService.uploadImage(this.fileUpload).subscribe((data) => {
      this.formReceipt.get('imagePath').setValue(data.path);
    }, (error: { success: boolean, message: string, errorDataFile }) => {
      this.resetFile();
      this.imageURL = oldImageURL;
      this.loadingService.hideAppLoading();
      if (error.message) {
        this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.' + error.message);
      } else if (error.errorDataFile) {
        const errStr = this.basicFunction.getErrStr(error.errorDataFile, this.translateService);
        this.confirmPopup.notify('COMMON_DIALOG.TT0003', errStr);
      }
    }, () => {
      this.resetFile();
      this.loadingService.hideAppLoading();
    });
  }


  selectFile(): void {
    this.myInputVariable.nativeElement.click();
    this.formReceipt.markAsDirty();
  }

  actionUpdateTime(data, type, check): void {
    this.updateTime(data, type, check);
    this.formReceipt.markAsDirty();
  }

  updateTime(data?: { hour: number, minute: number, second: number }, type?: string, check?: any, dateEndTimeNow?: any): void {
    if (!data) {
      let dataTime: any = {};
      if (type === 'startTime') {
        if (this.form('startTime').value) {
          const startTime = this.form('startTime').value?.split(':');
          dataTime = { hour: parseInt(startTime[0]), minute: parseInt(startTime[1]), second: 0 };
          this.startTimeData = dataTime;
        }
      } else if (type === 'endTime') {
        if (this.form('endTime').value) {
          const endTime = this.form('endTime').value?.split(':');
          dataTime = { hour: parseInt(endTime[0]), minute: parseInt(endTime[1]), second: 0 };
          this.endTimeData = dataTime;
        }
      }
      return;
    }
    const localData = JSON.parse(JSON.stringify(data));
    if (localData.hour < 10) {
      localData.hour = '0' + localData.hour;
    }
    if (localData.minute < 10) {
      localData.minute = '0' + localData.minute;
    }
    if (type === 'startTime') {
      this.form('startTime').setValue(localData.hour + ':' + localData.minute);
    } else if (type === 'endTime') {
      this.form('endTime').setValue(localData.hour + ':' + localData.minute);
      if (dateEndTimeNow) {
        this.dateEndTime = dateEndTimeNow;
      } else {
        this.dateEndTime = this.form('deliveryDateDay').value + '-' + this.form('deliveryDateMonth').value + '-' + this.form('deliveryDateYear').value;
      }
    }
    if (!check && !this.id) {
      this.updateEndTime = this.form('deliveryDateDay').value + '-' + this.form('deliveryDateMonth').value + '-' + this.form('deliveryDateYear').value + ' ' + this.form('endTime').value;
    }
  }

  actionUpdateDate(data): void {
    this.updateDate(data);
    this.formReceipt.markAsDirty();
  }

  updateDate(data): void {
    if (!this.id) {
      let month = '';
      let day = '';

      if (data.month < 10) {
        month = '0' + data.month;
      }
      if (data.day < 10) {
        day = '0' + data.day;
      }
      this.formReceipt.get('deliveryDateYear').setValue(data.year);
      this.formReceipt.get('deliveryDateMonth').setValue(month ? month : data.month);
      this.formReceipt.get('deliveryDateDay').setValue(day ? day : data.day);
      this.formReceipt.get('deliveryDate')
        .setValue(this.form('deliveryDateYear').value + '-'
          + this.form('deliveryDateMonth').value + '-' + this.form('deliveryDateDay').value);
      this.getDayOfWeek(this.formReceipt.get('deliveryDate').value);
    } else {
      let month = '';
      let day = '';

      if (data.month < 10) {
        month = '0' + data.month;
      }
      if (data.day < 10) {
        day = '0' + data.day;
      }
      this.formReceipt.get('deliveryDateYear').setValue(data.year);
      this.formReceipt.get('deliveryDateMonth').setValue(month ? month : data.month);
      this.formReceipt.get('deliveryDateDay').setValue(day ? day : data.day);
      this.formReceipt.get('deliveryDate').setValue(this.form('deliveryDateYear').value + '-' + this.form('deliveryDateMonth').value
        + '-' + this.form('deliveryDateDay').value
      );
      this.getDayOfWeek(this.formReceipt.get('deliveryDate').value);
    }
  }

  checkAllStatus(status): void {
    // tslint:disable-next-line:forin
    for (const index in this.statusBtn) {
      this.statusBtn[index] = status;
    }
  }

  checkStatusBtnCreate(): void {
    if (this.authService.isProvider || this.authService.isDelivery) {
      this.statusBtn.editBtn = true;
      this.statusBtn.saveDraftBtn = true;
      this.statusBtn.discardChangeBtn = true;
    }
  }

  checkStatusBtn(data?): void {
    const receiptStatus = this.dataDetail.deliveryStatus;
    const declaredAt = this.formReceipt.get('declaredAt').value !== null;
    const declaredByUserId = this.formReceipt.get('declaredByUserId').value !== null;
    const acceptedAt = this.formReceipt.get('acceptedAt').value !== null;
    const acceptedByUserId = this.formReceipt.get('acceptedByUserId').value !== null;
    const confirmationWebAt = data?.confirmationWebAt;
    const confirmationWebByUserId = data?.confirmationWebByUserId;

    this.statusBtn = {
      editBtn: false,
      saveDraftBtn: false,
      discardChangeBtn: false,
      acceptBtn: false,
      approveBtn: false,
    };

    if (this.authService.isProvider) {
      this.statusBtn.editBtn = true;
      this.statusBtn.saveDraftBtn = true;
      this.statusBtn.discardChangeBtn = true;
    }

    // #1: Status: Temporary
    if (receiptStatus === this.statusReceipt.TEMPORARY) {
      if (this.authService.isDelivery) {
        this.statusBtn.editBtn = true;
        this.statusBtn.saveDraftBtn = true;
        this.statusBtn.discardChangeBtn = true;
      }
    }

    if (receiptStatus === this.statusReceipt.REGISTERED && !acceptedByUserId && !acceptedAt
      && !confirmationWebAt && !confirmationWebByUserId && !declaredAt && !declaredByUserId) {
      if (this.authService.isDelivery) {
        this.statusBtn.editBtn = true;
        this.statusBtn.acceptBtn = true;
        this.statusBtn.discardChangeBtn = true;
      } else if (this.authService.isProvider) {
        this.statusBtn.editBtn = true;
        this.statusBtn.discardChangeBtn = true;
        this.statusBtn.acceptBtn = true;
        this.statusBtn.approveBtn = true;
      }
    } else {
      if (receiptStatus === this.statusReceipt.REGISTERED && !acceptedByUserId && !acceptedAt) {
        if (this.authService.isProvider) {
          this.statusBtn.approveBtn = true;
        }
      }

      if (receiptStatus === this.statusReceipt.REGISTERED &&
        !declaredAt && !declaredByUserId) {
        if (this.authService.isProvider || this.authService.isDelivery) {
          this.statusBtn.acceptBtn = true;
        }
      }
    }
  }

  openDialogNote(): void {
    if (!this.formReceipt.get('note').value || this.formReceipt.get('note').value === ' ') {
      const dialogRef = this.dialog.open(NoteReceiptComponent, {
        disableClose: true,
        data: { providerCompanyId: this.currenUserLogin.providerCompanyId}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result?.data === '空白') {
          this.formReceipt.get('note').setValue(' ');
          this.textLenght = 118;
        } else if (result?.data === '開始時間       \n終了時間       \n--') {
          this.formReceipt.get('note').setValue(result?.data);
          this.textLenght = 115;
        } else {
          this.formReceipt.get('note').setValue(result?.data);
          this.textLenght = 120;
        }
      });
    }

  }
  checkStatusForm(receiptStatus): void {
    const declaredAt = this.formReceipt.get('declaredAt').value !== null;
    const declaredByUserId = this.formReceipt.get('declaredByUserId').value !== null;
    const acceptedAt = this.formReceipt.get('acceptedAt').value !== null;
    const acceptedByUserId = this.formReceipt.get('acceptedByUserId').value !== null;
    const isAcceptanceRequired = this.formReceipt.get('isAcceptanceRequired').value;

    this.formDisable = true;

    if (receiptStatus === this.statusReceipt.TEMPORARY) {
      if ((this.authService.isProvider && this.checkCanEdit && this.checkEditOtherProvider) || this.authService.isDelivery) {
        this.formDisable = false;
      }
    } else if (isAcceptanceRequired && receiptStatus === this.statusReceipt.REGISTERED
      && !declaredAt && !declaredByUserId && !acceptedAt && !acceptedByUserId) {
      if ((this.authService.isProvider && this.checkCanEdit && this.checkEditOtherProvider) || this.authService.isDelivery) {
        this.formDisable = false;
      }
    } else if (!isAcceptanceRequired && receiptStatus === this.statusReceipt.REGISTERED
      && !declaredAt && !declaredByUserId && acceptedAt && acceptedByUserId) {
      if ((this.authService.isProvider && this.checkCanEdit && this.checkEditOtherProvider) || this.authService.isDelivery) {
        this.formDisable = false;
      }
    } else {
      if (this.authService.isProvider && this.checkCanEdit && this.checkEditOtherProvider) {
        this.formDisable = false;
      }
    }
  }

  doConfirmDelivery(): void {
    this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'MESS.MS0416', 'BTN.OK', 'BTN.CANCEL', () => {
      const body = { deliveries: [this.id], receiptNumber: [this.receiptNumber] };
      this.receiptService.confirmDelivery(body).subscribe(() => {
      }, (error: { success: boolean, message: string }) => {
        this.loadingService.hideAppLoading();
        this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
      }, () => {
        this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.CD0055', 'BTN.OK', () => {
          this.modeEdit = true;
          this.getMinMaxReceipt();
          this.getDataReceipt(this.id);
        });
      });
    });
  }

  doAcceptDelivery(): void {
    this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'MESS.MS0410', 'BTN.OK', 'BTN.CANCEL', () => {
      const body = { deliveries: [this.id], receiptNumber: [this.receiptNumber] };
      this.receiptService.acceptDelivery(body).subscribe(() => {
      }, (error: { success: boolean, message: string }) => {
        this.loadingService.hideAppLoading();
        this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
      }, () => {
        this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.CD0059', 'BTN.OK', () => {
          this.modeEdit = true;
          this.getMinMaxReceipt();
          this.getDataReceipt(this.id);
        });
      });
    });
  }

  openModalExport(content): void {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      keyboard: false,
      backdrop: 'static'
    });

  }

  print(): void {
    this.dataForPrint = this.formReceipt.value;
    if (this.formReceipt.value.deliveryDate) {
      this.formReceipt.value.deliveryDate = this.formReceipt.value.deliveryDateYear + '年' +
        this.formReceipt.value.deliveryDateMonth + '月' + this.formReceipt.value.deliveryDateDay + '日    ' + '(' + this.dayOfWeek + ')';
    }
    if (this.dataForPrint.customerSignature) {
      this.dataForPrint.customerSignature = this.labelCompanyName + this.dataForPrint.customerSignature;
    }
    if (this.checkBoxOption1) {
      this.titlePrint = '受領書';
    } else {
      this.titlePrint = '';
    }
    if (this.checkBoxOption2) {
      this.titlePrint1 = '納品書 ';
    } else {
      this.titlePrint1 = '';
    }
    this.titleHeader = {
      title1: this.titlePrint,
      title2: this.titlePrint1,
    };

    this.dataForPrint.isConfirmationWebCustomer = this.dataDetail?.isConfirmationWebCustomer;
    this.dataForPrint.isConfirmationWebSale = this.dataDetail?.isConfirmationWebSale;
    this.dataForPrint.confirmationWebAt = this.dataDetail?.confirmationWebAt;
    this.dataForPrint.confirmationWebByUserId = this.dataDetail?.confirmationWebByUserId;
    this.isPrintList = true;
    this.modalService.dismissAll();
  }

  printDoneList(): void {
    this.isPrintList = false;
  }

  validateTemperature(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        if (parseInt(control.value, 10) > 50 || parseInt(control.value, 10) < -20) {
          return { rangeTemperature: true };
        }
      } else {
        return null;
      }
    };
  }

  validatePressure(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        if (parseInt(control.value, 10) > 1.99 || control.value < 0.01.toFixed(2)) {
          return { rangeTemperature: true };
        }
      } else {
        return null;
      }
    };
  }

  validateTankPercentage(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        if (parseInt(control.value, 10) > 100 || parseInt(control.value, 10) < 1) {
          return { rangePressure: true };
        }
      } else {

        return null;
      }
    };
  }

  getQuantityMeasuringMethodLabel(val: string): string {
    if (!val) {
      return '';
    }
    this.dataTitle = '';
    if (val === 'A') {
      this.dataTitle = 'ローリーメーター';
    } else if (val === 'B') {
      this.dataTitle = '台貫';
    } else if (val === 'C') {
      this.dataTitle = 'その他';
    } else if (val === 'D') {
      this.dataTitle = '検針';
    }
    return this.dataTitle;
  }

  clearImg(event): void {
    event.stopPropagation();
    this.resetFile();
    this.imageURL = '';
    this.formReceipt.get('imagePath').setValue('');
  }
  get validateTemperatureForm(): boolean {
    const temperature = this.form('temperature').value;
    if (temperature === '-' || temperature === '+') {
      return true;
    }
  }
  get validateUpdateTimeDiff(): boolean {
    if (this.id) {
      const deliveryDate = moment(this.formReceipt.value.deliveryDate).format('YYYY-MM-DD');
      const updateStartTime = this.updateStartTime?.slice(0, 10);
      const startTime = updateStartTime?.slice(6, 10) + '-' + updateStartTime?.slice(3, 5) + '-' + updateStartTime?.slice(0, 2);
      if (startTime > deliveryDate) {
        return false;
      }
      return startTime > deliveryDate;
    }

  }
  get validateTimeDiff(): boolean {
    const date = {
      day: moment().date(),
      month: moment().month() + 1,
      year: moment().year()
    };
    if (this.id) {
      const startTime = this.form('startTime').value;
      const endTime = this.form('endTime').value;
      const updateStartTime = this.updateStartTime?.slice(6, 10) + '-' + this.updateStartTime?.slice(3, 5) + '-' + this.updateStartTime?.slice(0, 2) + ' ' + startTime;
      const updateEndTime = this.updateEndTime?.slice(6, 10) + '-' + this.updateEndTime?.slice(3, 5) + '-' + this.updateEndTime?.slice(0, 2) + ' ' + endTime;
      const dateEndTime = this.updateEndTime?.slice(6, 10) + '-' + this.updateEndTime?.slice(3, 5) + '-' + this.updateEndTime?.slice(0, 2);
      const dateStartTime = this.updateStartTime?.slice(6, 10) + '-' + this.updateStartTime?.slice(3, 5) + '-' + this.updateStartTime?.slice(0, 2);
      const endTimeFirst = Number(endTime.slice(0, 2)) + 24;
      const endTimeLast = endTime.slice(3, 5);
      const startTimeVali: any = startTime.replace(':', '');
      const endTimeVali: any = endTimeFirst + '' + endTimeLast;
      if ((endTimeVali - startTimeVali) <= 200) {
        return false;
      }
      if (dateEndTime != dateStartTime && startTime < endTime) {
        return false;
      }
      if (dateEndTime != dateStartTime && (endTimeVali - startTimeVali) > 200 && startTime > endTime) {
        return true;
      }
      if (this.dataDetail?.startTime >= this.dateEndTime || updateStartTime >= updateEndTime) {
        const startTime = this.form('startTime').value;
        const endTime = this.form('endTime').value;
        if (updateStartTime <= updateEndTime) {
          return false;
        }
        if (!startTime || !endTime) {
          return false;
        }
        const end = moment(this.formReceipt.value.deliveryDate).format('DD-MM-YYYY') + ' ' + this.form('endTime').value;
        return this.dataDetail.startTime > end || updateStartTime >= updateEndTime;

      }
    } else {
      const startTime = this.form('startTime').value;
      const endTime = this.form('endTime').value;
      const endTimeFirst = Number(endTime.slice(0, 2)) + 24;
      const endTimeLast = endTime.slice(3, 5);
      const startTimeVali: any = startTime.replace(':', '');
      const endTimeVali: any = endTimeFirst + '' + endTimeLast;
      if ((endTimeVali - startTimeVali) <= 200) {
        return false;
      }
      if (!startTime || !endTime) {
        return false;
      }
      const start = moment(date.day + '/' + date.month + '/' + date.year + ' ' + startTime, 'DD/MM/YYYY HH:mm');
      const end = moment(date.day + '/' + date.month + '/' + date.year + ' ' + endTime, 'DD/MM/YYYY HH:mm');
      return start.diff(end) > 0;
    }


  }

  get validateTankDiff(): boolean {
    const tankPercentageBefore = this.form('tankPercentageBefore').value;
    const tankPercentageAfter = this.form('tankPercentageAfter').value;
    if (!tankPercentageBefore || !tankPercentageAfter) {
      return false;
    }
    return parseInt(tankPercentageBefore, 10) > parseInt(tankPercentageAfter, 10);
  }

  get validateAmountNumber(): boolean {
    const amount = this.form('amount').value?.replaceAll(',', '');
    if (!amount) {
      return false;
    }
    if (amount === '0') {
      return true;
    }
    return amount?.length > 5;
  }
  get validateAmount(): boolean {
    if (this.counterType === 1) {
      const amount = this.form('amount').value;
      if (amount === '' || amount === null) {
        return true;
      } else {
        return false;
      }

    }
  }

  get validateCounterFlowAmount(): boolean {
    if (this.counterType === 0) {
      const counterFlowAmount = this.form('counterFlowAmount').value;
      if (!counterFlowAmount) {
        return true;
      } else {
        return false;
      }
    }

  }
  get validateConversionRate(): boolean {
    if (this.counterType === 0) {
      const conversionRate = this.form('conversionRate').value;
      if (!conversionRate) {
        return true;
      } else {
        return false;
      }
    }
  }
  get validateCounterFlowAmountNumber(): boolean {
    if (this.counterType === 0) {
      const counterFlowAmount = this.form('counterFlowAmount').value?.replaceAll(',', '');
      if (counterFlowAmount === '0') {
        return true;
      }
      return counterFlowAmount?.length > 5;
    }
  }
  get validatePressureDiff(): boolean {
    const pressureBefore = this.form('pressureBefore').value;
    const pressureAfter = this.form('pressureAfter').value;
    if (!pressureBefore || !pressureAfter) {
      return false;
    }
    return parseFloat(pressureBefore) > parseFloat(pressureAfter);
  }
  showTimePicker(): void {
    setTimeout(() => {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
        '.ngb-tp-input'
      );
      if (firstInvalidControl) {
        firstInvalidControl.focus();
      }
    });
  }

  ngAfterViewInit(): void {
    // tslint:disable-next-line:no-unused-expression
    (!this.id) && this.onListenFormChange();

    const elStartTime = this.renderer.selectRootElement('#startTime');
    this.renderer.listen(elStartTime, 'focus', () => {
      const value = this.formReceipt.get('startTime').value.split(':').join('');
      this.formReceipt.get('startTime').setValue(value);
    });
    // this.renderer.listen(elStartTime, 'blur', () => {
    //   this.onBlurTime('startTime');
    // });

    const elEndTime = this.renderer.selectRootElement('#endTime');
    this.renderer.listen(elEndTime, 'focus', () => {
      const value = this.formReceipt.get('endTime').value.split(':').join('');
      this.formReceipt.get('endTime').setValue(value);
    });
    // this.renderer.listen(elEndTime, 'blur', () => {
    //   this.onBlurTime('endTime');
    // });
  }

  onListenFormChange(): void {
    this.formReceipt.valueChanges.subscribe(() => {
      if (this.formReceipt.dirty) {
        this.globalData.setStatusDataEdit(true);
      }
    });
  }

  onKeypress($event: KeyboardEvent, formControl): void {
  }

  onBlurTime(formControl): void {
    let value = this.formReceipt.get(formControl).value.replaceAll(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0))
      .replaceAll(/\u3000/g, '\u0020');
    // let value = this.formReceipt.get(formControl).value;
    // value = value.split(':').join('');
    const regex = new RegExp(/^[0-9]*$/);
    if (regex.test(value)) {
      const length = value.length;
      if (length === 3) {
        if (value.includes(':')){
          this.formReceipt.get(formControl).setValue('');
          return;
        }
        const h = value.substring(0, 1);
        const m = value.substring(1, 3);
        if (m > 59){
          this.formReceipt.get(formControl).setValue('');
        } else {
          const time = '0' + h + ':' + m;
          this.formReceipt.get(formControl).setValue(time);
        }
      } else if (length === 1) {
        if (value.includes(':')){
          this.formReceipt.get(formControl).setValue('');
          return;
        }
        this.formReceipt.get(formControl).setValue('00:0' + value);
      } else if (length === 2) {
        if (value.includes(':')){
          this.formReceipt.get(formControl).setValue('');
          return;
        }
        if (value > 59) {
          this.formReceipt.get(formControl).setValue('');
        } else {
          this.formReceipt.get(formControl).setValue('00:' + value);
        }
      } else if (length === 4) {
        if (value.includes(':')){
          this.formReceipt.get(formControl).setValue('');
          return;
        }
        const h = value.substring(0, 2);
        const m = value.substring(2, 4);
        if (h > 23 || m > 59){
          this.formReceipt.get(formControl).setValue('');
        } else {
          this.formReceipt.get(formControl).setValue(h + ':' + m);
        }
      }
    } else {
      this.formReceipt.get(formControl).setValue('');
    }

    this.validStartTime = this.checkValidStartTime();
  }

  onConfirmWeb(): void {
    this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'WEB_CONFIRM.MS0001', 'BTN.OK', 'BTN.CANCEL', () => {
      const body = {deliveries : [this.id]};
      this.receiptService.deliveryHistoryConfirmWeb(body).subscribe(() => {
      }, (error: { success: boolean, message: string }) => {
        this.loadingService.hideAppLoading();
        this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
      }, () => {
        this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'WEB_CONFIRM.MS0002', 'BTN.OK', () => {
          this.modeEdit = true;
          this.getMinMaxReceipt();
          this.getDataReceipt(this.id);
        });
      });
    });
  }

  onFocus(control): void {
    const value = this.formReceipt.get(control).value.split(':').join('');
    this.formReceipt.get(control).setValue(value);
  }

  isCheckConfirmWeb(): boolean {
    return !(this.authService.isCustomer && !this.authService.isApprovalDelivery);
  }

  onFocusCounterFlowAmount(): void {
    if (this.formReceipt.controls.counterFlowAmount.value) {
      this.formReceipt.controls.counterFlowAmount.setValue(this.formReceipt.controls.counterFlowAmount.value.replaceAll(',', ''));
    }
  }

  onFocusAmount(): void {
    if (this.formReceipt.controls.amount.value) {
      this.formReceipt.controls.amount.setValue(this.formReceipt.controls.amount.value.replaceAll(',', ''));
    }
  }

  checkValidStartTime(): boolean {
    const startTime = this.formReceipt.controls.startTime.value.split(':').join('');
    const endTime = this.formReceipt.controls.endTime.value.split(':').join('');
    return startTime <= endTime;
  }
}
