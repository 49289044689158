<div class="dropdown">
  <input type="text" class="form-select dropdown-toggle text-overflow-ellipsis" readonly
         [disabled]="isDisable"
         [value]="itemSelect.value ? itemSelect?.name: nameShow"
         [ngClass]="{'border border-danger': hasError}"
         (focus)="toggleDrop(true)" [title]="itemSelect.value ? itemSelect?.name: nameShow">
  <div class="dropdown-menu" [class]="{'show': showDrop.value}">
    <input type="text" class="form-control" [formControl]="searchKey" #searchInput (focusout)="checkValidateForm()"
           [placeholder]="placeholder | translate" (keydown)="actionKeyDown($event)"
           [tabIndex]="showDrop.value ? 1: null"
           [tabindex]="showDrop.value ? 1: null"
    >
    <hr class="dropdown-divider">
    <div class="opt-list-scroll" #optListScroll>
      <div class="text-center" *ngIf="!optionList.length && !searchInProgress">{{'error.WR0002' | translate}}</div>
      <div *ngFor="let opt of optionList; let i = index">
        <input class="dropdown-item text-overflow-ellipsis cursor-default" (keydown)="actionKeyDown($event)"
               (click)="chooseOpt(opt)" (keyup.enter)="chooseOpt(opt)" [title]="opt[option.keyName]"
           [value]="opt[option.keyName]"
               [tabIndex]="optionList.length ? i+2: null"
               [tabindex]="optionList.length ? i+2: null" readonly>
      </div>
    </div>
  </div>
</div>
