import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../share/utils/custom-validators';
import {LoadingService} from '../../../share/service/loading.service';
import {ConfirmationModalService} from '../../../share/service/modal/confirmation-modal.service';
import {AuthService} from '../../../services/api-service/auth.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {PASSWORD_VALIDATOR} from '../../../configs/const';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('submitBTN') submitBTN: ElementRef;
  @Input() showEditPass = false;
  @Input() userData: any;
  @Output() close = new EventEmitter();
  error = '';
  errOldPass = '';
  errNewPass = '';
  errNewPasswordConfirmation = '';
  regexPass = PASSWORD_VALIDATOR;
  hidePassword = true;
  hideNewPassword = true;
  userFormGroup = new FormGroup({
    current_password: new FormControl(null, CustomValidators.required()),
    new_password: new FormControl(null, [CustomValidators.required(),
      CustomValidators.regexValidator(this.regexPass, {errFormat: true})]),
    new_confirm_password: new FormControl(null, CustomValidators.required()),
  }, {validators: [this.checkPasswords, this.checkNewPassword]});

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private el: ElementRef,
    private confirmModal: ConfirmationModalService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  focusErrorField(): void {
    setTimeout(() => {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
        '.border-danger'
      );
      if (firstInvalidControl) {
        firstInvalidControl.focus();
      }
    });
  }

  onSubmit(): void {
    this.errOldPass = this.translate.instant('CHANGE_PASSWORD.OLD_PASSWORD');
    this.errNewPass = this.translate.instant('CHANGE_PASSWORD.NEW_PASSWORD');
    this.errNewPasswordConfirmation = this.translate.instant('CHANGE_PASSWORD.NEW_PASSWORD_CONFIRMATION');
    if (this.userFormGroup.invalid) {
      this.focusErrorField();
      return;
    }

    this.showEditPass = false;
    this.confirmModal.confirm('COMMON_DIALOG.TT0001',
      'MESS.CD0001', 'BTN.OK', 'BTN.CANCEL', () => {
        this.loadingService.showAppLoading();
        const body = {
          userId: this.userData.loginId,
          password: this.userFormGroup.get('current_password').value,
          newPassword: this.userFormGroup.get('new_password').value,
          newPasswordConfirm: this.userFormGroup.get('new_confirm_password').value

        };
        this.authService.changePass(body).subscribe(() => {
          this.loadingService.hideAppLoading();
        }, (error: { success: boolean, message: string }) => {
          this.showEditPass = false;
          this.loadingService.hideAppLoading();
          this.confirmModal.confirm('COMMON_DIALOG.TT0003', 'MESS.' + error.message, 'BTN.OK', '', ()=>{
            this.showEditPass = true;
          });
        }, () => {
          this.showEditPass = false;
          this.confirmModal.confirm('COMMON_DIALOG.TT0002', 'MESS.CD0094', 'BTN.OK', '', ()=>{
            this.router.navigateByUrl('/top', {replaceUrl: true}).then();
          });
          this.userFormGroup.markAsPristine();
        });
      }, () => {
        this.showEditPass = true;
      });
  }

  formControl(control: string): AbstractControl {
    return this.userFormGroup.get(control);
  }

  checkPasswords(group: FormGroup): any {
    const password = group.get('new_password').value;
    const confirmPassword = group.get('new_confirm_password').value;

    return password === confirmPassword ? null : {matchPass: true};
  }

  checkNewPassword(group: FormGroup): any {

    const oldPassword = group.get('current_password').value;
    const newPassword = group.get('new_password').value;

    return oldPassword === newPassword ? {matchNewPass: true} : null;
  }
}
