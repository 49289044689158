import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {ComponentRef, Injectable, Injector} from '@angular/core';
import {CF_BTN_TYPE, ConfirmationModalOverlayRef} from '../../classes/confirmation-modal.ref';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationComponent} from '../../components/confirmation/confirmation.component';
import {CONFIRMATION_MODAL_DATA} from '../../tokens/confirmation-data.token';

export interface ConfirmationModalData {
  title?: string;
  content?: string;
  icon?: string;
  okText?: string;
  cancelText?: string;
  confirmText?: string;
}

interface ConfirmationModalConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: ConfirmationModalData;
}

const DEFAULT_CONFIG: ConfirmationModalConfig = {
  hasBackdrop: true,
  backdropClass: 'modal-backdrop',
  panelClass: 'modal'
};


@Injectable({
  providedIn: 'root'
})
export class ConfirmationModalService {
  public dialogRef: ConfirmationModalOverlayRef;
  isShow = false;

  constructor(
    private translate: TranslateService,
    private injector: Injector,
    private overlay: Overlay
  ) {
  }

  open(config: ConfirmationModalConfig = {}): any {
    // Returns an OverlayRef (which is a PortalHost)
    const modalConfig = {...DEFAULT_CONFIG, ...config};
    const overlayRef = this.createOverlay(modalConfig);
    const dialogRef = new ConfirmationModalOverlayRef(overlayRef);
    const overlayComponent = this.attachModalContainer(overlayRef, modalConfig, dialogRef);

    this.dialogRef = dialogRef;

    return dialogRef;
  }

  private attachModalContainer(overlayRef: OverlayRef, config: ConfirmationModalConfig, dialogRef: ConfirmationModalOverlayRef): any {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(ConfirmationComponent, null, injector);
    const containerRef: ComponentRef<ConfirmationComponent> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private createInjector(config: ConfirmationModalConfig, dialogRef: ConfirmationModalOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(ConfirmationModalOverlayRef, dialogRef);
    injectionTokens.set(CONFIRMATION_MODAL_DATA, config.data);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: ConfirmationModalConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global();

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
  }

  private createOverlay(config: ConfirmationModalConfig): OverlayRef {
    // Returns an OverlayConfig
    const overlayConfig = this.getOverlayConfig(config);

    // Returns an OverlayRef
    return this.overlay.create(overlayConfig);
  }

  confirm(title = '', content = '', confirmText = 'ok', cancelText = 'cancel', onConfirm?, onCancel?, params?): any {
    if (this.isShow) {
      return;
    }
    this.isShow = true;

    const translateText = (key) => {
      if (key) {
        let translate = '';
        if (params?.optionsTranslate){
          translate = this.translate.instant(key, params?.optionsTranslate);
        }else{
          translate = this.translate.instant(key);
        }
        return translate !== key ? translate : key;
      }
      return key;
    };

    this.open({
      data: {
        title: translateText(title),
        content: translateText(content),
        confirmText: translateText(confirmText),
        cancelText: translateText(cancelText),
      }
    }).onEvent().subscribe(events => {
      this.isShow = false;
      if (events.type === CF_BTN_TYPE.CONFIRM && onConfirm) {
        onConfirm();
      }
      if (events.type === CF_BTN_TYPE.CLOSE && onCancel) {
        if (onCancel) {
          onCancel();
        }
      }
    });
  }

  notify(title = '', content = '', confirmText = 'OK', onConfirm?, params?): any {
    this.confirm(title, content, confirmText, '', onConfirm, null, params);
  }
}
