import {Directive, Input, ElementRef, HostListener, OnInit, OnDestroy} from '@angular/core';
import {TabService} from '../service/tab-index.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[tabIndex]'
})
export class TabIndexDirective implements OnInit {
  private _index: number;

  // tslint:disable-next-line:typedef
  get index() {
    return this._index;
  }

  @Input('tabIndex')
  set index(i: any) {
    this._index = parseInt(i, 10);
  }

  @HostListener('keydown', ['$event'])
  onInput(e: any): void {
    if (e.which === 38) {
      if (this.index > 1) {
        e.preventDefault();
        this.tabService.selectedInput.next(this.index - 1);
      }
    } else if (e.which === 40) {
      if (this.index === -1) {
        this.tabService.selectedInput.next(this.index + 1);
      }
      e.preventDefault();
      this.tabService.selectedInput.next(this.index + 1);
    }
  }

  @HostListener('click', ['$event'])
  onClick(e): void {
    this.tabService.selectedInput.next(e.srcElement.tabIndex);
  }


  constructor(private el: ElementRef, private tabService: TabService) {
  }

  ngOnInit(): void {
    this.tabService.selectedInput.subscribe((i) => {
      if (i === this.index) {
        this.el.nativeElement.focus();
      }
    });
  }
}
