<form class="sidenav" [formGroup]="form" (ngSubmit)="search()">
  <h5 class="sidebar-heading d-flex justify-content-between align-items-center px-2 mb-1 mt-3">
    <span class="font-weight-bold" for="specificSizeInputName"></span>
    <button class="btn text-center btn-search" id="specificSizeInputName" type="submit">{{'BTN_SEARCH'| translate}}
      &nbsp;&nbsp;&nbsp;<span><i class="fas fa-search"
      ></i></span></button>
  </h5>
  <h5 class="sidebar-heading d-flex justify-content-between align-items-center px-2 mb-1 pt-1">
    <label class="text-underlined" for="companyName">{{'B9.SEARCH_DELIVERY.DELIVERY_COMPANY' | translate}}</label>
  </h5>
  <ul class="nav flex-column mb-4">
    <li class="nav-item px-2 ms-2">
      <input type="text" class="form-control input-size" id="companyName" formControlName="companyName" autocomplete="off">
    </li>
  </ul>
  <ul class="nav flex-column mb-4" *ngIf="globalDataService.checkViewOtherProvider()">
    <h5 class="sidebar-heading d-flex justify-content-between align-items-center px-2 mb-1">
      <label class="text-underlined">{{'B9.SEARCH_DELIVERY.COMPANY_UNDER' | translate}}</label>
    </h5>
    <ul class="nav flex-column mb-4">
      <li class="nav-item hover-search cursor-pointer" *ngFor="let item of checkBoxFormArray.controls; let i = index">
        <div class="px-3 py-2">
          <label formArrayName="checkBox" class="cursor-pointer">
            <input type="checkbox" [id]="i" [formControlName]="i" class="form-check-input">
            <label class="form-check-label px-1 cursor-pointer" [for] ="i">
              <h5> {{listProviderBranches[i].providerCompanyName}}</h5>
            </label>
          </label>
        </div>
      </li>
    </ul>
  </ul>
</form>
