<div class="header">
    <h2 class="title">
      仕入先メモ（記入）
    </h2>
    <form class="change-title">
        <div class="change-title-item  align-items-start border-0 p-0">
            <span class="title-description">
                内容
            </span>
        </div>
        <div class="change-title-item flex-column align-items-start">
          <input  type="text"
                  [ngClass]="{'hasError': errorForm && validatevendorMaster}"
                  maxlength="50"
                  [(ngModel)]="vendorMaster" name="vendorMaster" class="select-note">
          <span *ngIf="errorForm && validatevendorMaster" style="color: red">仕入先メモ（記入）を入力してください。</span>
        </div>
        <div class="title-submit ">
            <button class="btn btn-blue mr" (click)="submit()">確認</button>
            <button class="btn btn-blue" (click)="closeDialog()">キャンセル</button>
        </div>
    </form>
</div>
