import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { BaseApi } from '../../../services/api-service/base.api';
import { ActivatedRoute } from '@angular/router';
import {OFFSET_PAGE, PAGE_NUMBER} from '../../../configs/const';


@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  constructor(
    private el: ElementRef,
    private baseApi: BaseApi,
    private route: ActivatedRoute,
  ) {

  }
  @Input() id: string;
  @Input() ngClass: string;
  @Input() deliveriesList: boolean;
  @Input() placeholder = '';
  @Input() maxLength = 20;
  @Input() index = null;
  @Input() hasError: any;
  @Input() disable: boolean;
  @Input() isNumber = false;
  @Input() isSidebar = false;
  @Input() background: any;
  // tslint:disable-next-line:variable-name
  @Input() background_disable: any;
  @Input() options: {
    apiUrl?: string,
    displayKey?: { param1?: string, param2?: string },
    searchKey?: string,
    isShipmentLocationList?: string,
    getData?: string,
    paramPlus?: any
  };
  @Output() chooseVal = new EventEmitter<any>();
  @Input() autoFocus = false;
  @Input() formReceipt = false;
  @Input() textError = '';
  @Input() checkDeliveryFinish = false;
  @Input() isFilter = false;
  private sub;
  option = {
    apiUrl: '',
    displayKey: { param1: '', param2: '' },
    searchKey: 'searchKey',
  };
  showDrop = new BehaviorSubject(false);
  searchKey = new FormControl('');
  optionList: Array<any> = [];
  viewMessage = false;
  oldValueSearch = '';
  idParam;
  check;
  isChoose = false;
  isAlwaysCallback = true;
  scrollDistance = 2;
  pageNumber = 0;
  offset = 20;
  totalRecords = 0;
  scrollThreshold = 380;
  currentScroll = 0;
  private dataLength: number;
  @ViewChild('optListScroll') optListScroll: ElementRef;
  @ViewChild('search') searchElement: ElementRef;
  @ViewChild('tableScroll') tableScroll: ElementRef;
  @Input() styleInput: any;
  private onChangeModel = (v: any) => {
  }
  protected onTouchModel = (v: any) => {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.check = this.isFilter ? false : localStorage.getItem('isDeliveryParent');
    if (changes.options && changes.options.currentValue) {
      const newOpt = changes.options.currentValue;
      Object.keys(newOpt).forEach((key) => {
        if (this.option.hasOwnProperty(key)) {
          this.option[key] = this.options[key];
        }
      });
    }
  }

  ngOnInit(): void {
    this.idParam = this.route.snapshot.paramMap.get('id');
    if (this.autoFocus) {
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
        this.toggleDrop(true);
      }, 0);
    }
    this.showDrop.subscribe(flag => {
      if (!flag) {
        if (this.sub) {
          this.optionList = [];
          this.sub.unsubscribe();
        }
      } else {
        if (this.searchElement) {
          this.searchElement.nativeElement.focus();
        }
        if (this.searchKey.value && this.searchKey?.value.trim() !== '') {
          this.callData().subscribe();
        }
      }
    });
    this.searchKey.valueChanges.subscribe(val => {
      this.viewMessage = false;
      if (val === '') {
        this.oldValueSearch = '';
      }
      this.isChoose = false;
      this.onChangeModel(val);
    });
    let time = 0;
    if (!this.isSidebar){
      time = 500;
    }
    this.searchKey.valueChanges.pipe(
      debounceTime(time),
      // distinctUntilChanged(),
      switchMap(() => {
        this.chooseVal.emit(false);
        if (this.searchKey?.value && this.searchKey?.value.trim()) {
          // setTimeout(() => {
            return this.callData();
          // }, 500);
        } else {
          this.optionList = [];
        }

        return new Observable<any>(subscriber => {
          subscriber.complete();
        });
      })).subscribe(() => {
        this.oldValueSearch = Object.assign(this.searchKey.value, {});
        const hasFocusSearch = this.searchElement.nativeElement === document.activeElement;
        if (this.showDrop.value === false && hasFocusSearch) {
          this.showDrop.next(true);
        }
      });
  }



  onScroll(event: any): void {
    if (this.dataLength === this.totalRecords) {
      return;
    }else {
      this.currentScroll = event.target.scrollTop;
      if (this.currentScroll > this.scrollThreshold) {
        this.onScrollDown();
        this.currentScroll = 0;
      }
    }

  }

  onScrollDown(): void {
    if (this.dataLength === this.totalRecords) {
      return;
    } else {
      this.scrollThreshold = this.scrollThreshold + 380;
      this.pageNumber = this.pageNumber + 1;
      const param = this.options.paramPlus;
      if (this.options.isShipmentLocationList){
        const paramSearch = {
          keyword: this.searchKey?.value.trim(),
          productName: '',
          providerCompanyIds: [],
        };
        const body = {
          filters: paramSearch,
          page: this.pageNumber,
          size: this.offset,
          sort: {
            sortDirection: 1,
            sortKey: ''
          },
          ...param
        };
        this.baseApi.post(this.option.apiUrl, body)
          .subscribe((res: any) => {
            const option = res[this.options.getData];
            option.map(item => {
              this.optionList.push(item);
            });
            this.dataLength = this.optionList.length;
          });
      }else {
        this.baseApi.post(this.option.apiUrl, {
          [this.option.searchKey]: this.searchKey?.value.trim(),
          size: this.offset,
          page: this.pageNumber,
          ...param
        })
          .subscribe((res: any) => {
            const option = res[this.options.getData];
            option.map(item => {
              this.optionList.push(item);
            });
            this.dataLength = this.optionList.length;
          });
      }

    }
  }
  /**
   * Retrieve data from server
   */
  callData(nextPage = false): Observable<any> {
    this.viewMessage = false;
    if (!this.option.apiUrl) {
      return new Observable<any>(subscriber => {
        subscriber.next();
        subscriber.complete();
      });
    }
    const param = this.options.paramPlus;
    this.optionList = [];
    if (!this.searchKey.value) {
      return new Observable<any>(subscriber => {
        subscriber.next();
        subscriber.complete();
      });
    }
    if (!nextPage) {
      this.pageNumber = PAGE_NUMBER;
    }
    if (this.options.isShipmentLocationList){
      const paramSearch = {
          keyword: this.searchKey?.value.trim(),
          productName: '',
          providerCompanyIds: [],
      };
      const body = {
        filters: paramSearch,
        page: this.pageNumber,
        size: this.offset,
        sort: {
          sortDirection: 1,
          sortKey: ''
        },
        ...param
      };
      return this.baseApi.post(this.option.apiUrl, body)
        .pipe(tap((res) => {
          this.optionList = res[this.options.getData];
          this.totalRecords = res.totalRecords;
          this.dataLength = this.optionList.length;
          this.viewMessage = true;
        }));
    }
    return this.baseApi.post(this.option.apiUrl, {
      [this.option.searchKey]: this.searchKey?.value.trim(),
      size: this.offset,
      page: this.pageNumber,
      ...param
    })
      .pipe(tap((res) => {
        this.optionList = res[this.options.getData];
        this.dataLength = this.optionList.length;
        this.totalRecords = res.totalRecords;
        this.viewMessage = true;
      }));
  }

  /**
   * Option changed handling function
   * @param val: any
   */
  chooseOpt(val: any): void {
    if (this.checkDeliveryFinish) {
      const value = val[this.options.displayKey.param1] + ' - ' + val[this.options.displayKey.param2] + (this.checkDeliveryFinish ? ' - 配送終了' : '');
      this.searchKey.setValue(value, { emitEvent: false });
    } else {
      this.searchKey.setValue(val[this.options.displayKey.param1] + ' - ' + val[this.options.displayKey.param2], { emitEvent: false });
    }

    this.onChangeModel(val);
    this.chooseVal.emit(val);
    this.toggleDrop(false);
    this.isChoose = true;
  }

  /**
   * Toggle dropdown shown
   */
  toggleDrop(show?: boolean): void {
    this.scrollThreshold = 380;
    if (show) {
      this.searchElement.nativeElement.select();
      this.showDrop.next(true);
    } else {
      this.showDrop.next(!this.showDrop.value);
    }
    if (this.showDrop.value) {
      this.sub = fromEvent(document.body, 'click').subscribe((ev: MouseEvent) => {
        if (!this.el.nativeElement.contains(ev.target)) {
          this.showDrop.next(false);
          if (!this.isChoose && !this.isFilter && this.searchKey.value) {
            this.searchKey.reset();
            this.chooseVal.emit('');
          }
        }
      });
    }
    setTimeout(() => {
      this.optListScroll?.nativeElement.getElementsByClassName('opt-list-scroll')[0].scroll({ top: 0 });
    }, 100);
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchModel = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchKey.disable();
    } else {
      this.searchKey.enable();
    }
  }

  writeValue(value: string): void {
    if (value) {
      this.isChoose = true;
    }
    this.searchKey.setValue(value, { emitEvent: false });
  }

  focusOut(): void {
    this.searchElement.nativeElement.blur();
  }

  actionKeyDown(event): void {
    this.scrollThreshold = 380;
    if (event.shiftKey && event.keyCode === 9 || event.keyCode === 9) {
      this.toggleDrop(false);
    }
  }
}
