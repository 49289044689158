<ng-container *ngFor="let item of arrayData">
    <div class=" receipt-form page-break" *ngIf="item.title">
        <div class="row no-gutters align-items-center" style="margin-top: 20px">
            <div class="col text-center">
                <h2 style="font-size: 24px; font-family: 900;"> {{item.title}} </h2>
            </div>
        </div>
        <div class=" no-gutters align-items-center">
            <div class="form-item p-0">
                <div class="col-11 text-center">
                    <span style="font-size: 18px;">{{item.customerCompanyName}}</span>
                </div>
                <div class="col-1 text-end">
                    <span class="fontsize"> 殿</span>
                </div>
            </div>
        </div>
        <div class="row no-gutters align-items-center justify-flex-end " style="height: 10px;">
            <div class="col-3 ">
                <div class="form-item  m-0 border-0" style="font-size: 12px;">
                    <span>コード &nbsp;&nbsp; &nbsp;&nbsp;</span>
                    <span>{{item.customerCompanyCode}}</span>
                    <span></span>
                </div>
            </div>
        </div>
        <div class="row no-gutters items-group align-items-center m-0" style="height: 32px;">
            <div class="col-8 p-0 mb-2">
                <div class="input-group align-items-end d-flex border-0 ">
                    <span class="fontsize">納入日 :</span>
                    <span class="fontsize" style="margin-left: 20px;">{{item.deliveryDate}}</span>
                </div>
            </div>
        </div>
        <div class="input-group">
            <span class="input-group-text custom-text p-2" style="height: 40px;">
                <span class="mb-3" style="font-size: 12px; ">品名</span>
            </span>
            <input type="text" class="form-control  custom-input custom-border p-3" aria-label="Username"
                style="font-size: 16px; height: 40px;" [value]="item.productBillingName">
            <span class="input-group-text custom-text p-2" style="height: 40px;">
                <span class="mb-3" style="font-size: 12px; ">数量</span>
            </span>
            <input type="text" class="form-control  custom-input custom-border-2 text-end p-2" aria-label="Username"
                [value]="item.amount" style="max-width: 100px; font-size: 16px; height: 40px;">
            <span class="input-group-text custom-kg fontsize p-2" style="height: 40px;">kg</span>
        </div>
        <div class="row no-gutters items-group  align-items-center m-0" *ngIf="item.counterType === 0">
            <div class="col-7" style="padding-left: 0;">
                <div class="form-item">
                    <span class="span" style="width:110px">
                        <span class="fontsize">カウンター流量</span>
                        <span>:</span>
                    </span>
                    <span class="float-right fontsize">{{item.counterFlowAmount}}&nbsp;&nbsp;L</span>
                    <span class=" none-padding fontsize"></span>
                </div>
            </div>
            <div class="col-5 " style="padding-right: 0; ">
                <div class="form-item">
                    <span class="width-span fontsize">
                        <span class="fontsize">換算率</span>
                        <span>:</span>
                    </span>
                    <div>
                        <span class="float-right fontsize" style="margin-right: 40px;">{{item.conversionRate}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters items-group items-groups   align-items-center m-0">
            <div class="col-7" style="padding-left: 0; ">
                <div class="form-item">
                    <span class="span">
                        <span class="fontsize">乗務員名</span>
                        <span>:</span>
                    </span>
                    <span class="float-right fontsize text-center" style="width: 50%;">{{item.delivererUserName}}</span>
                    <span>&nbsp;</span>
                </div>
            </div>
            <div class="col-5" style=" padding-right: 0; ">
                <div class="form-item">
                    <span class="width-span">
                        <span class="fontsize">車番</span>
                        <span>:</span>
                    </span>
                    <span class="float-right fontsize" style="margin-right: 40px;">{{item.vehicleCode}}</span>
                    <!-- <span>&nbsp;</span> -->
                </div>
            </div>
        </div>
        <div class="row m-0" style="height:20px">
            <div class="col-7"></div>
            <div class="col-5" style="padding-right: 6px;">
                <div class="form-item" *ngIf="item.temperature !== null && item.temperature !== ''">
                    <span class="width-span">
                        <span class="fontsize">温度</span>
                        <span>:</span>
                    </span>
                    <span class="float-right fontsize text-end"> </span>
                    <span class="fontsize"> {{item.temperature}}&nbsp;℃ &nbsp;&nbsp;</span>
                </div>
            </div>
        </div>
        <div style="height: 42px;">
            <div class="row no-gutters items-group align-items-center mt-0" *ngIf="item.saleBillingCompanyName != '―'">
                <div class="col-3 p-0" style="border-bottom: 1px solid;">
                    <label class="col-form-label none-padding  ">
                        <span class="span">
                            <span class="fontsize"> 取扱窓口</span>
                            <span>:</span>
                        </span>
                    </label>
                </div>
                <div class="col-7 p-0">
                    <div class="input-group">
                        <input type="text" class="form-control custom-input-line none-padding fontsize"
                            aria-label="Username" aria-describedby="basic-addon1" [value]="item.saleBillingCompanyName">
                    </div>
                </div>
            </div>
        </div>

        <div class="row no-gutters items-group align-items-center m-0 border-content mb-1">
            <div class="col-6" style="padding-left: 0;">
                <div class="d-flex">
                    <div class="form-left  fontsize pb-1">納入前</div>
                    <div class="form-right">
                        <div class="form-item border-0 m-1">
                            <span class="span">
                                <span class="fontsize">開始時間</span>
                                <span>:</span>
                            </span>
                            <span class="float-right2 fontsize" style="margin-right: 20px;">{{item.startTime }}</span>
                            <span> &nbsp;</span>
                        </div>
                        <div class="form-item border-0 m-1" [style.opacity]="item.tankPercentageBefore === null || item.tankPercentageBefore === '' ? 0 : 1">
                            <span class="span">
                                <span class="fontsize">タンク在庫</span>
                                <span>:</span>
                            </span>
                            <span class="float-right2 fontsize"> {{item.tankPercentageBefore}}</span>
                            <span class="width-span2 fontsize" style="margin-right: 12px;">%</span>
                        </div>
                        <div class="form-item border-0 m-1"  [style.opacity]="item.pressureBefore === null || item.pressureBefore === '' ? 0 : 1">
                            <span class="span">
                                <span class="fontsize">圧力</span>
                                <span>:</span>
                            </span>
                            <span class="float-right2 fontsize">{{item.pressureBefore}}</span>
                            <span class="width-span2 fontsize"> MPa</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6" style="padding-left: 0;">
                <div class="d-flex">
                    <div class="form-left fontsize pb-1">納入後</div>
                    <div class="form-right">
                        <div class="form-item border-0 m-1">
                            <span class="span">
                                <span class="fontsize">終了時間</span>
                                <span>:</span>
                            </span>
                            <span class="float-right2 fontsize" style="margin-right: 20px;"> {{item.endTime }} </span>
                            <span> &nbsp;</span>
                        </div>
                        <div class="form-item border-0 m-1" [style.opacity]="item.tankPercentageAfter === null || item.tankPercentageAfter === '' ? 0 : 1">
                            <span class="span">
                                <span class="fontsize">タンク在庫</span>
                                <span>:</span>
                            </span>
                            <span class="float-right2 fontsize">{{item.tankPercentageAfter}}</span>
                            <span class="width-span2 fontsize" style="margin-right: 12px;">%</span>
                        </div>
                        <div class="form-item border-0 m-1" [style.opacity]="item.pressureAfter === null || item.pressureAfter === '' ? 0 : 1">
                            <span class="span">
                                <span class="fontsize ">圧力</span>
                                <span>:</span>
                            </span>
                            <span class="float-right2 fontsize"> {{item.pressureAfter}}</span>
                            <span class="width-span2 fontsize"> MPa</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row  align-items-stretch  m-0" [ngClass]="{'image2' : item.counterType === 1}">
            <div class="col-7 " style="padding-left: 0;height: 190px; ">
                <span *ngIf="item.imagePath || item?.note" class="fontsize"> {{dataTitle}}</span>
                <span *ngIf="!item.imagePath" class="fontsize"> <span style="opacity: 0;">1</span> </span>
                <div class=" border-dark image" *ngIf="item.imagePath || item?.note">
                    <img *ngIf="item.imagePath" class="image-item border-darks" src="{{urlImgOn}}{{item.imagePath}}">
                </div>
            </div>
            <div class="col-5 " style="padding-right: 0; padding-left: 0; height: 190px;">
                <span *ngIf="item?.note || item.imagePath" class="fontsize">備考</span>
                <span *ngIf="!item?.note" class="fontsize"><span style="opacity: 0;">1</span> </span>
                <div class="custom-textarea">
                    <div class="custom-textarea" *ngIf="item.imagePath ||item?.note">
                        <textarea class="form-control  border-dark image  fontsize" [value]="item?.note"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters items-group align-items-center m-0">
            <div class="form-item m-0 p-0">
                <div><label class="col-form-label p-0 fontsize">
                        <span class="width-span">
                            <span class="fontsize">貴社</span>
                            <span>:</span>
                        </span>
                    </label>
                    <span class="fontsize" style="margin-left: 15px;">{{labelCompanyName}}</span>&nbsp;
                    <span class="fontsize">{{item.customerCompanyName}}</span>
                </div>

            </div>
        </div>

        <div class="row no-gutters items-group align-items-center m-0">
            <div class="col-12">
                <div class="row">
                    <div class="col-2 p-0">
                        <label class="col-form-label fontsize" style="padding-top: 0px !important;">
                            <span class="span">
                                <span class="fontsize">配送会社 :</span>
                            </span>
                        </label>
                    </div>
                    <div class="col-10 px-1">
                        <span class="fontsize cut-text text-start"
                            style="margin-left: 15px;">{{item.deliveryOfficialName}}&nbsp;&nbsp;&nbsp;
                            {{item.deliveryOfficeName }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-gutters items-group align-items-center m-0" style="height: 40px;">
            <div class="col-4">
            </div>
            <div class="col-8 text-end ">
                <div class="form-item border-0 align-items-center justify-content-center">
                    <label class="col-form-label px-2 text-end fontsize " disabled>伝票番号
                        :</label>
                    <span class="fontsize ">{{item.receiptNumber}}</span>
                </div>
            </div>
        </div>
    </div>
</ng-container>
