import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { URL_IMG } from 'src/app/configs/const';
@Component({
  selector: 'app-print-order',
  templateUrl: './print-order.component.html',
  styleUrls: ['./print-order.component.scss']
})
export class PrintOrderComponent implements OnInit {
  @Input() item: any;
  @Input() title: any;
  weekday;
  urlImgOn = URL_IMG;
  receiptConfirmationPrint = '―';
  constructor(
  ) { }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    const day = moment(this.item.deliveryDate).locale('ja');
    this.weekday = day.format('dd');
    if (this.item?.acceptedAt && this.item?.acceptedByUserId) {
      this.receiptConfirmationPrint = '＊';
    }

    if ((this.item?.isConfirmationWebCustomer || this.item?.isConfirmationWebSale) &&
      this.item?.confirmationWebAt && this.item?.confirmationWebByUserId) {
      this.receiptConfirmationPrint = '●';
    }
  }

  showTitle(): string {
    if (this.item.quantityMeasuringMethod === 'A') {
      return 'ローリーメーター';
    } else if (this.item.quantityMeasuringMethod === 'B') {
      return '台貫';
    } else if (this.item.quantityMeasuringMethod === 'C') {
      return 'その他';
    } else if (this.item.quantityMeasuringMethod === 'D') {
      return '検針';
    }
  }

}
