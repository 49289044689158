import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/api-service/auth.service';
import {UserRoles} from '../../../configs/user-config';
import {TranslateService} from '@ngx-translate/core';
import {ChangeFormHelper} from '../../../helpers/authen/change-form.helper';
import {GlobalDataService} from '../../../services/global-data.service';
import {ConfirmationModalService} from '../../service/modal/confirmation-modal.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  borderHeader = '';
  currentUser: any;
  userRole = UserRoles;
  titleUser = '';
  @Input() titleComponent = '';

  constructor(private router: Router,
              private translate: TranslateService,
              private changeFormHelper: ChangeFormHelper,
              public global: GlobalDataService,
              private confirmPopup: ConfirmationModalService,
              private authService: AuthService) {
    this.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    switch (this.currentUser.role) {
      case this.userRole.PROVIDER_COMPANY:
        this.titleUser = this.translate.instant('HEADER.TITLE.PROVIDER');
        break;
      case this.userRole.SALE_COMPANY:
        this.titleUser = this.translate.instant('HEADER.TITLE.SALE');
        break;
      case this.userRole.DELIVERY_COMPANY:
        this.titleUser = this.translate.instant('HEADER.TITLE.DELIVERY');
        break;
      case this.userRole.CUSTOMER:
        this.titleUser = this.translate.instant('HEADER.TITLE.CUSTOMER');
        break;

      default:
        this.titleUser = this.translate.instant('HEADER.TITLE.PROVIDER');
    }
  }

  logout(): void {
    localStorage.removeItem('viewInvoice');
    this.global.statusFormDetail = false;
    this.global.clickSearch = false;
    const hasEdit = this.global.getStatusDataEdit();
    const hasEditTab = this.global.getStatusDataEditTab();
    if (hasEdit || hasEditTab) {
      this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'MESS.CD0005', 'BTN.OK', 'BTN.CANCEL', () => {
        this.global.setStatusDataEdit(false);
        this.global.setStatusDataEditTab(false);
        this.global.clickFlag = false;
        this.global.tabChange = false;
        this.authService.clearToken();
        this.router.navigateByUrl('/login', {replaceUrl: true}).then();
      });
    } else {
      this.authService.clearToken();
      this.router.navigateByUrl('/login', {replaceUrl: true}).then();
    }
  }

  redirectTop(): void {
    localStorage.removeItem('viewInvoice');
    this.global.statusFormDetail = false;
    this.global.clickSearch = false;
    this.global.clickFlag = false;
    this.global.tabChange = false;
    this.router.navigateByUrl('/top', {replaceUrl: true}).then();
  }
}
