import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/api-service/auth.service';
import { ConfirmationModalService } from '../../share/service/modal/confirmation-modal.service';
import { NetworkService } from "../../services/network.service";
import { UserSession } from "../../models/user-session.model";

@Injectable({ providedIn: 'root' })
export class AuthGuardHelper implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private confirmPopup: ConfirmationModalService,
    private network: NetworkService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.network.online) {
      if (this.authService.currentUserValue) {
        return true;
      } else if (!this.authService.token) {
        return this.authService.redirectLogin();
      } else {
        return this.checkLogin();
      }
    } else {
      this.network.isNetworkStopped = true;
      return false;
    }
  }

  private checkLogin(): Observable<boolean> {
    let isChanged = true;
    return new Observable(subscriber => {
      this.authService.getUserInfo().subscribe((userSession) => {
        isChanged = userSession.isChanged;
        if (isChanged) {
          subscriber.next(true);
        } else {
          this.authService.clearToken();
          this.router.navigateByUrl('/login', { replaceUrl: true }).then();
        }
      }, (error: { success: boolean, message: string }) => {
        this.authService.clearToken();
        if(error.message === 'CD0054'){
          this.confirmPopup.notify('COMMON_DIALOG.TT0002', "MESS.CD0054", 'BTN.OK', () => {
            return this.authService.redirectLogin();
          });
        }else{
          this.confirmPopup.notify('COMMON_DIALOG.TT0003', "MESS.ER0134", 'BTN.OK', () => {
            return this.authService.redirectLogin();
          });
        }

        subscriber.next(false);
      }, () => {
        subscriber.complete();
      });
    });
  }
}
