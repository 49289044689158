import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/api-service/auth.service';
import { environment } from '../../../environments/environment';
import { ResponseApiInterface } from '../../interfaces/response-api.interface';

@Injectable()
export class JwtInterceptorHelper implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.currentUserValue && this.authService.currentUserValue.token !== this.authService.token) {
      location.reload();
      return EMPTY;
    }
    // add auth action-crud with jwt if user is logged in and request is to the api url
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const isApiPublic = request.url.indexOf('/public/') >= 0;
    const isApiLogin = request.url.indexOf('/login') >= 0;

    if (isApiUrl && !isApiPublic && !isApiLogin) {
      if (this.authService.token) {
        request = request.clone({
          setHeaders: {
            Authorization: this.authService.token
          }
        });
      } else {
        this.authService.redirectLogin();
        return EMPTY;
      }
    }

    return next.handle(request).pipe(map(event => {
      if (event instanceof HttpResponse) {
        const body: ResponseApiInterface = event.body;
        return event.clone({ body });
      }
    }));
  }
}
