import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NumberProcessor} from '../../utils/number-processor';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent implements OnInit, ControlValueAccessor {
  @Input() moneyUnit = '';
  @Input() fractionPart = 3;
  @Input() placeholder = '';
  @Input() defaultValue = '0';
  @Input() isInteger = false;

  displayVal = new FormControl();
  private onChangeModel = (v: any) => {};
  protected onTouchModel = (v: any) => {};

  constructor() { }

  ngOnInit(): void {
    this.displayVal.valueChanges.subscribe(val => {
      this.onChangeModel(val ? val.replace(/\,/g, '') : '');
    });
  }

  blur(e): void {
    this.displayVal.setValue(this._convertNumberToCurrency(e.target.value));
  }

  private _convertNumberToCurrency(input: any): string {
    const c = input ? input.toString().replace(/\,/g, '') : '';
    return NumberProcessor.currencyLocaleString(c, 0, this.fractionPart);
  }

  writeValue(value: number | string): void {
    this.displayVal.setValue(this._convertNumberToCurrency(value));
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchModel = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.displayVal.disable();
    } else {
      this.displayVal.enable();
    }
  }
}
