import { ChangeDetectorRef, Component, OnInit, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { CustomValidators } from '../../../share/utils/custom-validators';
import { AuthService } from '../../../services/api-service/auth.service';
import { UserSessionInterface } from '../../../interfaces/user-session.interface';
import { LoadingService } from '../../../share/service/loading.service';
import { ConfirmationModalService } from '../../../share/service/modal/confirmation-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalDataService } from '../../../services/global-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    username: new FormControl(null, [CustomValidators.required()]),
    password: new FormControl(null, [CustomValidators.required()])
  });
  labelId = '';
  labelPassword = '';
  showEditPass = false;
  isLoading = false;
  firstLogin = false;
  userData: Array<UserSessionInterface> = [];

  constructor(
    private router: Router,
    private loading: LoadingService,
    private confirmPopup: ConfirmationModalService,
    private authService: AuthService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private globalData: GlobalDataService
  ) {
  }

  ngOnInit(): void {
  }

  login(): void {
    this.globalData.checkNetwork();
    this.labelId = this.translateService.instant('SCREEN.LOGIN.NAME');
    this.labelPassword = this.translateService.instant('SCREEN.LOGIN.PASSWORD');
    if (this.loginForm.invalid) {
      return;
    }
    this.loading.showAppLoading();
    this.isLoading = true;
    const params = this.loginForm.value;
    this.authService.login(params).subscribe(() => {
      this.authService.getUserInfo().subscribe((userData) => {
        if (userData.isChanged) {
          this.router.navigateByUrl('/top', { replaceUrl: true }).then();
        } else {
          this.userData = userData;
          this.showHideModal();
        }
      });
      this.isLoading = false;
      this.loading.hideAppLoading();
    }, (error: { success: boolean, message: string }) => {
      this.loading.hideAppLoading();
      this.isLoading = false;
      if (error.message) {
        this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.' + error.message);
      } else {
        this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.ER0134');
      }
    }
    );
  }

  // convenience getter for easy access to form fields
  form(control: string): AbstractControl {
    return this.loginForm.get(control);
  }

  showHideModal(isShow = true): void {
    this.showEditPass = isShow;
  }
}
