<div class="header">
    <h2 class="title">
        印刷
    </h2>
    <form class="change-title" [formGroup]="form">
        <div class="change-title-item  align-items-start border-0 p-0">
            <span class="title-description">
                印刷する帳票を選択してください。
            </span>
        </div>
        <div class="change-title-item flex-column align-items-start">
            <div class="mb">
                <input class="form-check-input cursor-pointer " id="受領書" type="radio" name="titleOne" checked  value="受領書" formControlName="titleOne">
               <label for="受領書">受領書</label>
           </div>
           <div class="mb">
               <input class="form-check-input cursor-pointer " id="納品書" type="radio" name="titleOne" value="納品書" formControlName="titleOne">
                <label for="受領書">納品書</label>
           </div>

        </div>
        <div class="title-submit ">
            <button class="btn btn-blue mr" (click)="submit()">印刷</button>
            <button class="btn btn-blue" (click)="closeDialog()">キャンセル</button>
        </div>
    </form>
</div>
