import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthService} from './services/api-service/auth.service';
import {UserRoles} from './configs/user-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  userRoles = UserRoles;
  constructor(
    translate: TranslateService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,

  ) {
    translate.addLangs(['en', 'ja']);
    translate.setDefaultLang('ja');
    translate.use('ja');

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {

        const route = this.getChild(this.activatedRoute);
        route.data.subscribe(data => {
          const pathRoot = data.pathRoot;
          const historyFilter = JSON.parse(localStorage.getItem('history-filter'));
          if (pathRoot && historyFilter && pathRoot !== historyFilter.route) {
            localStorage.removeItem('history-filter');
          }

          const historyFilterSale = JSON.parse(localStorage.getItem('history-filter-sale'));
          if (pathRoot && historyFilterSale && pathRoot !== historyFilterSale.route) {
            localStorage.removeItem('history-filter-sale');
          }
          const historyFilterCustomers = JSON.parse(localStorage.getItem('history-filter-customers'));
          if (pathRoot && historyFilterCustomers && pathRoot !== historyFilterCustomers.route) {
            localStorage.removeItem('history-filter-customers');
          }
          const historyFilterDelivery = JSON.parse(localStorage.getItem('history-filter-delivery'));
          if (pathRoot && historyFilterDelivery && pathRoot !== historyFilterDelivery.route) {
            localStorage.removeItem('history-filter-delivery');
          }
          const historyFilterVendors = JSON.parse(localStorage.getItem('history-filter-vendors'));
          if (pathRoot && historyFilterVendors && pathRoot !== historyFilterVendors.route) {
            localStorage.removeItem('history-filter-vendors');
          }
          const historyFilterShipment = JSON.parse(localStorage.getItem('history-filter-shipment'));
          if (pathRoot && historyFilterShipment && pathRoot !== historyFilterShipment.route) {
            localStorage.removeItem('history-filter-shipment');
          }
          if (pathRoot === '/list/deliveries-history' && (this.authService.currentUserValue.role === this.userRoles.CUSTOMER || this.authService.currentUserValue.role === this.userRoles.SALE_COMPANY)){
            this.titleService.setTitle(data?.titleSale ?? data?.title);
          }else {
            this.titleService.setTitle(data.title);
          }
        });

      });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }
}
