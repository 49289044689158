const ACTIONS_CODE = {
  DELIVERIES_HISTORY_MANAGER: 'deliveries_history_manager',
  DELIVERIES_HISTORY_NEW: 'deliveries_history_new',
  DELIVERIES_HISTORY_EDIT: 'deliveries_history_edit',
  DELIVERIES_HISTORY_DELETE: 'deliveries_history_delete',
  DELIVERIES_HISTORY_APPROVE: 'deliveries_history_approve',
  DELIVERIES_HISTORY_CONFIRM: 'deliveries_history_confirm',
  DELIVERIES_HISTORY_DETAIL_PRINT: 'deliveries_history_detail_print',
  DELIVERIES_GASFILLING_CONFIRM: 'deliveries_gasfilling_confirm',
  PROVIDER_GASFILLING_HISTORY: 'provider_gasfilling_history',
  GASFILLING_DETAIL_HISTORY: 'gasfilling_detail_history',


  SALE_MANAGER: 'sale_manager',
  SALE_DETAIL_VIEW: 'sale_detail_view',

  CUSTOMER_MANAGER: 'customer_manager',
  CUSTOMER_LIST: 'customer_list',
  CUSTOMER_NEW: 'customer_new',
  CUSTOMER_EDIT: 'customer_edit',
  CUSTOMER_DELETE: 'customer_delete',
  CUSTOMER_PRINT: 'customer_print',
  CUSTOMER_EXPORT: 'customer_export',
  CUSTOMER_IMPORT: 'customer_import',
  CUSTOMER_DETAIL: 'customer_detail',
  CUSTOMER_DETAIL_USER_CREATE: 'customer_detail_user_create',
  CUSTOMER_DETAIL_USER_EDIT: 'customer_detail_user_edit',
  CUSTOMER_DETAIL_USER_DELETE: 'customer_detail_user_delete',
  CUSTOMER_DETAIL_USER_EXPORT: 'customer_detail_user_export',
  CUSTOMER_DETAIL_USER_IMPORT: 'customer_detail_user_import',

  DELIVERY_LIST: 'delivery_list',
  DELIVERY_DETAIL: 'delivery_detail',
  DELIVERY_DETAIL_USER: 'delivery_detail_user',
  DELIVERY_DETAIL_USER_NEW: 'delivery_detail_user_new',
  DELIVERY_DETAIL_USER_EDIT: 'delivery_detail_user_edit',
  DELIVERY_DETAIL_USER_DELETE: 'delivery_detail_user_delete',
  DELIVERY_DETAIL_VERHICE: 'delivery_detail_verhice',
  DELIVERY_DETAIL_VERHICE_NEW: 'delivery_detail_verhice_new',
  DELIVERY_DETAIL_VERHICE_EDIT: 'delivery_detail_verhice_edit',
  DELIVERY_DETAIL_VERHICE_DELETE: 'delivery_detail_verhice_delete',

  PROVIDER_USER_LIST: 'provider_user_list',
  EDIT_VENDOR: 'edit_vendor',
  EDIT_SHIPMENT_LOCATION: 'edit_shipment_location',
  CONFIRM_GASFILLING: 'confirm_gasfilling',
  EDIT_GASFILLING_HISTORY: 'edit_gasfilling_history',
};

const ACTIONS = {
  DEFAULT_ALLOW: {
    ROLE_PROVIDER: ['B2_EXPORT'],
    ROLE_SALE: ['B2_HISTORY', 'B2_SALE', 'B2_CUSTOMER',
        'deliveries_history_manager', 'deliveries_history_detail_print',
        'sale_detail_view',
        'customer_list', 'customer_print', 'customer_export', 'customer_detail',

        ],
    ROLE_DELIVERY: ['B2_HISTORY', 'B2_CUSTOMER', 'B2_DELIVERY',
      'deliveries_history_manager', 'deliveries_history_new', 'deliveries_history_edit', 'deliveries_history_delete',
      'deliveries_history_detail_print',
      'customer_list', 'customer_print', 'customer_export', 'customer_detail', 'delivery_detail',
      'delivery_detail_user', 'delivery_detail_verhice'
    ],
    ROLE_CUSTOMER: ['B2_HISTORY', 'B2_CUSTOMER',
      'deliveries_history_manager', 'deliveries_history_detail_print', 'customer_detail'
    ],
  },
  EDIT_PROVIDER_OTHER: 'EDIT_PROVIDER_OTHER',
  VIEW_PROVIDER_OTHER: 'VIEW_PROVIDER_OTHER',
  VIEW_PROVIDER_USER: 'VIEW_PROVIDER_USER',
  EDIT_PROVIDER_USER_OTHER: 'EDIT_PROVIDER_USER_OTHER',
  EDIT_PROVIDER_USER_NEW: 'EDIT_PROVIDER_USER_NEW',
  EDIT_CUSTOMER_USER: 'EDIT_CUSTOMER_USER',
  EDIT_CUSTOMER: 'EDIT_CUSTOMER',
  EDIT_SALE: 'EDIT_SALE',

  // B3, B4 edit, view detail, print, export
  deliveries_history_manager: ['EDIT_DELIVERY_HISTORY_DETAIL', 'CONFIRM_DELIVERY', 'APPROVE_DELIVERY'],
  deliveries_history_new: ['EDIT_DELIVERY_HISTORY_DETAIL'], // B4 new
  deliveries_history_edit: ['EDIT_DELIVERY_HISTORY_DETAIL'], // B4 edit
  deliveries_history_delete: ['EDIT_DELIVERY_HISTORY_DETAIL'],
  deliveries_history_confirm: ['CONFIRM_DELIVERY'],
  deliveries_history_approve: ['APPROVE_DELIVERY'],
  deliveries_history_detail_print: ['EDIT_DELIVERY_HISTORY_DETAIL', 'CONFIRM_DELIVERY'],
  deliveries_gasfilling_confirm: ['CONFIRM_GASFILLING'],
  provider_gasfilling_history: ['EDIT_GASFILLING_HISTORY'],
  gasfilling_detail_history: ['CONFIRM_GASFILLING', 'EDIT_GASFILLING_HISTORY'],
  // B5, B6
  sale_manager: ['EDIT_SALE'],

  // B7, B8
  customer_manager: ['EDIT_CUSTOMER'],
  customer_list: ['EDIT_CUSTOMER'],
  customer_new: ['EDIT_CUSTOMER'],
  customer_edit: ['EDIT_CUSTOMER'],
  customer_delete: ['EDIT_CUSTOMER'],
  customer_print: ['EDIT_CUSTOMER'],
  customer_export: ['EDIT_CUSTOMER'],
  customer_import: ['EDIT_CUSTOMER'],
  customer_detail: ['EDIT_CUSTOMER'],
  customer_detail_user_create: ['EDIT_CUSTOMER', 'EDIT_CUSTOMER_USER'],
  customer_detail_user_edit: ['EDIT_CUSTOMER', 'EDIT_CUSTOMER_USER'],
  customer_detail_user_delete: ['EDIT_CUSTOMER', 'EDIT_CUSTOMER_USER'],
  customer_detail_user_export: ['EDIT_CUSTOMER'],
  customer_detail_user_import: ['EDIT_CUSTOMER'],

  // B9, B10
  delivery_list: ['EDIT_DELIVERY'],
  delivery_detail: ['EDIT_DELIVERY'],
  delivery_detail_user: ['EDIT_DELIVERY'],
  delivery_detail_user_new: ['EDIT_DELIVERY', 'EDIT_DELIVERY_USER'],
  delivery_detail_user_edit: ['EDIT_DELIVERY', 'EDIT_DELIVERY_USER'],
  delivery_detail_user_delete: ['EDIT_DELIVERY', 'EDIT_DELIVERY_USER'],
  delivery_detail_verhice: ['EDIT_DELIVERY'],
  delivery_detail_verhice_new: ['EDIT_DELIVERY', 'EDIT_DELIVERY_USER'],
  delivery_detail_verhice_edit: ['EDIT_DELIVERY', 'EDIT_DELIVERY_USER'],
  delivery_detail_verhice_delete: ['EDIT_DELIVERY', 'EDIT_DELIVERY_USER'],

  // B11
  provider_user_list: ['EDIT_PROVIDER_USER', 'EDIT_PROVIDER_USER_OTHER', 'EDIT_PROVIDER_USER_NEW'],

// -----------------------------
// B2
  B2_HISTORY: ['EDIT_DELIVERY_HISTORY_DETAIL', 'CONFIRM_DELIVERY', 'APPROVE_DELIVERY', 'VIEW_PROVIDER_OTHER', 'EDIT_PROVIDER_OTHER', 'EDIT_SALE_USER', 'EDIT_DELIVERY_USER', 'CONFIRM_DELIVERY', 'EDIT_CUSTOMER_USER', 'APPROVE_DELIVERY'],
  B2_USER_PROVIDER: ['EDIT_PROVIDER_USER', 'VIEW_PROVIDER_OTHER', 'EDIT_PROVIDER_OTHER', 'EDIT_PROVIDER_USER_OTHER'],
  B2_SALE: ['EDIT_SALE', 'VIEW_PROVIDER_OTHER', 'EDIT_PROVIDER_OTHER'],
  B2_CUSTOMER: ['EDIT_CUSTOMER', 'VIEW_PROVIDER_OTHER', 'EDIT_PROVIDER_OTHER'],
  B2_DELIVERY: ['EDIT_DELIVERY', 'VIEW_PROVIDER_OTHER', 'EDIT_PROVIDER_OTHER'],
  B2_IMPORT: [],
  B2_EXPORT: ['EDIT_DELIVERY_HISTORY_DETAIL', 'CONFIRM_DELIVERY', 'APPROVE_DELIVERY', 'EDIT_CUSTOMER', 'EDIT_SALE', 'EDIT_DELIVERY', 'APPROVE_DELIVERY', 'EDIT_PROVIDER_USER', 'VIEW_PROVIDER_OTHER', 'EDIT_PROVIDER_OTHER', 'EDIT_PROVIDER_USER_OTHER'],

  // B13,B14
  edit_vendor: ['EDIT_VENDOR'],

  // B15
  edit_shipment_location: ['EDIT_SHIPMENT_LOCATION'],
  //
  confirm_gasfilling: ['CONFIRM_GASFILLING'],
};

export const PERMISSION_CONFIG = { ACTIONS_CODE, ACTIONS };
