import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {NoteProviderApiService} from '../../../../services/api-service/note-provider.api.service';
import {ProviderNoteModel} from '../../../../models/provider-note-model';
// @ts-ignore
@Component({
  selector: 'app-note-receipt',
  templateUrl: './note-receipt.component.html',
  styleUrls: ['./note-receipt.component.scss']
})
export class NoteReceiptComponent implements OnInit {
  model = '空白';
  offPrintDetail = false;
  isPrintDetail: any;
  option = "[1]納入前      \n    納入後      \n[2]納入前      \n    納入後      \n--";
  option2 = "[1]納入前      \n    納入後      \n[2]納入前      \n    納入後      \n[3]納入前      \n    納入後      \n--";
  option3 = "納入前(cm)      \n納入後(cm)      \n--";
  option4 = "開始時間       \n終了時間       \n--";
  option6 = "[No.4]      \n[No.5]      \n[No.6]      \n[No.7]      \n[No.8]      \n[No.9]      \n--"
  option5 = "[No.1]      \n[No.2]      \n[No.3]      \n--"
  listNoteProvider: Array<ProviderNoteModel> = [];

  constructor(
    private noteProviderApiService: NoteProviderApiService,
    public dialogRef: MatDialogRef<NoteReceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getListNoteProvinder();
  }
  getListNoteProvinder(): void{
    this.noteProviderApiService.getListNoteProviderById(this.data.providerCompanyId).subscribe((data) => {
      this.listNoteProvider = data ? data.providerCompanyInNoteLists : [];
    });
  }

  printDoneList(): void {
    this.offPrintDetail = false;
  }
  submit(): void {
    this.dialogRef.close({ data: this.model });
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

}
