import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseApi} from './base.api';

@Injectable({
  providedIn: 'root'
})
export class SystemApi extends BaseApi {
  prefixURL = '';
  
  exportLog(data):Observable<any>{
    return this.download('export/log',data);
  }
  valiExportLog(data):Observable<any>{
    return this.download('validate/export/log',data);
  }
  public uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.post('import/company/provider', formData);
  }
}
