import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PERMISSION_CONFIG } from 'src/app/configs/permision-config';
import { AuthService } from './api-service/auth.service';
import { USER_ROLES_PROVIDER_SCREEN, UserRoles } from '../configs/user-config';
import {ActivatedRoute, Router} from '@angular/router';
import { NetworkService } from './network.service';
import { ConfirmationModalService } from '../share/service/modal/confirmation-modal.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  userRoles = UserRoles;
  private hasDataEdit = false;
  private hasDataEditTab = false;
  userRolesProviderScreen = USER_ROLES_PROVIDER_SCREEN;
  public statusFormDetail = false;
  public title = '';
  public clickSearch = false;
  public clickFlag = false;
  public tabChange = false;
  public formDetailChange = false;
  public checkBTN = false;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private network: NetworkService,
              private confirmPopup: ConfirmationModalService,
              private router: Router) {
  }

  getStatusDataEdit(): boolean {
    return this.hasDataEdit;
  }

  setStatusDataEdit(status: boolean): void {
    // console.log(status);
    this.hasDataEdit = status;
  }

  getStatusDataEditTab(): boolean {
    return this.hasDataEditTab;
  }

  setStatusDataEditTab(status: boolean): void {
    // console.log(status);
    this.hasDataEditTab = status;
  }

  checkEditData(data: Array<FormGroup>): boolean {
    this.setStatusDataEdit(false);
    for (const item of data) {
      if (!item.pristine) {
        this.setStatusDataEdit(true);
        return false;
      }
    }
    return true;
  }
  removeListId(): void {
    localStorage.removeItem('listIdReceipt');
  }
  setListIdReceipt(data: Array<any>): void {
    localStorage.setItem('listIdReceipt', JSON.stringify(data));
  }
  setListIdGasFilling(data: Array<any>): void {
    localStorage.setItem('listIdGasFilling', JSON.stringify(data));
  }
  getListIdReceipt(): Array<any> {
    return JSON.parse(localStorage.getItem('listIdReceipt'));
  }

  getListIdGasFilling(): Array<any> {
    return JSON.parse(localStorage.getItem('listIdGasFilling'));
  }

  getIndexGasFillingInList(id?: number): number {
    return this.getListIdGasFilling().findIndex((item) => {
      return item.gasId === id;
    });
  }

  getIndexReceiptInList(id?: number): number {
    return this.getListIdReceipt().findIndex((item) => {
      return item.deliveryId === id;
    });
  }

  typeOfReceipt(id?: number): { type: boolean; isCombinada: number } {
    let data = {
      type : false,
      isCombinada: 0
    };
    for (const item of this.getListIdReceipt()) {
      if (item.deliveryId === id) {
        // data = item.type;
        data = {
          type : item.type,
          isCombinada: item.isCombinada
        };
      }
    }
    return data;
  }

  // checkPermissionScreen(screenCode: string): boolean{
  //   if (PERMISSION.btn[screenCode] === 'undefined') { return false; }
  //   let screenFound = false;
  //   if (this.authService.currentUserValue.role === this.userRoles.PROVIDER_COMPANY) {
  //     this.userRolesProviderScreen.map(screen => {
  //       if (PERMISSION.screen[screenCode].indexOf(screen) >= 0) {
  //         screenFound = true;
  //       }
  //     });
  //   }
  //   console.log('checkScreen: ', screenCode, screenFound);
  //   return screenFound;
  // }

  checkPermissionAction(action: string, redirect: boolean = false): boolean {
    let allowAction = false;
    if (this.authService.isSystemAdmin) {
      // console.log('SystemAdmin alway has all', action);
      allowAction = true;
    }
    if (allowAction === false) {
      const defaultAllow = PERMISSION_CONFIG.ACTIONS.DEFAULT_ALLOW[this.authService.currentUserValue.role];
      if (defaultAllow.indexOf(action) >= 0) {
        allowAction = true;
      }

      if (!allowAction) {
        if (PERMISSION_CONFIG.ACTIONS[action] !== 'undefined') {
          if (this.authService.currentUserValue.privileges.length > 0) {
            // tslint:disable-next-line:forin
            for (const index in this.authService.currentUserValue.privileges) {
              const p = this.authService.currentUserValue.privileges[index];
              if (PERMISSION_CONFIG?.ACTIONS[action]?.indexOf(p) >= 0) {
                allowAction = true;
                break;
              }
            }
          }
        }
      }
    }

    // console.log(`Check action [action] is`, action);

    if (!allowAction && redirect) {
      console.log('goto not found - global dataservice checkPermissionAction', action);
      this.router.navigateByUrl('not-found').then();
    }
    return allowAction;
  }

  checkViewOtherProvider(selfComanyID = null, redirect: boolean = false): boolean {
    if (this.authService.currentUserValue.providerCompanyId === selfComanyID || this.authService.isSystemAdmin) {
      return true;
    }

    if (this.authService.currentUserValue.privileges.indexOf(PERMISSION_CONFIG.ACTIONS.VIEW_PROVIDER_OTHER) >= 0) {
      return true;
    }

    return this.processPermissionFalse(redirect);
  }

  checkEditOtherProvider(selfComanyID = null, redirect: boolean = false): boolean {
    if (this.authService.currentUserValue.providerCompanyId === selfComanyID || this.authService.isSystemAdmin) {
      return true;
    }

    if (this.authService.currentUserValue.privileges.indexOf(PERMISSION_CONFIG.ACTIONS.EDIT_PROVIDER_OTHER) >= 0) {
      return true;
    }

    return this.processPermissionFalse(redirect);
  }

  checkViewTabBTN(): boolean {
    if (this.authService.isProvider &&
      (this.authService.currentUserValue.privileges.indexOf(PERMISSION_CONFIG.ACTIONS.EDIT_PROVIDER_OTHER) >= 0 ||
        this.authService.currentUserValue.privileges.indexOf(PERMISSION_CONFIG.ACTIONS.EDIT_CUSTOMER) >= 0)
    ) {
      return true;
    }
    if (this.authService.isCustomer &&
      this.authService.currentUserValue.privileges.indexOf(PERMISSION_CONFIG.ACTIONS.EDIT_CUSTOMER_USER) >= 0) {
      return true;
    }
    return false;
  }

  processPermissionFalse(redirect = true): boolean {
    if (redirect) {
      console.log('goto not found - global dataservice processPermissionFalse');
      this.router.navigateByUrl('not-found').then();
    }
    return false;
  }

  // 10
  checkSaleHasEditSaleUser(): boolean {
    if (this.authService.currentUserValue.privileges.indexOf('EDIT_SALE_USER') >= 0) {
      return true;
    }
    return false;
  }

  // 12
  checkDeliveryHasEditDeliveryUser(): boolean {
    return true;
  }

  // 2
  checkDeliveryHasConfirmDelivery(): boolean {
    return true;
  }

  // 11
  checkCustomerHasEditCustomerUser(): boolean {
    if (this.authService.isCustomer && this.authService.currentUserValue.privileges.indexOf('EDIT_CUSTOMER_USER') >= 0) {
      return true;
    }
    return false;
  }

  // 3
  checkCustomerHasApproveDelivery(): boolean {
    return true;
  }

  checkNetwork(): void {
    if (!this.network.online) {
      this.confirmPopup.confirm('COMMON_DIALOG.TT0002', 'MESS.CD0053', 'BTN.OK', '');
      return;
    }
  }

  checkHistoryFilter(): void {
    const route = this.router.url;
    const historyFilter = JSON.parse(localStorage.getItem('history-filter'));
    const listRoute = ['/list/deliveries-history', '/list/sale-company', '/list/delivery-company', '/list/customer-company', '/list/vendors', '/list/shipment-location'];
    if (historyFilter && listRoute.includes(route) && historyFilter.route !== route) {
      localStorage.removeItem('history-filter');
    }
  }

  formDate(date): any {
    return date.split('-')[2] + '-' + date.split('-')[1] + '-' + date.split('-')[0]; // d-m-y to y-m-d
  }
}
