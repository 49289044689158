import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-template-print-table',
  templateUrl: './template-print-table.component.html',
  styleUrls: ['./template-print-table.component.scss']
})
export class TemplatePrintTableComponent implements OnInit, OnChanges, AfterViewInit  {
  @Input() header: Array<{ name?: string, column?: string, param?: string }>;
  @Input() contentTable: Array<any>;
  @Input() title = '';
  @Output() printDone = new EventEmitter();
  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
  }

  ngAfterViewInit(): void {
    window.print();
  }

  @HostListener('window:afterprint', ['$event.target'])
  afterprint(btn): void {
    setTimeout(() => {
      this.printDone.emit();
    }, 50);
  }
}
