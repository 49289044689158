import {Directive, EventEmitter, Input, Output} from '@angular/core';

export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = {asc: 'desc', desc: '', '': 'asc'};

export interface SortEvent {
  column: string;
  direction: SortDirection;
  sortQuery: string;
}

@Directive({
  selector: '[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'onClick()'
  }
})
export class SortableDirective {
  @Input() sortable = '';
  @Input() direction: SortDirection = '';
  @Input() activeSort = false;
  @Output() sort = new EventEmitter<SortEvent>();

  constructor() {
  }

  onClick(): void {
    if (!this.activeSort) {
      return;
    }
    this.processSort();
  }

  processSort(): void {
    this.direction = rotate[this.direction];
    let sortQuery = '';
    if (this.direction === 'asc') { sortQuery = `+${this.sortable}`; }
    if (this.direction === 'desc') { sortQuery = `-${this.sortable}`; }
    this.sort.emit({column: this.sortable, direction: this.direction, sortQuery});
  }
}
