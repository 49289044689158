import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {ProviderBranchesModel} from '../../../models/provider-branches-model';
import {DeliveryCompanyService} from '../../../services/api-service/delivery-company-service';
import {AuthService} from '../../../services/api-service/auth.service';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {GlobalDataService} from "../../../services/global-data.service";

@Component({
  selector: 'app-search-delivery',
  templateUrl: './search-delivery.component.html',
  styleUrls: ['./search-delivery.component.scss']
})
export class SearchDeliveryComponent implements OnInit {
  @Output() emitParam = new EventEmitter<object>();
  @Input() inputSearch = {companyName: '', providerCompanyIds: []};
  form: FormGroup;
  listProviderBranches: Array<ProviderBranchesModel> = [];
  providerBranchId = 0;
  showListProviderBranch = false;

  constructor(private deliveryCompanyService: DeliveryCompanyService,
              private authService: AuthService,
              public globalDataService: GlobalDataService,
              private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      checkBox: new FormArray([]),
      companyName: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.providerBranchId = this.authService.currentUserValue.providerBranchId;
    if (this.globalDataService.checkViewOtherProvider()) {
      this.getProviderBranches();
      this.showListProviderBranch = true;
    }
    if (this.globalDataService.statusFormDetail &&  this.globalDataService.clickSearch) {
      this.form.get('companyName').setValue(this.inputSearch.companyName);
    }

    const historyFilter = localStorage.getItem('history-filter-delivery') ? JSON.parse(localStorage.getItem('history-filter-delivery')) : null;
    if (historyFilter) {
      this.inputSearch = historyFilter.filter;
      this.form.get('companyName').setValue(this.inputSearch.companyName);
    }
  }

  get checkBoxFormArray(): FormArray {
    return this.form.controls.checkBox as FormArray;
  }

  addCheckboxes(): void {
    this.listProviderBranches.map((listProviderBranch: any) =>
      this.checkBoxFormArray.push(new FormControl(!!this.inputSearch?.providerCompanyIds.includes(listProviderBranch.providerCompanyId))));
  }

  getProviderBranches(): void {
    this.deliveryCompanyService.getProviderBranches().subscribe((data) => {
      this.listProviderBranches = data.data;
      this.addCheckboxes();
    });
  }


  search(): void {
    const selectedOrderIds = this.form.value.checkBox
      .map((checked, i) => checked ? this.listProviderBranches[i].providerCompanyId : null)
      .filter(v => v !== null);
    const paramOutput = {
      companyName: this.form.controls.companyName.value.trim(),
      providerCompanyIds: selectedOrderIds
    };
    this.emitParam.emit(paramOutput);
  }
}
