<div class="position-relative">
  <input type="text" appNumberOnly [isNumber]="isNumber" autocomplete="off"
    class="form-control dropdown-toggle fs text-overflow-ellipsis"
    [ngClass]="{'background-disable':background_disable,'hasError': hasError, 'border-danger': hasError, 'input-custom-receipt': formReceipt, 'background': background && !idParam , 'background-green': background && idParam &&  (check === 'true' || check === null), 'disable' :  check != 'true' && background && idParam && check !== null} "
    [id]="id" [maxLength]="maxLength" [placeholder]="placeholder | translate" [formControl]="searchKey" #search
    (focus)="toggleDrop(true)" (keyup.enter)="focusOut()" (keydown)="actionKeyDown($event)"
    [attr.disabled]="disable || (check !== 'true' && check) ? '': null " [appTooltip]="hasError ? textError: ''"
    [placement]="'right'" [style]="styleInput">
    <div  [class]="{'show': showDrop.value, 'deliveries': deliveriesList}" class="dropdown-menu" #optListScroll *ngIf="optionList.length > 0">
    <div class="opt-list-scroll" infinite-scroll #tableScroll [alwaysCallback]="isAlwaysCallback"
         [infiniteScrollDistance]="scrollDistance" [scrollWindow]="false" (scroll)="onScroll($event)" >
      <div *ngFor="let opt of optionList; let i = index">
          <a class="dropdown-item text-overflow-ellipsis" *ngIf="!checkDeliveryFinish; else other_content" (keydown.tab)="toggleDrop(false)"
             [title]="opt[option.displayKey?.param1] + ' - ' + opt[option.displayKey?.param2]" (click)="chooseOpt(opt)"
             (keyup.enter)="chooseOpt(opt)">
            {{opt[option.displayKey?.param1] + ' - ' + opt[option.displayKey?.param2]}}
          </a>
        <ng-template #other_content>
          <a class="dropdown-item text-overflow-ellipsis" (keydown.tab)="toggleDrop(false)"
             [title]="opt[option.displayKey?.param1] + ' - ' + opt[option.displayKey?.param2] + (opt.finishDelivery ? ' - 配送終了' : '')" (click)="chooseOpt(opt)"
             (keyup.enter)="chooseOpt(opt)">
            {{opt[option.displayKey?.param1] + ' - ' + opt[option.displayKey?.param2] + (opt.finishDelivery ? ' - 配送終了' : '')}}
          </a>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="dropdown-menu" [class]="{'show': showDrop.value}" #optListScroll
    *ngIf="optionList.length === 0 && searchKey.value && viewMessage">
    <div class="opt-list-scroll">
      <a class="dropdown-item text-overflow-ellipsis" disabled>{{'MESS.CM0003'| translate}}</a>
    </div>
  </div>
</div>
