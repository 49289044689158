import {OverlayRef} from '@angular/cdk/overlay';
import {BehaviorSubject} from 'rxjs';
import {filter, take} from 'rxjs/operators';

export class ConfirmationModalOverlayRef {
  public events = new BehaviorSubject<any>(null);

  constructor(private overlayRef: OverlayRef) {
  }

  public close(): void {
    this.overlayRef.dispose();
  }

  /**
   * @returns boolean - whether cookie with specified name exists
   * @description check for cookie
   */
  public onEvent(): any {
    return this.events.pipe(
      filter((event) => !!event),
      filter((event) => {
        if (Object.values(CF_BTN_TYPE).includes(event.type)) {
          this.close();
        }

        return Object.values(CF_BTN_TYPE).includes(event.type);
      }),
      take(1)
    );
  }
}

export enum CF_BTN_TYPE {
  CONFIRM = 'confirm',
  CLOSE = 'close',
  OK = 'ok'
}
