import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {GlobalDataService} from '../../../services/global-data.service';
import {OFFSET_PAGE} from '../../../configs/const';


@Component({
  selector: 'app-list-layout',
  templateUrl: './list-layout.component.html',
  styleUrls: ['./list-layout.component.scss']
})
export class ListLayoutComponent implements OnInit {
  titleComponent = '';
  constructor(private route: ActivatedRoute,
              public globalData: GlobalDataService
  ) {
  }

  ngOnInit(): void {
    localStorage.removeItem('viewInvoice');
    // this.titleComponent = this.globalData.title;
    // this.titleComponent = this.route.snapshot.data.title ? this.route.snapshot.data.title : this.route.snapshot.firstChild.data.title;
  }
}
