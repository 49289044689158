import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseApi} from './base.api';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseApi {
  public productCompanySearch: any = {sort: {}};
  prefixURL = '';

  public getProduct(body?): Observable<any> {
    return this.post('product/list', body);
  }

  public createProduct(body?): Observable<any> {
    return this.post('product', body);
  }

  public updateProduct(id?: string, body?: object): Observable<any> {
    return this.put('product/' + id, body);
  }

  public deleteProduct(id?: string): Observable<any> {
    return this.patch('product/' + id, '');
  }

  public uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.post('import/product', formData);
  }

  public downLoadFile(): Observable<any> {
    return this.download('export/product');
  }
}
