<nav aria-label="breadcrumb row" style="display: flex;justify-content: space-between;">
  <ol class="breadcrumb col-3">
    <li class="breadcrumb-item" *ngFor="let item of filterData(breadcrumbData)"><a class="text-decoration-underline cursor-pointer"
                                                                                   (click)="routing(item?.url)">{{item?.breadcrumb}}</a></li>
    <li class="breadcrumb-item active"
        aria-current="page">{{breadcrumbData[breadcrumbData.length - 1]?.breadcrumb}}</li>
  </ol>
  <div class="col-9" *ngIf="showTotal" >
    <div class="d-flex row" style="font-size: 18px; font-weight: bold; margin-left: 55px;">
      <div class="col-7 row ps-6"   *ngIf="typeMenu===3; else other">
        <div class="col-4 text-right pe-0 text-end " >選択数量 :</div>
        <div class="col-7 ps-2">
          納入：{{totalKg | number}} kg <br>
          積込：{{totalKg2 | number}} kg
        </div>
      </div>
      <ng-template #other>
        <div class="col-7 row ps-6" style="height: 54px;">
          <div class="col-4 text-right pe-0 text-end ">選択数量 :</div>
          <div class="col-7 ps-2">
            {{totalKg | number}} kg
          </div>
        </div>
      </ng-template>

      <div class="col-5 d-flex p-0 " style="margin-left: 8px;">
        <div class="col-12 text-right pe-0 text-end">{{currentRecords | number}}/<span class="ms-1">{{totalRecords | number}}</span> 件</div>
      </div>
    </div>
  </div>
</nav>
