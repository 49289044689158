<div class="header">
    <h2 class="title">
        備考
    </h2>
    <form class="change-title">
        <div class="change-title-item  align-items-start border-0 p-0">
            <span class="title-description">
                備考の内容を選択してください。
            </span>
        </div>
        <div class="change-title-item flex-column align-items-start">
            <select class="select-note" [(ngModel)]="model" name="model">
                <option value="空白">空白</option>
                <option *ngFor="let item of listNoteProvider" [value]="item?.note">{{item?.title}}</option>
<!--                <option [value]="option">（2基）複数タンク</option>-->
<!--                <option [value]="option2 ">（3基）複数タンク</option>-->
<!--                <option [value]="option3">cm</option>-->
<!--                <option [value]="option4">深夜納入時間表示ズレ</option>-->
<!--                <option [value]="option5">（3基）複数タンク No.のみ</option>-->
<!--                <option [value]="option6">（4基以上）複数タンク No.のみ </option>-->
            </select>
        </div>
        <div class="title-submit ">
            <button class="btn btn-blue mr" (click)="submit()">確認</button>
            <button class="btn btn-blue" (click)="closeDialog()">キャンセル</button>
        </div>
    </form>
</div>
