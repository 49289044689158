import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/api-service/auth.service';
import { UserRoles } from '../../../configs/user-config';
import { BasicFunctionService } from '../../../share/service/basicFunction.service';
import { GlobalDataService } from '../../../services/global-data.service';
import { IMPORT_TYPE_XLS, IMPORT_TYPE_XLSX, MAX_SIZE_IMPORT, OFFSET_PAGE } from '../../../configs/const';
import { DeliveryCompanyService } from "../../../services/api-service/delivery-company-service";
import { LoadingService } from "../../../share/service/loading.service";
import { FormBuilder } from "@angular/forms";
import { ConfirmationModalService } from "../../../share/service/modal/confirmation-modal.service";
import { TranslateService } from "@ngx-translate/core";
import { SystemApi } from "../../../services/api-service/system.api";
import { PERMISSION_CONFIG } from 'src/app/configs/permision-config';
import { saveAs } from 'file-saver';
import { formatDate } from '@angular/common';
import { SearchExportLogComponent } from './search-export-log/search-export-log.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit {
  userRole = UserRoles;
  userLogin: any;
  companyId = '';
  titleComponent = '';
  fileUpload: File = null;
  exportExcel;
  currentDate = formatDate(new Date(), 'yyyyMMdd', 'en');
  actionsCode = PERMISSION_CONFIG.ACTIONS_CODE;
  themeForUser = '';
  dataSearch = {};
  @ViewChild('inputFile') myInputVariable: ElementRef;

  constructor(private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    private basicFunction: BasicFunctionService,
    public globalData: GlobalDataService,
    private deliveryCompanyService: DeliveryCompanyService,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private confirmPopup: ConfirmationModalService,
    private systemApi: SystemApi,
    private translate: TranslateService,
    private loading: LoadingService,
    private dialog: MatDialog) {
    this.userLogin = this.authService.currentUserValue;
    this.companyId = this.userLogin.partnerCompanyId;
  }

  ngOnInit(): void {
    // call api get user info to update client
    this.authService.getUserInfo().subscribe((userSession) => { });

    this.globalData.statusFormDetail = false;
    this.globalData.clickSearch = false;
    this.route.data.subscribe(value => {
      this.titleComponent = value.title;
      this.globalData.title = value.title;
    });
    this.basicFunction.setStatusHadCallApi(false);
    this.globalData.removeListId();
    localStorage.removeItem('typeForm');
    this.globalData.checkHistoryFilter()
  }
  valiExportLog() {
    this.loading.showAppLoading();
    this.systemApi.valiExportLog(this.dataSearch).subscribe((res) => {
    }, (async (error: { success: boolean, message: string }) => {
      const err = await this.parseErrorBlob(error);
      this.loading.hideAppLoading();
      this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'MESS.' + err.message, 'BTN.OK', '', () => {
        if (err.message === 'ER0141') {
          this.exportLog()
        }
      });
    })
      , () => {
        this.loading.hideAppLoading();
        this.exportLog()
      })
  }
  async parseErrorBlob(error): Promise<any> {
    const data = await new Blob([error], { type: 'text/plain' });
    return await JSON.parse(await data.text());
  }
  exportLog() {
    this.loading.showAppLoading();
    this.systemApi.exportLog(this.dataSearch).subscribe((res) => {
      this.exportExcel = res.body;
      if (this.exportExcel === null) {
        this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.ER0209');
        return;
      }
    }, ((error: { success: boolean, message: string }) => {
      this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
      this.loading.hideAppLoading();
    })
      , () => {
        this.loading.hideAppLoading();
        this.downLoadFile();
      })
  }

  downLoadFile(): void {
    let companyTypeText = '操作履歴';
    saveAs(this.exportExcel, `${companyTypeText} _${this.currentDate}.xlsx`);
  }

  openSearchExportLog(): void {
    const dialogRef = this.dialog.open(SearchExportLogComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {

      this.dataSearch = { startDate: moment(result.data.startDate).format('DD-MM-YYYY') + ' 00:00', endDate: moment(result.data.endDate).format('DD-MM-YYYY') + ' 00:00' };
    }, (error) => {
    }, () => {
      this.valiExportLog()
    });
  }
  activeComponent(screen: string): void {

    if (screen === 'deliveries-history') {
      this.router.navigateByUrl('list/deliveries-history').then();
    } else if (screen === 'user-provider') {
      this.router.navigateByUrl('detail/user-provider').then();
    } else if (screen === 'sale-company') {
      this.router.navigateByUrl('list/sale-company').then();
    } else if (screen === 'delivery-company') {
      this.router.navigateByUrl('list/delivery-company').then();
    } else if (screen === 'gas-customers') {
      this.router.navigateByUrl('list/customer-company').then();
    } else if (screen === 'company-details') {
      if (this.userLogin.role === this.userRole.SALE_COMPANY) {
        this.router.navigateByUrl('detail/sale-company/' + this.companyId).then();
      } else if (this.userLogin.role === this.userRole.DELIVERY_COMPANY) {
        this.router.navigateByUrl('detail/delivery-company/' + this.companyId).then();
      } else if (this.userLogin.role === this.userRole.CUSTOMER) {
        this.router.navigateByUrl('detail/customer-company/' + this.companyId).then();
      }
    } else if (screen === 'shipment-location') {
      this.router.navigateByUrl('list/shipment-location').then();
    } else if (screen === 'vendor') {
      this.router.navigateByUrl('list/vendors').then();
    }
  }

  importDataProvider(): void {

  }

  selectFile(): void {
    this.myInputVariable.nativeElement.click();
  }

  resetFile(): void {
    this.myInputVariable.nativeElement.value = '';
  }

  handleFileInput(files?: File): void {
    this.fileUpload = files[0];
    if (!this.fileUpload) {
      return;
    }
    if (this.fileUpload.type !== IMPORT_TYPE_XLSX && this.fileUpload.type !== IMPORT_TYPE_XLS) {
      this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.CD0058');
      return;
    }
    if (this.fileUpload.size > MAX_SIZE_IMPORT) {
      this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.CD0091');
      return;
    }
    this.loadingService.showAppLoading();
    this.systemApi.uploadFile(this.fileUpload).subscribe(() => {
    }, (error: { success: boolean, message: string, errorDataFile }) => {
      this.resetFile();
      this.loadingService.hideAppLoading();
      if (error.message) {
        this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
      } else if (error.errorDataFile) {
        const errStr = this.basicFunction.getErrStr(error.errorDataFile, this.translate);
        this.confirmPopup.notify('COMMON_DIALOG.TT0003', errStr);
      }
    }, () => {
      this.resetFile();
      this.loadingService.hideAppLoading();
      this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.CD0052', 'BTN.OK', () => {

      });
    });
  }
}
