<div class="modal-dialog modal-dialog-centered text-center">
  <div class="modal-content">
    <div class="modal-header" *ngIf="data.title">
      <h5 class="modal-title">{{data.title}}</h5>
      <!--      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"-->
      <!--              (click)="closeModal()"></button>-->
    </div>
    <div class="modal-body">
      <p class="dialog-content" [innerHTML]="data.content"></p>
    </div>
    <div class="modal-footer justify-content-center">
      <button *ngIf="data.okText" #elPopupOk
              class="btn btn-blue margin-right-15" (click)="okModal()">
        {{data.okText}}
      </button>
      <button *ngIf="data.confirmText" #elPopupConfirm
              class="btn btn-blue margin-right-15" (click)="confirmModal()">
        {{data.confirmText}}
      </button>
      <button *ngIf="data.cancelText" #elPopupCancel
              class="btn btn-blue" (click)="closeModal()">
        {{data.cancelText}}
      </button>
    </div>
  </div>
</div>
