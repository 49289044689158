import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// @ts-ignore
@Component({
  selector: 'app-note-receipt',
  templateUrl: './edit-vendor.component.html',
  styleUrls: ['./edit-vendor.component.scss']
})
export class EditVendorComponent implements OnInit {
  vendorMaster = '';
  errorForm = false;
  constructor(
    public dialogRef: MatDialogRef<EditVendorComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.vendorMaster = this.data.vendorMemos.vendorMemo;
  }
  get validatevendorMaster(): boolean {
    if (this.vendorMaster.trim() === '' || this.vendorMaster.trim() === null) {
      return true;
    } else {
      return false;
    }
  }
  submit(): void {
    if (this.vendorMaster.trim() === ''){
      // show error
      this.errorForm = true;
    }else {
      this.dialogRef.close({ data: this.vendorMaster.trim() });
    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

}
