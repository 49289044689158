import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {BaseApi} from './base.api';
import {map, tap} from 'rxjs/operators';
import {UserSessionInterface, UserSubjectInterface} from '../../interfaces/user-session.interface';
import {UserProviderRoles, UserRoles} from '../../configs/user-config';
import {UserSession} from "../../models/user-session.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApi {
  privilegesRole = UserProviderRoles;
  prefixURL = '';
  tokenName = 'token';
  public currentUserSubject = new BehaviorSubject<any>(null);

  /**
   * Get current user's session
   */
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  get isProvider(): boolean {
    return this.currentUserSubject.value.role === UserRoles.PROVIDER_COMPANY;
  }

  get isSale(): boolean {
    return this.currentUserSubject.value.role === UserRoles.SALE_COMPANY;
  }

  get isCustomer(): boolean {
    return this.currentUserSubject.value.role === UserRoles.CUSTOMER;
  }

  get isDelivery(): boolean {
    return this.currentUserSubject.value.role === UserRoles.DELIVERY_COMPANY;
  }

  get isSystemAdmin(): boolean {
    return this.currentUserSubject.value.role === UserRoles.SYSTEM_ADMIN;
  }

  isRoleSystemAdmin(role: string): boolean {
    return role === UserRoles.SYSTEM_ADMIN;
  }

  updateCurrentPrivileges(privileges): void{
    this.currentUserValue.privileges = privileges;
  }

  isRoleType(type?): boolean {
    const privileges = this.currentUserSubject.value.privileges;
    let data = false;
    for (const item of privileges) {
      if (item === type) {
        data = true;
      }
    }
    return data;
  }

  get isEditDeliveryHistoryDetail(): boolean {
    const privileges = this.currentUserSubject.value.privileges;
    let data = false;
    for (const item of privileges) {
      if (item === this.privilegesRole.EDIT_IMPORT_HISTORY_DETAIL) {
        data = true;
      }
    }
    return data;
  }

  get isEditSaleRole(): boolean {
    const privileges = this.currentUserSubject.value.privileges;
    let data = false;
    for (const item of privileges) {
      if (item === this.privilegesRole.EDIT_SALE) {
        data = true;
      }
    }
    return data;
  }

  get isProviderSaleRole(): boolean {
    const privileges = this.currentUserSubject.value.privileges;
    let data = false;
    for (const item of privileges) {
      if (item === this.privilegesRole.EDIT_SALE && item === this.privilegesRole.VIEW_PROVIDER_OTHER ||
        item === this.privilegesRole.EDIT_SALE && item === this.privilegesRole.EDIT_PROVIDER_OTHER) {
        data = true;
      }
    }
    return data;
  }

  get isEditDelivery(): boolean {
    const privileges = this.currentUserSubject.value.privileges;
    let data = false;
    for (const item of privileges) {
      if (item === this.privilegesRole.EDIT_DELIVERY) {
        data = true;
      }
    }
    return data;
  }

  get isEditShipmentLocation(): boolean {
    const privileges = this.currentUserSubject.value.privileges;
    let data = false;
    for (const item of privileges) {
      if (item === this.privilegesRole.EDIT_SHIPMENT_LOCATION) {
        data = true;
      }
    }
    return data;
  }


  get isViewProviderOther(): boolean {
    const privileges = this.currentUserSubject.value.privileges;
    let data = false;
    for (const item of privileges) {
      if (item === this.privilegesRole.VIEW_PROVIDER_OTHER) {
        data = true;
      }
    }
    return data;
  }

  get isEditProviderOther(): boolean {
    const privileges = this.currentUserSubject.value.privileges;
    let data = false;
    for (const item of privileges) {
      if (item === this.privilegesRole.EDIT_PROVIDER_OTHER) {
        data = true;
      }
    }
    return data;
  }

  get isEditDeliveryUser(): boolean {
    const privileges = this.currentUserSubject.value.privileges;
    let data = false;
    for (const item of privileges) {
      if (item === this.privilegesRole.EDIT_DELIVERY_USER) {
        data = true;
      }
    }
    return data;
  }

  get isEditGasFillingHistory(): boolean {
    const privileges = this.currentUserSubject.value.privileges;
    let data = false;
    for (const item of privileges) {
      if (item === this.privilegesRole.EDIT_GASFILLING_HISTORY) {
        data = true;
      }
    }
    return data;
  }

  get isConfirmGasFilling(): boolean {
    const privileges = this.currentUserSubject.value.privileges;
    let data = false;
    for (const item of privileges) {
      if (item === this.privilegesRole.CONFIRM_GASFILLING) {
        data = true;
      }
    }
    return data;
  }

  get isApprovalDelivery(): boolean {
    const privileges = this.currentUserSubject.value.privileges;
    let data = false;
    for (const item of privileges) {
      if (item === this.privilegesRole.APPROVE_DELIVERY) {
        data = true;
      }
    }
    return data;
  }

  /**
   * Get token
   */
  get token(): string | null {
    return localStorage.getItem(this.tokenName);
  }

  /**
   * Clear login data
   */
  clearToken(): void {
    localStorage.removeItem(this.tokenName);
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  getUserInfo(): Observable<any> {
    return this.get('user/self')
      .pipe(tap((user) => {
          user.token = this.token;
          this.currentUserSubject.next(user);
        })
      );
  }

  login(params: { hospital_id?: number, name: string, password: string }): Observable<UserSessionInterface> {
    return this.post('login', params)
      .pipe(map((data: UserSubjectInterface) => {
          localStorage.setItem(this.tokenName, data.accessToken);
          const user = new UserSession(data.user);
          user.token = data.accessToken;
          this.currentUserSubject.next(user);
          return data.user;
        })
      );
  }

  logout(): Observable<any> {
    return this.post('logout', {})
      .pipe(tap(() => {
          this.redirectLogin();
          this.clearToken();
        })
      );
  }

  changePass(body): Observable<any> {
    return this.put('user/updatePassword', body);
  }

  redirectLogin(): boolean {
    this.loading.hideAppLoading();
    this.router.navigateByUrl('/login', {replaceUrl: true}).then();
    return true;
  }
}
