import {
  AfterViewInit,
  Component, EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit, Output
} from '@angular/core';

@Component({
  selector: 'app-delivery-print',
  templateUrl: './delivery-print.component.html',
  styleUrls: ['./delivery-print.component.scss']
})
export class DeliveryPrintComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() header: Array<{ name?: string, column?: string, param?: string }>;
  @Input() contentTable: Array<any>;
  @Input() title;
  @Output() printDone = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => window.print(), 1000);
  }

  @HostListener('window:afterprint', ['$event.target'])
  afterprint(btn): void {
    setTimeout(() => {
      this.printDone.emit();
    }, 50);
  }
}
