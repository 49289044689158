import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApi } from './base.api';

@Injectable({
  providedIn: 'root'
})
export class NoteProviderApiService extends BaseApi {
  prefixURL = '';

  public getProviderById(): Observable<any> {
    return this.get(`company/provider`);
  }
  getListNoteProviderById(id: number): Observable<any> {
    return this.get(`note/provider/list/${id}`);
  }
  public updateNoteProvider(id?: string, body?: object): Observable<any> {
    return this.put('note/provider/' + id, body);
  }
  public createNoteProduct(body?): Observable<any> {
    return this.post('note/provider/', body);
  }
  public deleteProduct(id?: string): Observable<any> {
    return this.delete('note/provider/' + id, '');
  }
  public getProviderScreen(isScreen?): Observable<any> {
    return this.get('company/provider/screen', {isScreen});
  }
}
