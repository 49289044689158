import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {CF_BTN_TYPE, ConfirmationModalOverlayRef} from '../../classes/confirmation-modal.ref';
import {ConfirmationModalData} from '../../service/modal/confirmation-modal.service';
import {CONFIRMATION_MODAL_DATA} from '../../tokens/confirmation-data.token';


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, AfterViewInit {

  @ViewChild('elPopupOk') elPopupOk: ElementRef;
  @ViewChild('elPopupConfirm') elPopupConfirm: ElementRef;
  @ViewChild('elPopupCancel') elPopupCancel: ElementRef;

  constructor(
    public dialogRef: ConfirmationModalOverlayRef,
    @Inject(CONFIRMATION_MODAL_DATA) public data: ConfirmationModalData
  ) {
  }

  ngOnInit(): void {}

  /**
   * Set focus to button
   */
  ngAfterViewInit(): void {
    if (this.data.okText) {
      this.elPopupOk.nativeElement.focus();
    } else if (this.data.confirmText) {
      this.elPopupConfirm.nativeElement.focus();
    } else if (this.data.cancelText) {
      this.elPopupCancel.nativeElement.focus();
    }
  }

  /**
   * Close modal
   */
  public closeModal(): void {
    this.dialogRef.events.next({
      type: CF_BTN_TYPE.CLOSE,
      data: null
    });
  }

  /**
   * Confirm modal
   */
  public confirmModal(): void {
    this.dialogRef.events.next({
      type: CF_BTN_TYPE.CONFIRM,
      data: this.data
    });
  }

  /**
   * OK modal
   */
  public okModal(): void {
    this.dialogRef.events.next({
      type: CF_BTN_TYPE.OK,
      data: this.data
    });
  }
}
