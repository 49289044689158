import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {BaseApi} from './base.api';
import {SaleCompanyModel} from "../../models/sale-company-model";

@Injectable({
    providedIn: 'root'
})
export class CustomerApi extends BaseApi {
    public customerCompanySearch: any = {sort: {}, filters: {}};
    public isEditCustomer = false;
    prefixURL = '';

    public getListCustomer(body?: any): Observable<any> {
        return this.post('company/customer/list', body);
    }

    public deleteCustomerCompany(body?: any): Observable<any> {
        return this.patch('company/deleteMany', body);
    }

    public downLoadFileDeliveries(body): Observable<any> {
        return this.download('export/customerCompanies', body);
    }

    public uploadFile(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.post('import/company/customer', formData);
    }

    public getProviderById(): Observable<any> {
        return this.get(`company/provider`);
    }

    getDetail(id: number): Observable<any> {
        return this.get(`company/customer/${id}`);
    }

    public saveCustomerCompany(id?: string, body?: any): Observable<any> {
        return id ? this.put('company/customer/' + id, body)
            : this.post('company/customer', body);
    }

    public getProductListId(): Observable<any>{
        return this.get(`product/list/customer`);
    }

    public getProviderBranches(): Observable<any> {
        return this.get('company/provider');
    }

}
