<div class="d-flex bg-secondary vh-100 align-items-center justify-content-center">
  <div class="mw-30rem">
    <div class="text-center">
      <span class="fs-1 font-weight-bold">{{'APP_NAME' | translate}}</span>
      <img class="img-fluid mb-3" width="75px" height="80px" [src]="'assets/images/logos/logo.png'">
    </div>
    <form class="box-login" [formGroup]="loginForm" (ngSubmit)="login()" #ngForm="ngForm">
      <div class="form-group row mt-1">
        <label for="inputEmail3" class="col-sm-3 col-form-label">{{'SCREEN.LOGIN.NAME' | translate}}</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="inputEmail3" formControlName="username"
            [ngClass]="{'border border-danger': ngForm.submitted && form('username').errors}"
            [placeholder]="'SCREEN.LOGIN.NAME' | translate">
          <label *ngIf="ngForm.submitted && form('username').errors" class="text-danger">{{'MESS.CV0001'|translate:
            {label: labelId} }}</label>
        </div>
      </div>
      <div class="form-group row mt-3">
        <label for="inputPassword3" class="col-sm-3 col-form-label">{{'SCREEN.LOGIN.PASSWORD' | translate}}</label>
        <div class="col-sm-9">
          <input type="password" class="form-control" id="inputPassword3"
            [ngClass]="{'border border-danger': ngForm.submitted && form('password').errors}"
            [placeholder]="'SCREEN.LOGIN.PASSWORD' | translate" formControlName="password">
          <label *ngIf="ngForm.submitted && form('password').errors" class="text-danger">{{'MESS.CV0001'|translate:
            {label: labelPassword} }}</label>
        </div>
      </div>
      <div class="form-group row mt-3">
        <div class="text-center">
          <button [disabled]="isLoading" type="submit" class="w-30 btn btn-info mb-2">{{'SCREEN.LOGIN.LOGIN' |
            translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>

<app-change-password [showEditPass]="showEditPass" [userData]="userData" (close)="showHideModal(false)"></app-change-password>
