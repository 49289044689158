import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-search-export-log',
  templateUrl: './search-export-log.component.html',
  styleUrls: ['./search-export-log.component.scss']
})
export class SearchExportLogComponent implements OnInit {
  maxDate = { day: null, month: null, year: null };
  minDate = { year: 1985, month: 1, day: 1 };
  form: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<SearchExportLogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      startDate: new FormControl(''),
      endDate: new FormControl('')
    });
  }

  ngOnInit(): void {
    const yesterday = new Date();
      this.form.get('startDate').setValue(moment(yesterday).format('YYYY-MM-DD'));
      this.form.get('endDate').setValue(moment(yesterday).format('YYYY-MM-DD'));
   
  }
  submit(): void {
    if(!this.validateTime){
      this.dialogRef.close({ data: this.form.value });

    }
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  get validateTime(): boolean {
      const startDate =moment( this.form.get('startDate').value).format('YYYY-MM-DD') ;
      const endDate =  moment( this.form.get('endDate').value).format('YYYY-MM-DD') ;
    if ( startDate > endDate ) {
      return true
    }
  }
}
