import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-print-report',
  templateUrl: './print-report.component.html',
  styleUrls: ['./print-report.component.scss']
})
export class PrintReportComponent implements OnInit {

  @Input() item: any;

  constructor() { }

  ngOnInit() {
  }

  showCompanyName(): string {
    if (this.item.acceptedAt != null && this.item.acceptedByUserId != null || this.item.isAcceptedByProxy &&
      this.item.acceptedAt != null) {
      return '＊ ';
    } else if (this.item.isAcceptanceRequired && !this.item.isAcceptedByProxy && !this.item.acceptedByUserId) {
      return '― ';
    } else if (this.item.isAcceptanceRequired && !this.item.isAcceptedByProxy && this.item.acceptedByUserId) {
      return '〇 ';
    }
  }

  showDate(): string {
    return this.item.deliveryDate.slice(0, 4) + '年' +
      this.item.deliveryDateMonth + '月' + this.item.deliveryDateDay + '日' + this.item.endTime.slice(10, 13) + '      ' + moment(this.item.endTime).format('HH:mm');
  }

}
