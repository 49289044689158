<div class="header">
    <h2 class="title text-center">
        確認
    </h2>
    <form class="change-title" [formGroup]="form">
        <div class="change-title-item  align-items-start border-0 p-0 justify-content-center text-center">
            <span class="title-description">
                エクスポート期間を選択してください。
            </span>
        </div>
        <div class="change-title-item text-center">
            <div class="d-flex align-items-start justify-content-between">
                <div class="mb">
                    <input class="form-control input-height" name="dp1" ngbDatepicker formControlName="startDate"
                        #dp1="ngbDatepicker" (click)="dp1.toggle()" [maxDate]="maxDate" [minDate]="minDate"
                        firstDayOfWeek="7" readonly>
                    <span class="input-group-text icon"><i class="far fa-calendar-alt"></i></span>
                </div>
                <p>〜</p>
                <div class="mb">
                    <input class="form-control  input-height" name="dp2" ngbDatepicker formControlName="endDate"
                        #dp2="ngbDatepicker" (click)="dp2.toggle()" [maxDate]="maxDate" [minDate]="minDate"
                        firstDayOfWeek="7" readonly>
                    <span class="input-group-text icon"><i class="far fa-calendar-alt"></i></span>
                </div>
            </div>
        <span *ngIf="validateTime" style="color: red;">開始時間・終了時間を確認してください。</span>
           
        </div>

        <div class="title-submit ">
            <button class="btn btn-blue mr" (click)="submit()">エクスポート</button>
            <button class="btn btn-blue" (click)="closeDialog()">キャンセル</button>
        </div>
    </form>
</div>