import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-developing-component',
  templateUrl: './developing-component.component.html',
  styleUrls: ['./developing-component.component.scss']
})
export class DevelopingComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
