<app-header [titleComponent]="titleComponent"></app-header>
<!--Nhà cung cấp-->
<div class="container master-css overflowCss"
  *ngIf="userLogin?.role === userRole.PROVIDER_COMPANY   || authService.isSystemAdmin">
  <div class="row box">
    <div class="col-5 box-left" *ngIf="!authService.isSystemAdmin">
      <div class="box-left-item">
        <input  (click)="activeComponent('deliveries-history')" style="padding: 0px 26px 0px 32px" class="text-center cursor-pointer " [value]="'SCREEN.TOP_COMPONENT.DELIVERY_HISTORY' | translate"
          readonly />
      </div>
    </div>
    <div class="col-2" *ngIf="authService.isSystemAdmin"></div>
    <div class="box-right" [ngClass]="{'col-7 box-right-provider-company': !authService.isSystemAdmin, 'col-8': authService.isSystemAdmin}" >
      <div class="box-right-title"
           [ngClass]="authService.isProvider ? 'box-right-title-provider-company' : ''">
        <span class="box-right-title-text">
          供 給 会 社 管 理 メ ニ ュ ー
        </span>
      </div>
      <div class="box-right-provider">
        <input class="text-center cursor-pointer"
               [ngClass]="authService.isProvider ? 'cursor-pointer-provider-company' : ''"
               (click)="activeComponent('user-provider')"
          *ngIf="authService.isSystemAdmin || authService.isProvider"
          [value]="'SCREEN.TOP_COMPONENT.SUPPLIER_MANAGEMENT' | translate" readonly />
        <input class="text-center cursor-pointer"
               [ngClass]="authService.isProvider ? 'cursor-pointer-provider-company' : ''"
               (click)="activeComponent('sale-company')"
          *ngIf="authService.isProvider || authService.isSale"
          [value]="'SCREEN.TOP_COMPONENT.COUNTER_COMPANY_MANAGEMENT' | translate" readonly />
        <input class="text-center cursor-pointer"
               [ngClass]="authService.isProvider ? 'cursor-pointer-provider-company' : ''"
               (click)="activeComponent('gas-customers')"
          *ngIf="!authService.isSystemAdmin" [value]="'SCREEN.TOP_COMPONENT.DELIVERY_MANAGEMENT' | translate"
          readonly />
        <input class="text-center cursor-pointer"
               [ngClass]="authService.isProvider ? 'cursor-pointer-provider-company' : ''"
               (click)="activeComponent('delivery-company')"
          *ngIf="authService.isProvider || authService.isDelivery"
          [value]="'SCREEN.TOP_COMPONENT.SHIPPING_COMPANY_MANAGEMENT' | translate" readonly />
        <input class="text-center cursor-pointer btn-export export" (click)="openSearchExportLog()"
          *ngIf="authService.isSystemAdmin" [value]="'SCREEN.TOP_COMPONENT.OPERATION_HISTORY' | translate" readonly />
        <input class="text-center cursor-pointer btn-export export" (click)="selectFile()"
          *ngIf="authService.isSystemAdmin" value="供給会社Import" readonly />

        <input class="text-center cursor-pointer cursor-pointer-provider-company" *ngIf="authService.isProvider" (click)="activeComponent('vendor')"
               [value]="'SCREEN.TOP_COMPONENT.VENDOR' | translate" readonly />
        <input class="text-center cursor-pointer cursor-pointer-provider-company" *ngIf="authService.isProvider" (click)="activeComponent('shipment-location')"
               [value]="'SCREEN.TOP_COMPONENT.SHIPPING_POINT_MANAGEMENT' | translate" readonly />
      </div>
    </div>
    <div class="col-2" *ngIf="authService.isSystemAdmin"></div>
  </div>
</div>

<!--Trung gian-->
<div class="container master-css overflowCss" *ngIf="userLogin?.role === userRole.SALE_COMPANY">
  <div class="row box-sale">
    <div class="col-5 box-left">
      <div class="box-left-item"
        *ngIf="globalData.checkPermissionAction('B2_HISTORY')">
        <input  (click)="activeComponent('deliveries-history')" style="padding: 0px 26px 0px 32px" class="text-center cursor-pointer " [value]="'SCREEN.TOP_COMPONENT.DELIVERY_HISTORY_1' | translate"
          readonly />
      </div>
    </div>
    <div class="col-7 box-right box-right-sale-company">
      <div class="box-right-title box-right-title-sale-company">
        <span class="box-right-title-text">
          窓 口 会 社 管 理 メ ニ ュ ー
        </span>
      </div>
      <div class="box-right-item" *ngIf="globalData.checkPermissionAction('B2_CUSTOMER')">
        <input (click)="activeComponent('company-details')" class="text-center cursor-pointer "
          [value]="'SCREEN.TOP_COMPONENT.SALE_DETAIL' | translate" readonly />
        <input (click)="activeComponent('gas-customers')" class="text-center cursor-pointer "
          [value]="'SCREEN.TOP_COMPONENT.DELIVERY_MANAGEMENT' | translate" readonly />
      </div>
    </div>
  </div>
</div>

<!--Chuyển phát-->
<div class="container master-css overflowCss" *ngIf="userLogin?.role === userRole.DELIVERY_COMPANY">
  <div class="row box-delivery">
    <div class="col-5 box-left">
      <div class="box-left-item"
        *ngIf="globalData.checkPermissionAction('B2_HISTORY')">
        <input (click)="activeComponent('deliveries-history')" style="padding: 0px 26px 0px 32px"  class="text-center cursor-pointer " [value]="'SCREEN.TOP_COMPONENT.DELIVERY_HISTORY' | translate"
          readonly />
      </div>
    </div>
    <div class="col-7 box-right box-right-delivery-company">
      <div class="box-right-title box-right-title-delivery-company">
        <span class="box-right-title-text">
           配 送 会 社 管 理 メ ニ ュ ー
        </span>
      </div>
      <div class="box-right-item" *ngIf="globalData.checkPermissionAction('B2_CUSTOMER')">
        <input class="text-center cursor-pointer " (click)="activeComponent('company-details')"
          [value]="'SCREEN.TOP_COMPONENT.DELIVERY_DETAILS' | translate" readonly />
        <input class="text-center cursor-pointer " (click)="activeComponent('gas-customers')"
          [value]="'SCREEN.TOP_COMPONENT.DELIVERY_MANAGEMENT' | translate" readonly />

        <input class="text-center cursor-pointer " (click)="activeComponent('shipment-location')"
               [value]="'SCREEN.TOP_COMPONENT.SHIPPING_POINT_MANAGEMENT' | translate" readonly />
      </div>
    </div>
  </div>
</div>

<!--Khách hàng-->
<div class="container master-css overflowCss" *ngIf="userLogin?.role === userRole.CUSTOMER">
  <div class="row box-customer">
    <div class="col-5 box-left">
      <div class="box-left-item"
        *ngIf="globalData.checkPermissionAction('B2_HISTORY')">
        <input (click)="activeComponent('deliveries-history')" style="padding: 0px 26px 0px 32px"  class="text-center cursor-pointer top-item" [value]="'SCREEN.TOP_COMPONENT.DELIVERY_HISTORY_1' | translate"
          readonly />
      </div>
    </div>
    <div class="col-7 box-right box-right-customer">
      <div class="box-right-title box-right-title-customer">
        <span class="box-right-title-text" style="color: white">
          納 入 先 管 理 メ ニ ュ ー
        </span>
      </div>
      <div class="box-right-item" *ngIf="globalData.checkPermissionAction('B2_CUSTOMER')">
        <input class="text-center cursor-pointer " (click)="activeComponent('company-details')"
          [value]="'SCREEN.TOP_COMPONENT.COMPANY_DETAILS' | translate" readonly />
      </div>
    </div>
  </div>
</div>

<input type="file" class="input-file" #inputFile style="display: none" id="file" accept=".xls, .xlsx"
  (change)="handleFileInput($event.target?.files)">
<style>
.cursor-pointer-provider-company{
  background-color: #D5F4F0 !important;
}
.box-right-title-provider-company{
  background-color: #009180 !important;
}
.box-right-provider-company{
  background-color: #EBEEEE !important;
}

.box-right-sale-company{
  background-color: #EBEEEE !important;
}
.box-right-title-sale-company{
  background-color: #009180 !important;
}
.cursor-pointer-sale-company{
  background-color: #D5F4F0 !important;
}

.box-right-delivery-company{
  background-color: #EBEEEE !important;
}
.box-right-title-delivery-company{
  background-color: #009180 !important;
}
.cursor-pointer-delivery-company{
  background-color: #D5F4F0 !important;
}

.box-right-customer{
  background-color: #EBEEEE !important;
}
.box-right-title-customer{
  background-color: #009180 !important;
}
.cursor-pointer-customer{
  background-color: #D5F4F0 !important;
}
.master-css .box .box-right .box-right-provider input:hover {
  background-color: white !important;
}
.master-css .box .box-right .box-right-item input:hover {
  background-color: white !important;
}
</style>
