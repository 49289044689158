<nav class="navbar navbar-light bg-light border-header">
  <div class="container-fluid row p-0">
    <div class="avatar col-5" style="padding-left: 24px;" >
      <a class="navbar-brand cursor-pointer" (click)="redirectTop()">
        <span class="font-weight-bold text-size">{{'APP_NAME' | translate}}</span>
      </a>
    </div>
    <div class="title-screen col-4" style="padding-left: 1px;" >
      <span class="no-border text-size text-letter titleComponent">{{global.title}}</span>
      <span class="no-border text-size text-letter titleUser">{{titleUser}}</span>
    </div>
    <form class="d-flex col-3 text-end p-0" style="justify-content: end;">
      <span class="form-control me-2 no-border" style="background: transparent">{{'HELLO'| translate}} {{currentUser?.name}} さん</span>
      <button class="btn btn-logout" (click)="logout()"><span></span>{{'SCREEN.LOGIN.LOGOUT' | translate}}</button>
    </form>
  </div>
</nav>
