import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {PagingConfig} from '../../../configs/paging-config';
import {NetworkService} from '../../../services/network.service';
import {GlobalDataService} from '../../../services/global-data.service';
import {ConfirmationModalService} from '../../service/modal/confirmation-modal.service';

@Component({
  selector: 'app-pagination',
  styles: ['button.btn{text-decoration: none;}', 'button.btn:focus{box-shadow: 0 0 0 0.1rem #598DC1;}'],
  templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnChanges {
  @Input() totalPages = 1;
  @Input() selectedPage = 1;
  @Output() pageChange = new EventEmitter<number>();

  pager: {
    currentPage?: number,
    totalPages?: number,
    pages?: Array<number>,
  } = {
    currentPage: 1,
  };

  constructor(public networkService: NetworkService,
              private globalData: GlobalDataService, private confirmPopup: ConfirmationModalService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.networkService.online === false) {
      this.confirmPopup.notify('', 'error.ER0029');
      this.networkService.isNetworkStopped = true;
      // @ts-ignore
      return false;
    }
    if (changes.selectedPage?.currentValue) {
      this.setPage(this.selectedPage);
    }
    if (changes.totalPages?.currentValue) {
      this.setPage(this.selectedPage);
    }
  }

  setPage(page: number): void {
    const hasEditForm = this.globalData.getStatusDataEdit();
    if (page < 1 || page > this.totalPages || this.networkService.online === false) {
      this.confirmPopup.notify('', 'error.ER0029');
      this.networkService.isNetworkStopped = true;
      // @ts-ignore
      return false;
    }
    if (hasEditForm) {
      this.confirmPopup.confirm('', 'error.ER0061', 'ok', 'cancel', () => {
        this.globalData.setStatusDataEdit(false);
        this.changePage(page);
      }, () => {
        return;
      });
    } else {
      this.changePage(page);
    }

  }

  changePage(page): void {
    if (this.pager.currentPage !== page) {
      this.pageChange.emit(page);
    }
    if (this.totalPages) {
      this.pager = this._getPager(this.totalPages, page);
    }
  }

  private _getPager(totalPages: number, currentPage: number = 1): {
    currentPage: number;
    totalPages: number;
    pages: Array<number>;
  } {
    let startPage: number;
    let endPage: number;

    if (totalPages <= PagingConfig.PageShow) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage <= (Math.floor(PagingConfig.PageShow / 2) + 1)) {
      startPage = 1;
      endPage = PagingConfig.PageShow;
    } else if (currentPage >= totalPages - Math.floor(PagingConfig.PageShow / 2)) {
      startPage = totalPages - (PagingConfig.PageShow - 1);
      endPage = totalPages;
    } else {
      startPage = currentPage - Math.floor(PagingConfig.PageShow / 2);
      endPage = currentPage + Math.floor(PagingConfig.PageShow / 2);
    }

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from({length: endPage - startPage + 1}, (x, i) => i + startPage);

    // return object with all pager properties required by the view
    return {
      currentPage,
      totalPages,
      pages
    };
  }
}

