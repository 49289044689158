import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import { CompanyType, UserProviderRoles, UserRoles } from '../../../configs/user-config';
import { BreadcrumbModel } from '../../../models/breadcrumb.model';
import { LOGIN_ID_VALIDATOR, STATUS_RECEIPT, WEEKDAYS } from '../../../configs/const';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { LoadingService } from '../../service/loading.service';
import { ReceiptApiService } from '../../../services/api-service/receipt.api.service';
import { ConfirmationModalService } from '../../service/modal/confirmation-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/api-service/auth.service';
import { BasicFunctionService } from '../../service/basicFunction.service';
import { ProductService } from '../../../services/api-service/product.service';
import { GlobalDataService } from '../../../services/global-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CustomValidators } from '../../utils/custom-validators';
import { PERMISSION_CONFIG } from 'src/app/configs/permision-config';
import { DatePipe } from '@angular/common';
import { NoteReceiptComponent } from '../receipt-form/note-receipt/note-receipt.component';
import { MatDialog } from '@angular/material/dialog';
import {CustomerApi} from '../../../services/api-service/customer.api';
import {CustomerCompanyModel} from '../../../models/customer-company.model';

@Component({
  selector: 'app-non-delivery-report',
  templateUrl: './non-delivery-report.component.html',
  styleUrls: ['./non-delivery-report.component.scss']
})
export class NonDeliveryReportComponent implements OnInit, AfterViewInit {
  @Output() newItemEvent = new EventEmitter<number>();
  @Input() isNumber = false;
  @ViewChild('inputFile') myInputVariable: ElementRef;
  maxDate = { day: 31, month: 12, year: (new Date()).getFullYear() + 15 };
  minDate = { year: 2021, month: 1, day: 1 };
  endTimeData;
  dataDetail;
  flag = true;
  id = '';
  isDeliveryParent;
  formReceipt: FormGroup;
  modeEdit = false;
  disableBtnEditFormDelivery = false;
  errProviderCompanyId = '';
  submittedFormDelivery = false;
  companyNameText = new FormControl('');
  companyCodeText = new FormControl('');
  oldValueForm: any;
  disableEditFormDelivery = false;
  isProvider = false;
  isSystemAdmin = false;
  suggestDelivery = false;
  suggestProviderT1 = false;
  userRole = UserRoles;
  listBreadcrumb: Array<BreadcrumbModel> = [];
  companyType = CompanyType;
  providerCompanyId = '';
  partnerCompanyId = '';
  dayOfWeek = '';
  textErrVehicle = '';
  textErrDeliveryUser = '';
  weekDay = WEEKDAYS;
  userId;
  receiptNumber;
  suggestProvider = false;
  isDelivery = false;
  listVehicle = [];
  maxLength = null;
  index = null;
  customerCompanyId;
  listDeliveryUser = [];
  fileUpload: File = null;
  currenUserLogin: any;
  statusReceipt = STATUS_RECEIPT;
  labelCompanyName = '';
  editDate;
  delivererUserName;
  vehicleCode;
  editMonth;
  statusBtn = {
    editBtn: false,
    saveDraftBtn: false,
    discardChangeBtn: false,
    acceptBtn: false,
    approveBtn: false
  };
  formDisable = false;
  userProviderRoles = UserProviderRoles;
  checkBoxOption1 = '';
  checkBoxOption2 = '';
  dataForPrint: any;
  dataPrint = [];
  isPrintList = false;
  checkUserName = false;
  listReason = [];
  textErrEndTime = '';
  textErrorAmount = '';
  deliveryCompanyId;
  customerCompanyCode;
  textErrorNonDeliveryReasonId = '';
  textErrorCompanyCode = '';
  textErrorCompanyName = '';
  textErrorSaleFrontCompany = '';
  textDeliveryCompany = '';
  textErrorOfficalName = '';
  setProviderCompanyId = 0;
  textErrVehicleCode = '';
  textErrDelivererUserId = '';
  textErrTankPercentageBefore = '';
  messError;
  editDay;
  vehicle;
  companyCode;
  delivererUserId;
  permissionConfig = PERMISSION_CONFIG;
  endTime;
  isClickNone = true;
  deliveryCompanyOfficeName;
  deliveryCompanyOfficialName;
  isConfirmWeb = false;
  isConfirmWeb1 = false;
  paramPlus: any;
  paramPlusDelivery: any;
  userRoleSale = false;
  userRoleCustomer = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private receiptService: ReceiptApiService,
    private confirmPopup: ConfirmationModalService,
    private translateService: TranslateService,
    public authService: AuthService,
    private router: Router,
    private basicFunction: BasicFunctionService,
    private productService: ProductService,
    public globalData: GlobalDataService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private el: ElementRef,
    private dialog: MatDialog,
    private render: Renderer2,
    private customerApi: CustomerApi
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const url = event.url;
        const path = '/detail/deliveries-form/';

        if (url.search(path) !== -1) {
          const arr = event.url.split('/');
          if (!this.isClickNone) {
            const listIndex = JSON.parse(localStorage.getItem('listIdReceipt'));
            const type = listIndex.find(val => val.deliveryId == arr[3])?.type;
            this.getMinMaxReceipt(arr[3]);
            if (type) {
              this.getDataNonDeliveryReport(arr[3]);
            } else {
              this.isClickNone = true;
            }
            this.newItemEvent.emit(parseInt(arr[3]));
          }
        } else {
          this.isClickNone = true;
        }
      }
    });

    this.isProvider = this.authService.isProvider;
    this.isSystemAdmin = this.authService.isSystemAdmin;
    this.isDelivery = this.authService.isDelivery;

    const historyFilter = localStorage.getItem('history-filter') ? JSON.parse(localStorage.getItem('history-filter')) : null;
    if (historyFilter) {
      if (historyFilter.typeMenu === 3){
        this.listBreadcrumb = [
          { url: '/top', breadcrumb: 'トップメニュー' },
          { url: '/list/deliveries-history', breadcrumb: '合算履歴' },
          { url: '', breadcrumb: '未納入報告書' },
        ];
      }else {
        this.listBreadcrumb = [
          { url: '/top', breadcrumb: 'トップメニュー' },
          { url: '/list/deliveries-history', breadcrumb: '納入履歴' },
          { url: '', breadcrumb: '未納入報告書' },
        ];
      }
    }else {
      this.listBreadcrumb = [
        { url: '/top', breadcrumb: 'トップメニュー' },
        { url: '/list/deliveries-history', breadcrumb: '納入履歴' },
        { url: '', breadcrumb: '未納入報告書' },
      ];
    }

    this.currenUserLogin = this.authService.currentUserValue;
    this.providerCompanyId = this.currenUserLogin.providerCompanyId;
    this.partnerCompanyId = this.currenUserLogin.partnerCompanyId;
    this.buildForm();
    if (this.authService.isProvider && this.authService.isEditProviderOther) {
      this.suggestProvider = true;
    }
    if (this.authService.isDelivery || this.authService.isSale || this.authService.isCustomer) {
      this.suggestDelivery = true;
    }
    if (this.authService.isProvider && !this.authService.isEditProviderOther) {
      this.suggestProviderT1 = true;
    }
    this.messError = {
      amount: this.translateService.instant('MESS.CV0001', { label: translateService.instant('B4.AMOUNT') }),
      productName: this.translateService.instant('MESS.CV0001', { label: translateService.instant('B4.PRODUCT_NAME') }),
      saleFrontCompanyName: this.translateService.instant('MESS.CV0001', { label: translateService.instant('B4.SALE_FONT_COMPANY_NAME') }),
      customerCompanyName: this.translateService.instant('B4.COMPANY_NAME_VALIDATE'),
      deliveryCompanyName: this.translateService.instant('MESS.CV0001', { label: '運送会社名' }),
      vehicleCode: this.translateService.instant('MESS.CV0001', { label: '車番' }),
      delivererUserId: this.translateService.instant('MESS.CV0001', { label: '乗務員名' }),
      endTime: this.translateService.instant('MESS.CV0001', { label: '終了時間' }),
      temperature: this.translateService.instant('MESS.MS4013'),
      tankPercentageBefore: this.translateService.instant('MESS.CD0099'),
      nonDeliveryReasonId: this.translateService.instant('MESS.CV0001', { label: '未納入理由' }),
      note: this.translateService.instant('MESS.ER_MAX_LENGTH', { label: '備考', maxLength: 200 }),
    }
    this.textErrVehicle = this.translateService.instant('MESS.ER0204');
    this.textErrDeliveryUser = this.translateService.instant('MESS.ER0203');
    this.globalData.setStatusDataEdit(false);
    this.userRoleSale = this.userRole.SALE_COMPANY === this.authService.currentUserValue.role;
    this.userRoleCustomer = this.userRole.CUSTOMER === this.authService.currentUserValue.role;
  }

  ngOnInit(): void {
    if (this.userRoleCustomer){
      this.getDetailCustomerCompany();
    }
    if (this.userRoleSale){
      this.getListCustomer();
    }
    this.globalData.title = '未納入報告書';
    if (this.globalData.clickSearch) {
      this.globalData.statusFormDetail = true;
    }
    this.getDayOfWeek(moment().format('YYYY-MM-DD'));
    this.basicFunction.setStatusChangeRoute(true);
    this.id = this.route.snapshot.paramMap.get('id');
    this.getReasonDeliveryReport();
    if (!this.id) {
      localStorage.removeItem('isDeliveryParent');
      if (this.authService.isDelivery) {
        this.formReceipt.get('delivererUserId').setValue(this.currenUserLogin.userId, { emitEvent: false });
        this.formReceipt.get('delivererUserName').setValue(this.currenUserLogin.name, { emitEvent: false });
        this.form('deliveryCompanyId').setValue(this.currenUserLogin.partnerCompanyId);
        this.form('deliveryCompanyName').setValue(this.currenUserLogin.companyName);
        this.form('deliveryOfficialName').setValue(this.currenUserLogin.companyOfficalName);
      }
      const date = {
        day: moment().date(),
        month: moment().month() + 1,
        year: moment().year()
      };
      this.updateDate(date);
      this.checkStatusBtnCreate();
    } else {
      this.modeEdit = true;
      this.getDataNonDeliveryReport(this.id);
      this.getMinMaxReceipt(this.id);
    }
    this.isClickNone = true;
    this.updateNowTime();

    if (this.authService.isProvider || this.authService.isDelivery) {
      this.paramPlus = {saleOrder: '', companyType: this.companyType.CUSTOMER, providerCompanyId: '', checkRole: true};
    }

    if (this.authService.isDelivery) {
      this.paramPlusDelivery = {deliveryId: this.partnerCompanyId , providerCompanyId: this.providerCompanyId, checkRole: true};
    }
  }

  getListCustomer(): void {
    const offset = 100;
    const body = {
      filters: {
        customerCompanyName: '',
        deliveryCompanyName: '',
        productName: '',
        providerCompanyIds: [],
        saleCompanyName: ''
      },
      pageNumber: 0,
      offset,
      sort: {
        sortDirection: 1,
        sortKey: ''
      }
    };
    this.customerApi.customerCompanySearch.sort = body.sort;
    this.customerApi.customerCompanySearch.filters = body.filters;
    this.customerApi.getListCustomer(body).subscribe((data: any) => {
      const dataCustomer: Array<CustomerCompanyModel> = data.data;
      dataCustomer.map((item) => {
        item.saleList?.filter(it => {
          // tslint:disable-next-line:max-line-length
          if ((it?.saleCompanyOrder === 2 || it?.saleCompanyOrder === 4 || it?.saleCompanyOrder === 5) && it?.saleCompanyCode === this.authService.currentUserValue.companyCode && it?.isWebSaleOrder === true) {
            this.isConfirmWeb1 = true;
          }
        });
      });
    }, (error: { success: boolean, message: string }) => {
    }, () => {
    });
  }


  getDetailCustomerCompany(): void {
    this.customerApi.getDetail(this.authService.currentUserValue.partnerCompanyId).subscribe((data) => {
      if (data.isAcceptanceRequired && this.userRoleCustomer){
        this.isConfirmWeb1 = true;
      }
    });
  }

  get checkCanEdit(): boolean {
    return this.globalData.checkPermissionAction(this.permissionConfig.ACTIONS_CODE.DELIVERIES_HISTORY_EDIT);
  }

  get checkViewOtherProvider(): boolean {
    if (!this.id || this.authService.currentUserValue.companyCode === '70001') {
      return true;
    }

    return this.globalData.checkViewOtherProvider(this.setProviderCompanyId, false);
  }

  get checkEditOtherProvider(): boolean {
    if (!this.id || this.authService.currentUserValue.companyCode === '70001') {
      return true;
    }
    return this.globalData.checkEditOtherProvider(this.setProviderCompanyId, false);
  }

  checkStatusBtnCreate(): void {
    if (this.authService.isProvider || this.authService.isDelivery) {
      this.statusBtn.editBtn = true;
      this.statusBtn.saveDraftBtn = true;
      this.statusBtn.discardChangeBtn = true;
    }
  }

  getDayOfWeek(day: string): void {
    this.dayOfWeek = '';
    const weekDayName = moment(day).format('d');
    for (const item of this.weekDay) {
      if (+weekDayName === item.value) {
        this.dayOfWeek = item.text;
      }
    }
  }

  updateNowTime(): void {
    const time = new Date();
    const year = this.datePipe.transform(time, 'yyyy');
    const month = this.datePipe.transform(time, 'MM');
    const day = this.datePipe.transform(time, 'dd');
    this.formReceipt.get('deliveryYear').setValue(year);
    this.formReceipt.get('deliveryMonth').setValue(month);
    this.formReceipt.get('deliveryDateDay').setValue(day);
    this.formReceipt.get('deliveryDate')
      .setValue(this.form('deliveryYear').value + '-' + this.form('deliveryMonth').value
        + '-' + this.form('deliveryDateDay').value);
    if (!this.id) {
      const nowTime = {
        hour: time.getHours(), minute: time.getMinutes(), second: 0
      };
      this.endTimeData = nowTime;
      this.updateTime(nowTime, 'endTime');
    }

  }

  showTimePicker(): void {
    setTimeout(() => {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
        '.ngb-tp-input'
      );
      if (firstInvalidControl) {
        firstInvalidControl.focus();
      }
    });
  }

  actionUpdateTime(data, type): void {
    this.updateTime(data, type);
    this.formReceipt.markAsDirty();
  }

  updateTime(data?: { hour: number, minute: number, second: number }, type?: string,): void {
    if (!data) {
      let dataTime: any = {};

      if (type === 'endTime') {
        if (this.form('endTime').value) {
          const endTime = this.form('endTime').value?.split(':');
          dataTime = { hour: parseInt(endTime[0]), minute: parseInt(endTime[1]), second: 0 };
          this.endTimeData = dataTime;
        }
      }
      return;
    }
    const localData = JSON.parse(JSON.stringify(data));
    if (localData.hour < 10) {
      localData.hour = '0' + localData.hour;
    }
    if (localData.minute < 10) {
      localData.minute = '0' + localData.minute;
    }
    if (type === 'endTime') {
      this.endTime = localData.hour + ':' + localData.minute;
      this.formReceipt.get('endTime').setValue(localData.hour + ':' + localData.minute);
    }
  }
  buildForm(data?): void {
    if (data) {
      this.setProviderCompanyId = data.providerCompanyId;
      if (!this.checkViewOtherProvider && !this.checkEditOtherProvider && this.authService.currentUserValue.companyCode !== '70001') {
        this.globalData.processPermissionFalse();
      }
    }

    this.formReceipt = this.formBuilder.group({
      customerCompanyId: new FormControl(data ? data.customerCompanyId : '', []),
      customerCompanyCode: new FormControl(data ? data.customerCompanyCode : ''),
      customerCompanyName: new FormControl(data ? data.customerCompanyName : ''),
      deliveryDate: new FormControl(this.editDate),
      endTime: new FormControl(this.endTime),
      startTime: new FormControl(''),
      deliveryYear: new FormControl(data ? data.deliveryYear : ''),
      deliveryMonth: new FormControl(this.editMonth),
      deliveryDateDay: new FormControl(this.editDay),
      saleFrontCompanyId: new FormControl(data ? data.saleFrontCompanyId : ''),
      saleFrontCompanyCode: new FormControl(data ? data.saleFrontCompanyCode : ''),
      saleFrontCompanyName: new FormControl(data ? data.saleFrontCompanyName : ''),
      vehicleCode: new FormControl(data ? data.vehicleCode : ''),
      tankPercentageBefore: new FormControl(data ? data.tankPercentageBefore : ''),
      note: new FormControl(data ? data.note : ''),
      deliveryCompanyId: new FormControl(data ? data.deliveryCompanyId : ''),
      deliveryCompanyName: new FormControl(data ? data.deliveryCompanyName : ''),
      customerSignature: new FormControl(data ? data.customerSignature : ''),
      deliveryStatus: new FormControl(data ? data.deliveryStatus : ''),
      delivererUserId: new FormControl(data ? data.delivererUserId : ''),
      delivererUserName: new FormControl(data ? data.delivererUserName : ''),
      systemOwnerId: new FormControl(data ? data.systemOwnerId : this.currenUserLogin.systemOwnerId),
      acceptedByUserId: new FormControl(data ? data.acceptedByUserId : ''),
      declaredAt: new FormControl(data ? data.declaredAt : ''),
      declaredByUserId: new FormControl(data ? data.declaredByUserId : ''),
      acceptedAt: new FormControl(data ? data.acceptedAt : ''),
      nonDeliveryReasonId: new FormControl(data ? data.nonDeliveryReasonId : ''),
      nonDeliveryReasonName: new FormControl(data ? data.nonDeliveryReasonName : ''),
      receiptNumber: new FormControl(data ? data.receiptNumber : ''),
      customerCompanyBranchName: new FormControl(data ? data.customerCompanyBranchName : ''),
      isAcceptanceRequired: new FormControl(data ? data.isAcceptanceRequired : ''),
      isNondelivery: new FormControl(data ? data.isNondelivery : ''),
      deliveryOfficeName: new FormControl(this.deliveryCompanyOfficeName),
      deliveryOfficialName: new FormControl(this.deliveryCompanyOfficialName),
      saleFrontBillingCompanyName: new FormControl(data ? data.saleFrontBillingCompanyName : ''),
      saleBillingCompanyName: new FormControl(data ? data.saleBillingCompanyName : ''),
    });

  }

  getDataNonDeliveryReport(id?: string): void {
    this.loadingService.showAppLoading();
    this.isConfirmWeb = false;
    this.receiptService.getNonDeliveryReport(id).subscribe((data) => {
      this.dataDetail = data;
      this.delivererUserName = data.delivererUserName;
      if (this.suggestDelivery) {
        localStorage.removeItem('isDeliveryParent');
        localStorage.setItem('isDeliveryParent', data.isDeliveryParent);
      }
      this.customerCompanyId = data.customerCompanyId;
      this.deliveryCompanyId = data.deliveryCompanyId;
      this.customerCompanyCode = data.customerCompanyCode;
      this.userId = data.delivererUserId;
      this.receiptNumber = data.receiptNumber;
      this.vehicle = data.vehicleCode;
      this.delivererUserId = data.delivererUserId
      this.endTimeData = data.endTime ? this.formatTimeGetServe(data.endTime) : this.endTimeData;
      this.updateTime(this.endTimeData);
      if (data.deliveryCompanyOfficeName || data.deliveryCompanyOfficialName) {
        this.deliveryCompanyOfficeName = data.deliveryCompanyOfficeName;
        this.deliveryCompanyOfficialName = data.deliveryCompanyOfficialName;
      } else {
        this.deliveryCompanyOfficeName = '';
        this.deliveryCompanyOfficialName = '';
      }
      this.formReceipt.get('vehicleCode').setValue(data.vehicleCode);
      this.vehicleCode = data.vehicleCode;
      this.editDate = moment(data.deliveryDate, 'DD-MM-YYYY');
      const endTime = data.endTime ? this.formatTimeGetServe(data.endTime) : this.endTime;
      this.updateTime(endTime, 'endTime');
      if (data.deliveryMonth < 10) {
        this.editMonth = '0' + data.deliveryMonth;
      } else {
        this.editMonth = data.deliveryMonth;
      }
      if (data.deliveryDay < 10) {
        this.editDay = '0' + data.deliveryDay;
      } else {
        this.editDay = data.deliveryDay;
      }

      if (!data.isAcceptanceRequired || !data.isConfirmationWeb || ( (data.isConfirmationWebCustomer || data.isConfirmationWebSale)
        && data.confirmationWebAt && data.confirmationWebByUserId)) {
        this.isConfirmWeb = true;
      }

      this.buildForm(data);
      this.disableEditFormDelivery = true;
      this.oldValueForm = JSON.parse(JSON.stringify(data));
      this.companyNameText.setValue(data.customerCompanyName);
      this.companyCodeText.setValue(data.customerCompanyCode);
      if (data.customerCompanyId) {
        this.getCompanyByCodeUpdateType(data.customerCompanyCode);
      }
      if (data.delivererUserId) {
        this.getUserByDeliveryId(data.delivererUserId);
      }
      if (data.deliveryDate) {
        if (data.deliveryDate) {
          const day = data.deliveryDate.slice(0, 2)
          const month = data.deliveryDate.slice(2, 6)
          const year = data.deliveryDate.slice(6, 10)
          const date = year + month + day;
          this.getDayOfWeek(date)
        }
      }
      if (data.acceptedAt != null && data.acceptedByUserId != null || data.isAcceptedByProxy &&
        data.acceptedAt != null) {
        this.labelCompanyName = '＊ ';
      } else if (data.isAcceptanceRequired && !data.isAcceptedByProxy && !data.acceptedByUserId) {
        this.labelCompanyName = '― ';
      } else if (data.isAcceptanceRequired && !data.isAcceptedByProxy && data.acceptedByUserId) {
        this.labelCompanyName = '〇 ';
      }
      this.checkStatusBtn(data);
      this.checkStatusForm(data.deliveryStatus);
    }, (error: { success: boolean, message: string }) => {
      this.loadingService.hideAppLoading();
      this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message, 'BTN.OK', () => {
        this.router.navigateByUrl('list/deliveries-history');
      })
    }, () => {
      this.loadingService.hideAppLoading();
      this.isClickNone = false;
      this.globalData.setStatusDataEdit(false);
    });
  }

  formatTimeGetServe(time: string): { hour: number; minute: number; second: number } {
    if (!time) {
      return;
    }
    const data = { hour: 0, minute: 0, second: 0 };
    const rawTime = time.split(' ')[1];
    data.hour = parseInt(rawTime.split(':')[0], 10);
    data.minute = parseInt(rawTime.split(':')[1], 10);
    return data;
  }
  activeEdit(): void {
    this.disableEditFormDelivery = false;
    this.formDisable = false;
    (this.id) && this.onListenFormChange();
  }

  addValidateForm(): void {
    this.formReceipt.get('customerCompanyId').setValidators([CustomValidators.required()]);
    this.formReceipt.get('customerCompanyId').updateValueAndValidity();
    this.formReceipt.get('customerCompanyCode').setValidators([CustomValidators.required()]);
    this.formReceipt.get('customerCompanyCode').updateValueAndValidity();
    this.formReceipt.get('customerCompanyName').setValidators([CustomValidators.required()]);
    this.formReceipt.get('customerCompanyName').updateValueAndValidity();
    this.formReceipt.get('deliveryDate').setValidators([CustomValidators.required()]);
    this.formReceipt.get('deliveryDate').updateValueAndValidity();
    this.formReceipt.get('saleFrontCompanyId').setValidators([CustomValidators.required()]);
    this.formReceipt.get('saleFrontCompanyId').updateValueAndValidity();
    this.formReceipt.get('saleFrontCompanyCode').setValidators([CustomValidators.required()]);
    this.formReceipt.get('saleFrontCompanyCode').updateValueAndValidity();
    this.formReceipt.get('saleFrontCompanyName').setValidators([CustomValidators.required()]);
    this.formReceipt.get('saleFrontCompanyName').updateValueAndValidity();
    this.formReceipt.get('deliveryCompanyId').setValidators([CustomValidators.required()]);
    this.formReceipt.get('deliveryCompanyId').updateValueAndValidity();
    this.formReceipt.get('deliveryCompanyName').setValidators([CustomValidators.required()]);
    this.formReceipt.get('deliveryCompanyName').updateValueAndValidity();
    this.formReceipt.get('vehicleCode').setValidators([CustomValidators.required()]);
    this.formReceipt.get('vehicleCode').updateValueAndValidity();
    this.formReceipt.get('delivererUserId').setValidators([CustomValidators.required()]);
    this.formReceipt.get('delivererUserId').updateValueAndValidity();
    this.formReceipt.get('nonDeliveryReasonId').setValidators([CustomValidators.required()]);
    this.formReceipt.get('nonDeliveryReasonId').updateValueAndValidity();
    this.formReceipt.get('tankPercentageBefore').setValidators([this.validatePressure()]);
    this.formReceipt.get('tankPercentageBefore').updateValueAndValidity();
    this.formReceipt.get('endTime').setValidators([CustomValidators.required()]);
    this.formReceipt.get('endTime').updateValueAndValidity();
    this.formReceipt.get('deliveryOfficeName').setValidators([]);
    this.formReceipt.get('deliveryOfficeName').updateValueAndValidity();
    this.formReceipt.get('saleFrontBillingCompanyName').setValidators([CustomValidators.required()]);
    this.formReceipt.get('saleFrontBillingCompanyName').updateValueAndValidity();
    this.formReceipt.get('deliveryOfficialName').setValidators([CustomValidators.required()]);
    this.formReceipt.get('deliveryOfficialName').updateValueAndValidity();
    this.formReceipt.get('saleBillingCompanyName').setValidators([CustomValidators.required()]);
    this.formReceipt.get('saleBillingCompanyName').updateValueAndValidity();
    this.formReceipt.get('note').setValidators([Validators.maxLength(200)]);
    this.formReceipt.get('note').updateValueAndValidity();

  }
  resetValidate(): void {
    this.formReceipt.get('customerCompanyId').clearValidators();
    this.formReceipt.get('customerCompanyId').updateValueAndValidity();
    this.formReceipt.get('customerCompanyCode').clearValidators();
    this.formReceipt.get('customerCompanyCode').updateValueAndValidity();
    this.formReceipt.get('customerCompanyName').clearValidators();
    this.formReceipt.get('customerCompanyName').updateValueAndValidity();
    this.formReceipt.get('deliveryDate').clearValidators();
    this.formReceipt.get('deliveryDate').updateValueAndValidity();
    this.formReceipt.get('saleFrontCompanyId').clearValidators();
    this.formReceipt.get('saleFrontCompanyId').updateValueAndValidity();
    this.formReceipt.get('saleFrontCompanyCode').clearValidators();
    this.formReceipt.get('saleFrontCompanyCode').updateValueAndValidity();
    this.formReceipt.get('saleFrontCompanyName').clearValidators();
    this.formReceipt.get('saleFrontCompanyName').updateValueAndValidity();
    this.formReceipt.get('deliveryCompanyId').clearValidators();
    this.formReceipt.get('deliveryCompanyId').updateValueAndValidity();
    this.formReceipt.get('deliveryCompanyName').clearValidators();
    this.formReceipt.get('deliveryCompanyName').updateValueAndValidity();
    this.formReceipt.get('nonDeliveryReasonId').clearValidators();
    this.formReceipt.get('nonDeliveryReasonId').updateValueAndValidity();
    this.formReceipt.get('tankPercentageBefore').clearValidators();
    this.formReceipt.get('tankPercentageBefore').updateValueAndValidity();
    this.formReceipt.get('vehicleCode').clearValidators();
    this.formReceipt.get('vehicleCode').updateValueAndValidity();
    this.formReceipt.get('delivererUserId').clearValidators();
    this.formReceipt.get('delivererUserId').updateValueAndValidity();
    this.formReceipt.get('endTime').clearValidators();
    this.formReceipt.get('endTime').updateValueAndValidity();
    this.formReceipt.get('saleFrontBillingCompanyName').clearValidators();
    this.formReceipt.get('saleFrontBillingCompanyName').updateValueAndValidity();
    this.formReceipt.get('note').clearValidators();
    this.formReceipt.get('note').updateValueAndValidity();

  }
  focusErrorField(): void {
    setTimeout(() => {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
        '.harErrorBorder'
      );
      if (firstInvalidControl) {
        firstInvalidControl.focus();
      }
    });
  }
  saveReceipt(statusDelivery?: number): void {
    this.submittedFormDelivery = true;
    this.resetValidate();
    if (statusDelivery === 1) {
      this.addValidateForm();
      if (this.formReceipt.invalid) {
        const rawContent = [];
        const fields = [
          'customerCompanyName',
          'endTime',
          'delivererUserId',
          'vehicleCode',
          'saleFrontCompanyName',
          'nonDeliveryReasonId',
          'tankPercentageBefore',
          'note'
        ];
        fields.map(field => {
          if (this.form(field).errors) {
            rawContent.push(this.messError[field]);
          }
        });
        if (rawContent.length > 0) {
          const content = rawContent.join('\n');
          this.confirmPopup.notify('COMMON_DIALOG.TT0003', content, 'BTN.OK', () => {
            this.focusErrorField();
            return;
          });
        }
      }
    }
    this.formReceipt.get('deliveryStatus').setValue(statusDelivery);
    this.confirmPopup.confirm('COMMON_DIALOG.TT0001', statusDelivery === 0 ? 'MESS.AM0402' : 'MESS.CD0001',
      statusDelivery === 0 ? 'BTN.DRAFT' : 'BTN.OK', 'BTN.CANCEL', () => {
        const body = this.formReceipt.value;
        body.systemOwnerId = this.form('systemOwnerId').value ?
          this.form('systemOwnerId').value : this.currenUserLogin.systemOwner;
        body.deliveryDate = this.form('deliveryDateDay').value +
          '-' + this.form('deliveryMonth').value + '-' + this.form('deliveryYear').value;
        body.startTime = null;
        body.endTime = body.deliveryDate + ' ' + body.endTime;
        body.customerSignature = body.customerCompanyName;
        if (this.id) {
          this.loadingService.showAppLoading();
          this.receiptService.updateNonDeliveryReport(this.id, body).subscribe((data) => {
            this.disableEditFormDelivery = true;
            this.loadingService.hideAppLoading();
            this.formReceipt.markAsPristine();
          }, (error: { success: boolean, message: string }) => {
            this.loadingService.hideAppLoading();
            this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
          }, () => {
            this.globalData.setStatusDataEdit(false);
            this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.CD0094', 'BTN.OK', () => {
              this.getDataNonDeliveryReport(this.id);
            });
          });
        } else {
          let deliveryId = null;
          this.loadingService.showAppLoading();
          this.receiptService.createNonDeliveryReport(body).subscribe((data) => {
            deliveryId = data.deliveryId;
            this.globalData.setListIdReceipt([{ deliveryId: data.deliveryId, type: true }]);
            this.formReceipt.markAsPristine();
            this.disableEditFormDelivery = true;
            this.loadingService.hideAppLoading();
          }, (error: { success: boolean, message: string }) => {
            this.loadingService.hideAppLoading();
            this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
          }, () => {
            this.globalData.setStatusDataEdit(false);
            this.confirmPopup.notify('COMMON_DIALOG.TT0002', statusDelivery === 0 ? 'MESS.CD0057' : 'MESS.CD0050', 'BTN.OK', () => {
              this.router.navigateByUrl('detail/deliveries-form/' + deliveryId).then();
            });
          });
        }
      });
  }
  form(control: string): AbstractControl {
    return this.formReceipt.get(control);
  }
  convertDecimal(parameter): void {
    const formName: string = parameter.target.attributes.formcontrolname.nodeValue;
    const regExp = new RegExp(/^[0-9０-９]+$/);
    let ckeckNumber = regExp.test(parameter.target.value);
    if (parameter.target.value === '') {
      ckeckNumber = true;
    }
    if (ckeckNumber === false) {
      this.form(formName).setValue('', { emitEvent: false });
    } else {
      const value = this.form(formName).value.replace(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0))
        .replace(/\u3000/g, '\u0020').replaceAll('.', '');
      const reg = new RegExp(/^[0-9]+$/);
      const check = reg.test(value);
      if (check) {
        this.form(formName).setValue(value, { emitEvent: false });
      } else {
        this.form(formName).setValue("", { emitEvent: false });
      }
    }
  }
  getValueCompany(data): void {
    if (!data) {
      this.formReceipt.get('customerCompanyId').setValue('', { emitEvent: false });
      this.formReceipt.get('customerCompanyCode').setValue('', { emitEvent: false });
      this.formReceipt.get('customerCompanyName').setValue('', { emitEvent: false });
      return;
    }
    this.companyCode = data.companyCode;
    this.companyCodeText.setValue(data.companyCode, { emitEvent: false });
    this.companyNameText.setValue(data.companyName, { emitEvent: false });
    this.formReceipt.get('customerCompanyId').setValue(data.companyId, { emitEvent: false });
    this.formReceipt.get('customerCompanyCode').setValue(data.companyCode, { emitEvent: false });
    this.formReceipt.get('customerCompanyName').setValue(data.companyName, { emitEvent: false });
    if (this.customerCompanyId === data.companyId) {
      this.formReceipt.get('delivererUserId').setValue(this.delivererUserId);
      this.formReceipt.get('vehicleCode').setValue(this.form('vehicleCode').value);
      this.getCompanyByCode(data.companyCode, 'checkCompanyId');
    } else {
      this.getCompanyByCode(data.companyCode);
    }
    this.formReceipt.markAsDirty();
  }
  getCompanyByCode(companyCode?, type?): void {
    this.listDeliveryUser = [];
    if (type === 'checkCompanyId') {
      this.receiptService.getCompanyByCode(companyCode).subscribe((res) => {
        if (!res) {
          return;
        }
        this.form('saleFrontCompanyId').setValue(res.saleFrontCompanyId);
        this.form('saleFrontCompanyCode').setValue(res.saleFrontCompanyCode);
        this.form('saleFrontCompanyName').setValue(res.saleFrontCompanyName);
        this.form('customerCompanyBranchName').setValue(res.customerCompanyBranchName);
        this.form('saleBillingCompanyName').setValue(res.saleBillingCompanyName);
        if (this.id) {
          this.listDeliveryUser = res.userDeliveryList?.filter(elm => elm.deliveryCompanyId === this.deliveryCompanyId);
          this.getUserByDeliveryId(this.userId)
        } else {
          this.listDeliveryUser = res.userDeliveryList ? res.userDeliveryList : [];
        }
      });
    } else {
      this.receiptService.getCompanyByCode(companyCode).subscribe((res) => {
        if (!res) {
          return;
        }
        this.form('saleFrontCompanyId').setValue(res.saleFrontCompanyId);
        this.form('saleFrontCompanyCode').setValue(res.saleFrontCompanyCode);
        this.form('saleFrontCompanyName').setValue(res.saleFrontCompanyName);
        this.form('customerCompanyBranchName').setValue(res.customerCompanyBranchName);
        this.form('saleBillingCompanyName').setValue(res.saleBillingCompanyName);
        this.listDeliveryUser = res.userDeliveryList?.filter(elm => elm.deliveryCompanyId === this.deliveryCompanyId);

        if (this.id) {
          if (companyCode != this.customerCompanyCode) {
            this.listDeliveryUser = res.userDeliveryList;
            const deliveryCompanyId = this.listDeliveryUser?.find(x => x.deliveryCompanyId === this.deliveryCompanyId);
            if (deliveryCompanyId?.deliveryCompanyId === this.deliveryCompanyId) {
              this.getUserByDeliveryId(this.formReceipt.get('delivererUserId').value)
              this.form('delivererUserName').setValue(this.formReceipt.get('delivererUserName').value);
              this.form('deliveryCompanyId').setValue(this.formReceipt.get('deliveryCompanyId').value);
              this.form('deliveryCompanyName').setValue(this.formReceipt.get('deliveryCompanyName').value);
              this.form('deliveryOfficialName').setValue(this.formReceipt.get('deliveryOfficialName').value);
              this.form('deliveryOfficeName').setValue(this.formReceipt.get('deliveryOfficeName').value)
            } else {
              this.getUserByDeliveryId(this.listDeliveryUser[0].userId, true)
              this.form('delivererUserId').setValue(this.listDeliveryUser[0].userId);
              this.form('delivererUserName').setValue(this.listDeliveryUser[0].userName);
              this.form('deliveryCompanyId').setValue(this.listDeliveryUser[0].deliveryCompanyId);
              this.form('deliveryCompanyName').setValue(this.listDeliveryUser[0].deliveryCompanyName);
              this.form('deliveryOfficialName').setValue(this.listDeliveryUser[0].deliveryOfficialName);
              this.form('deliveryOfficeName').setValue(this.listDeliveryUser[0].deliveryOfficeName)
            }
          } else {
            this.listDeliveryUser = res.userDeliveryList?.filter(elm => elm.deliveryCompanyId === this.deliveryCompanyId);
            this.formReceipt.get('delivererUserId').setValue(this.delivererUserId);
            this.formReceipt.get('vehicleCode').setValue(this.vehicleCode);
          }

        } else {
          this.listDeliveryUser = res.userDeliveryList;
          if (res.userDeliveryList != null) {
            if (this.authService.isDelivery) {
              this.getUserByDeliveryId(this.listDeliveryUser[0].userId, true);
              for (const item of this.listDeliveryUser) {
                if (item.userId === parseInt(this.form('delivererUserId').value, 10)) {
                  this.form('deliveryCompanyId').setValue(item.deliveryCompanyId);
                  this.form('deliveryCompanyName').setValue(item.deliveryCompanyName);
                  this.form('deliveryOfficialName').setValue(item.deliveryOfficialName);
                  this.form('deliveryOfficeName').setValue(item.deliveryOfficeName);
                }
              }
            } else {
              this.form('delivererUserId').setValue(this.listDeliveryUser[0].userId);
              this.form('delivererUserName').setValue(this.listDeliveryUser[0].userName);
              this.form('deliveryCompanyId').setValue(this.listDeliveryUser[0].deliveryCompanyId);
              this.form('deliveryCompanyName').setValue(this.listDeliveryUser[0].deliveryCompanyName);
              this.form('deliveryOfficialName').setValue(this.listDeliveryUser[0].deliveryOfficialName);
              this.form('deliveryOfficeName').setValue(this.listDeliveryUser[0].deliveryOfficeName)
            }
            if (!this.authService.isDelivery) {
              this.getUserByDeliveryId(this.listDeliveryUser[0].userId, true);
            } else {
              this.getUserByDeliveryId(this.authService.currentUserValue.userId);
            }
          } else {
            if (this.authService.isDelivery) {
              this.getUserByDeliveryId(this.listDeliveryUser[0].userId, true);
              for (const item of this.listDeliveryUser) {
                if (item.userId === parseInt(this.form('delivererUserId').value, 10)) {
                  this.form('deliveryCompanyId').setValue('');
                  this.form('deliveryCompanyName').setValue('');
                  this.form('deliveryOfficialName').setValue('');
                  this.form('deliveryOfficeName').setValue('');
                }
              }
            } else {
              this.form('delivererUserName').setValue('');
              this.form('delivererUserId').setValue('');
              this.form('deliveryCompanyId').setValue('');
              this.form('deliveryCompanyName').setValue('');
              this.form('deliveryOfficialName').setValue('');
              this.form('deliveryOfficeName').setValue('');
            }
            if (!this.authService.isDelivery) {
              this.getUserByDeliveryId('');
            } else {
              this.getUserByDeliveryId('');
            }
          }
        }


      });
    }
  }
  getCompanyByCodeUpdateType(companyCode?): void {
    if (this.id) {
      this.listDeliveryUser = [];
      this.receiptService.getCompanyByCode(companyCode).subscribe((res) => {
        this.listDeliveryUser = res?.userDeliveryList?.filter(elm => elm.deliveryCompanyId === this.deliveryCompanyId);
        if (this.listDeliveryUser) {
          for (let item of this.listDeliveryUser) {
            if (this.delivererUserId == item?.userId) {
              this.checkUserName = true;
              break;
            } else {
              this.checkUserName = false;
            }
          }
        }
        if (!res) {
          return;
        }
      });
    }
  }

  resetForm(): void {
    this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'MESS.CD0002', 'BTN.OK', 'BTN.CANCEL', () => {
      this.resetValidate();
      this.globalData.setStatusDataEdit(false);
      if (this.id) {
        this.buildForm(this.oldValueForm);
        this.companyNameText.setValue(this.oldValueForm.customerCompanyName);
        this.companyCodeText.setValue(this.oldValueForm.customerCompanyCode);
        this.formReceipt.get('delivererUserName').setValue(this.oldValueForm.delivererUserName);
        if (this.oldValueForm.deliveryDate) {
          this.getDayOfWeek(this.oldValueForm.deliveryDate);
        }
        this.endTimeData = this.oldValueForm.endTime ? this.formatTimeGetServe(this.oldValueForm.endTime) : this.endTimeData;

        this.updateTime(this.endTimeData, 'endTime',);
        this.updateNowTime();
      } else {
        this.formReceipt.reset();
        this.updateTime(this.endTimeData, 'endTime',);
        this.companyCodeText.setValue('', { emitEvent: false, onlySelf: true });
        this.companyNameText.setValue('', { emitEvent: false, onlySelf: true });
        const date = {
          day: moment().date(),
          month: moment().month() + 1,
          year: moment().year()
        };
        this.updateDate(date);
        this.form('nonDeliveryReasonId').setValue(this.listReason[0].reasonInt)

      }
      this.listVehicle = [];
      this.getUserByDeliveryId(this.userId, false);
      this.getCompanyByCode(this.customerCompanyCode, 'checkCompanyId');
      if (this.authService.isDelivery && !this.id) {
        this.formReceipt.get('delivererUserName').setValue(this.currenUserLogin.name);
        this.formReceipt.get('delivererUserId').setValue(this.currenUserLogin.userId);

      } else if (this.authService.isDelivery && this.id) {
        this.form('delivererUserName').setValue(this.delivererUserName);
      } else {
        this.form('delivererUserName').setValue(this.delivererUserName);
        this.form('delivererUserId').setValue(this.userId);
      }
      this.vehicleCode = this.vehicle;
      this.delivererUserId = this.userId;
      this.form('vehicleCode').setValue(this.vehicleCode);
    });
  }

  getUserByDeliveryId(id: any, Check?: boolean): void {
    this.listVehicle = [];
    if (id) {
      this.formReceipt.get('vehicleCode').setValue('');
      this.receiptService.getUserByDeliveryId(id).subscribe((res) => {
        this.listVehicle = res.vehicleList;
        if (Check === true && !this.id) {
          this.formReceipt.get('vehicleCode').setValue(this.listVehicle[0]?.vehicleCode);
        }
        if (!Check && this.id) {
          this.formReceipt.get('vehicleCode').setValue(this.vehicle);
        }
        const vehicle = this.listVehicle.filter(x => x.vehicleCode === this.vehicleCode);
        if (vehicle.length >= 1 && this.id && Check != false) {
          this.formReceipt.get('vehicleCode').setValue(this.vehicleCode);
        } else if (vehicle.length === 0 && this.id && !this.suggestDelivery) {
          this.vehicleCode = this.listVehicle[0]?.vehicleCode;
          this.formReceipt.get('vehicleCode').setValue(this.listVehicle[0]?.vehicleCode);
        }
        if (this.suggestDelivery && vehicle.length === 0 && this.id) {
          this.formReceipt.get('vehicleCode').setValue(this.vehicleCode);
        }
        if (this.listVehicle.length === 0) {
          this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.ER0204');
          return;
        }
        if (!this.id) {
          if ((this.listDeliveryUser.length === 0 || this.listDeliveryUser === null) && this.listVehicle.length === 0) {
            const content = [this.textErrDeliveryUser, this.textErrVehicle].join('\n')
            this.confirmPopup.notify('COMMON_DIALOG.TT0003', content);
            return;
          }
          if (this.listDeliveryUser.length === 0 || this.listDeliveryUser === null) {
            this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.ER0203');
            return;
          }
        }

      });
    } else {
      this.formReceipt.get('vehicleCode').setValue('');
    }

  }

  updateDataVehicleUser(): void {
    this.delivererUserId = this.form('delivererUserId').value;
    this.getUserByDeliveryId(this.delivererUserId, true);
    for (const item of this.listDeliveryUser) {
      if (parseInt(this.delivererUserId, 10) === item.userId) {
        this.form('delivererUserName').setValue(item.userName);
        this.form('deliveryCompanyId').setValue(item.deliveryCompanyId);
        this.form('deliveryCompanyName').setValue(item.deliveryCompanyName);
        this.form('deliveryOfficialName').setValue(item.deliveryOfficialName);
        this.form('deliveryOfficeName').setValue(item?.deliveryOfficeName);
        break;
      }
    }
  }

  updateDataVehicle(): void {
    this.vehicleCode = this.form('vehicleCode').value;
  }

  actionUpdateDate(data): void {
    this.updateDate(data);
    this.formReceipt.markAsDirty();
  }

  updateDate(data): void {
    let month = '';
    let day = '';

    if (data.month < 10) {
      month = '0' + data.month;
    }
    if (data.day < 10) {
      day = '0' + data.day;
    }
    this.formReceipt.get('deliveryYear').setValue(data.year);
    this.formReceipt.get('deliveryMonth').setValue(month ? month : data.month);
    this.formReceipt.get('deliveryDateDay').setValue(day ? day : data.day);
    this.formReceipt.get('deliveryDate')
      .setValue(this.form('deliveryYear').value + '-' + this.form('deliveryMonth').value + '-' + this.form('deliveryDateDay').value);
    this.getDayOfWeek(this.formReceipt.get('deliveryDate').value);
    // this.formReceipt.markAsDirty();
  }

  checkStatusBtn(data?): void {
    const receiptStatus = this.dataDetail.deliveryStatus;
    const declaredAt = this.formReceipt.get('declaredAt').value !== null;
    const declaredByUserId = this.formReceipt.get('declaredByUserId').value !== null;
    const acceptedAt = this.formReceipt.get('acceptedAt').value !== null;
    const acceptedByUserId = this.formReceipt.get('acceptedByUserId').value !== null;
    const confirmationWebAt = data?.confirmationWebAt;
    const confirmationWebByUserId = data?.confirmationWebByUserId;

    this.statusBtn = {
      editBtn: false,
      saveDraftBtn: false,
      discardChangeBtn: false,
      acceptBtn: false,
      approveBtn: false,
    };

    if (this.authService.isProvider) {
      this.statusBtn.editBtn = true;
      this.statusBtn.saveDraftBtn = true;
      this.statusBtn.discardChangeBtn = true;
    }

    // #1: Status: Temporary
    if (receiptStatus === this.statusReceipt.TEMPORARY) {
      if (this.authService.isDelivery) {
        this.statusBtn.editBtn = true;
        this.statusBtn.saveDraftBtn = true;
        this.statusBtn.discardChangeBtn = true;
      }
    }

    if (receiptStatus === this.statusReceipt.REGISTERED && !acceptedByUserId && !acceptedAt
      && !confirmationWebAt && !confirmationWebByUserId && !declaredAt && !declaredByUserId) {
      if (this.authService.isDelivery) {
        this.statusBtn.editBtn = true;
        this.statusBtn.acceptBtn = true;
        this.statusBtn.discardChangeBtn = true;
      } else if (this.authService.isProvider) {
        this.statusBtn.editBtn = true;
        this.statusBtn.acceptBtn = true;
        this.statusBtn.discardChangeBtn = true;
        this.statusBtn.approveBtn = true;
      }
    } else {
      if (receiptStatus === this.statusReceipt.REGISTERED && !acceptedByUserId && !acceptedAt) {
        if (this.authService.isProvider) {
          this.statusBtn.approveBtn = true;
        }
      }

      if (receiptStatus === this.statusReceipt.REGISTERED &&
        !declaredAt && !declaredByUserId) {
        if (this.authService.isProvider || this.authService.isDelivery) {
          this.statusBtn.acceptBtn = true;
        }
      }
    }
  }

  checkStatusForm(receiptStatus): void {
    const declaredAt = this.formReceipt.get('declaredAt').value !== null;
    const declaredByUserId = this.formReceipt.get('declaredByUserId').value !== null;
    const acceptedAt = this.formReceipt.get('acceptedAt').value !== null;
    const acceptedByUserId = this.formReceipt.get('acceptedByUserId').value !== null;
    const isAcceptanceRequired = this.formReceipt.get('isAcceptanceRequired').value;

    this.formDisable = true;

    if (receiptStatus === this.statusReceipt.TEMPORARY) {
      if ((this.authService.isProvider && this.checkCanEdit && this.checkEditOtherProvider) || this.authService.isDelivery) {
        this.formDisable = false;
      }
    } else if (isAcceptanceRequired && receiptStatus === this.statusReceipt.REGISTERED
      && !declaredAt && !declaredByUserId && !acceptedAt && !acceptedByUserId) {
      if ((this.authService.isProvider && this.checkCanEdit && this.checkEditOtherProvider) || this.authService.isDelivery) {
        this.formDisable = false;
      }
    } else if (!isAcceptanceRequired && receiptStatus === this.statusReceipt.REGISTERED
      && !declaredAt && !declaredByUserId && acceptedAt && acceptedByUserId) {
      if ((this.authService.isProvider && this.checkCanEdit && this.checkEditOtherProvider) || this.authService.isDelivery) {
        this.formDisable = false;
      }
    } else {
      if (this.authService.isProvider && this.checkCanEdit && this.checkEditOtherProvider) {
        this.formDisable = false;
      }
    }
  }

  doConfirmDelivery(): void {
    this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'MESS.MS0416', 'BTN.OK', 'BTN.CANCEL', () => {
      const body = { deliveries: [this.id], receiptNumber: [this.receiptNumber] };
      this.receiptService.confirmDelivery(body).subscribe(() => {

      }, (error: { success: boolean, message: string }) => {
        this.loadingService.hideAppLoading();
        this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
      }, () => {
        this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.CD0055', 'BTN.OK', () => {
          this.modeEdit = true;
          this.getDataNonDeliveryReport(this.id);
          this.getMinMaxReceipt(this.id);
        });
      });
    });
  }

  doAcceptDelivery(): void {
    this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'MESS.MS0410', 'BTN.OK', 'BTN.CANCEL', () => {
      const body = { deliveries: [this.id], receiptNumber: [this.receiptNumber] };
      this.receiptService.acceptDelivery(body).subscribe(() => {
      }, (error: { success: boolean, message: string }) => {
        this.loadingService.hideAppLoading();
        this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
      }, () => {
        this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'MESS.CD0059', 'BTN.OK', () => {
          this.modeEdit = true;
          this.getDataNonDeliveryReport(this.id);
          this.getMinMaxReceipt(this.id);
        });
      });
    });
  }

  print(): void {
    this.dataForPrint = this.formReceipt.value;
    this.dataPrint = [this.dataForPrint];
    if (this.formReceipt.value.deliveryDate) {
      this.formReceipt.value.deliveryDate = this.formReceipt.value.deliveryYear + '年' +
        this.formReceipt.value.deliveryMonth + '月' + this.formReceipt.value.deliveryDateDay + '日 ' + '(' + this.dayOfWeek + ')';
    }
    if (this.dataForPrint.customerSignature) {
      this.dataForPrint.customerSignature = this.labelCompanyName + this.dataForPrint.customerSignature;
    }
    this.isPrintList = true;
    this.modalService.dismissAll();
  }

  printDoneList(): void {
    this.isPrintList = false;
  }

  getReasonDeliveryReport(): void {
    const body = {
      systemOwnerId: this.authService.currentUserValue.systemOwner
    };
    this.receiptService.getReasonNondelivery(body).subscribe((res) => {
      this.listReason = res.reasonList;
      if (!this.id) {
        this.formReceipt.get('nonDeliveryReasonId').setValue(this.listReason[0].reasonInt);
      }
    }, (error: { success: boolean, message: string }) => {
      this.loadingService.hideAppLoading();
      this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
    });
  }

  changeIndexReceipt(index): void {
    if (this.formReceipt.dirty) {
      this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'MESS.CD0005', 'BTN.OK', 'BTN.CANCEL', () => {
        this.globalData.setStatusDataEdit(false);
        this.isClickNone = true;
        const listIndex = JSON.parse(localStorage.getItem('listIdReceipt'));
        let rawIndex = 0;
        switch (index) {
          case 1:
            rawIndex = 0;
            this.index = 1;
            break;
          case 2:
            rawIndex = this.index - 2;
            break;
          case 3:
            rawIndex = this.index;
            break;
          case 4:
            rawIndex = this.maxLength - 1;
            this.index = this.maxLength;
            break;
        }
        this.id = listIndex[rawIndex].deliveryId;
        this.router.navigateByUrl('detail/deliveries-form/' + listIndex[rawIndex].deliveryId).then();
        if (listIndex[rawIndex].type) {
          this.getDataNonDeliveryReport(listIndex[rawIndex].deliveryId);
        }
        this.getMinMaxReceipt(listIndex[rawIndex].deliveryId);
        this.newItemEvent.emit(listIndex[rawIndex].deliveryId);
      }, () => {
      });
    } else {
      this.isClickNone = true;
      const listIndex = JSON.parse(localStorage.getItem('listIdReceipt'));
      let rawIndex = 0;
      switch (index) {
        case 1:
          rawIndex = 0;
          this.index = 1;
          break;
        case 2:
          rawIndex = this.index - 2;
          break;
        case 3:
          rawIndex = this.index;
          break;
        case 4:
          rawIndex = this.maxLength - 1;
          this.index = this.maxLength;
          break;
      }
      this.id = listIndex[rawIndex].deliveryId;
      this.router.navigateByUrl('detail/deliveries-form/' + listIndex[rawIndex].deliveryId).then();
      // if (listIndex[rawIndex].type) {
      if (listIndex[rawIndex].isCombinada !== 2 && listIndex[rawIndex].type) {
        this.getDataNonDeliveryReport(listIndex[rawIndex].deliveryId);
      }
      this.getMinMaxReceipt(listIndex[rawIndex].deliveryId);
      this.newItemEvent.emit(listIndex[rawIndex].deliveryId);
    }
  }

  getMinMaxReceipt(id?): void {
    this.maxLength = this.globalData.getListIdReceipt()?.length;
    this.index = this.globalData.getIndexReceiptInList(parseInt(id ? id : this?.id, 10)) + 1;
  }

  validatePressure(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        if (parseInt(control.value, 10) > 100 || parseInt(control.value, 10) < 0) {
          return { rangePressure: true };
        }
      } else {
        return null;
      }
    };
  }
  ngAfterViewInit(): void {
    // tslint:disable-next-line:no-unused-expression
    (!this.id) && this.onListenFormChange();
  }

  onListenFormChange(): void {
    this.formReceipt.valueChanges.subscribe(() => {
      if (this.formReceipt.dirty) {
        this.globalData.setStatusDataEdit(true);
      }
    });
  }

  onBlurTime(formControl): void {
    let value = this.formReceipt.get(formControl).value.replaceAll(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0))
      .replaceAll(/\u3000/g, '\u0020');
    // let value = this.formReceipt.get(formControl).value;
    // value = value.split(':').join('');
    const regex = new RegExp(/^[0-9]*$/);
    if (regex.test(value)) {
      const length = value.length;
      if (length === 3) {
        if (value.includes(':')){
          this.formReceipt.get(formControl).setValue('');
          return;
        }
        const h = value.substring(0, 1);
        const m = value.substring(1, 3);
        if (m > 59){
          this.formReceipt.get(formControl).setValue('');
        } else {
          const time = '0' + h + ':' + m;
          this.formReceipt.get(formControl).setValue(time);
        }
      } else if (length === 1) {
        if (value.includes(':')){
          this.formReceipt.get(formControl).setValue('');
          return;
        }
        this.formReceipt.get(formControl).setValue('00:0' + value);
      } else if (length === 2) {
        if (value.includes(':')){
          this.formReceipt.get(formControl).setValue('');
          return;
        }
        if (value > 59) {
          this.formReceipt.get(formControl).setValue('');
        } else {
          this.formReceipt.get(formControl).setValue('00:' + value);
        }
      } else if (length === 4) {
        if (value.includes(':')){
          this.formReceipt.get(formControl).setValue('');
          return;
        }
        const h = value.substring(0, 2);
        const m = value.substring(2, 4);
        if (h > 23 || m > 59){
          this.formReceipt.get(formControl).setValue('');
        } else {
          this.formReceipt.get(formControl).setValue(h + ':' + m);
        }
      }
    } else {
      this.formReceipt.get(formControl).setValue('');
    }
  }

  onConfirmWeb(): void {
    this.confirmPopup.confirm('COMMON_DIALOG.TT0001', 'WEB_CONFIRM.MS0001', 'BTN.OK', 'BTN.CANCEL', () => {
      const body = {deliveries : [this.id]};
      this.receiptService.deliveryHistoryConfirmWeb(body).subscribe(() => {
      }, (error: { success: boolean, message: string }) => {
        this.loadingService.hideAppLoading();
        this.confirmPopup.notify('COMMON_DIALOG.TT0003', 'MESS.' + error.message);
      }, () => {
        this.confirmPopup.notify('COMMON_DIALOG.TT0002', 'WEB_CONFIRM.MS0002', 'BTN.OK', () => {
          this.modeEdit = true;
          this.getMinMaxReceipt();
          this.getDataNonDeliveryReport(this.id);
        });
      });
    });
  }

  onFocus(control): void {
    const value = this.formReceipt.get(control).value.split(':').join('');
    this.formReceipt.get(control).setValue(value);
  }

  isCheckConfirmWeb(): boolean {
    return !(this.authService.isCustomer && !this.authService.isApprovalDelivery);
  }
}
