import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { URL_IMG } from 'src/app/configs/const';

@Component({
  selector: 'app-tempalte-print-non-delivery-report',
  templateUrl: './tempalte-print-non-delivery-report.component.html',
  styleUrls: ['./tempalte-print-non-delivery-report.component.scss']
})
export class TempaltePrintNonDeliveryReportComponent implements OnInit, AfterViewInit {
  labelCompanyName: any;
  dayOfWeek: any;
  nondeliveryTrueArray;
  dataNondeliveryTrue;
  imageURL;
  urlImgOn = URL_IMG;
  date;
  endtime
  dataTitle;
  delivererUserName;
  @Input() dataInput: any;
  @Input() deliveryday: any;
  @Output() printDone = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  showDate(item): string {
    return item.deliveryDate.slice(0, 4) + '年' +
      item.deliveryDateMonth + '月' + item.deliveryDateDay + '日    ' + item.endTime.slice(10, 13) + '       ' + moment(item.endTime).format('HH:mm');
  }

  ngAfterViewInit(): void {
    window.print();
  }

  @HostListener('window:afterprint', ['$event.target'])
  afterprint(btn): void {
    setTimeout(() => {
      this.printDone.emit();
    }, 50);
  }

}
