import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseApi} from './base.api';

@Injectable({
  providedIn: 'root'
})
export class DeliveryCompanyService extends BaseApi {
  prefixURL = '';

  public getProviderBranches(): Observable<any> {
    return this.get('company/provider');
  }

  public getListDeliveryCompany(body?: any): Observable<any> {
    return this.post('company/delivery/list', body);
  }

  public getValidateDeliveryProvider(body?: any): Observable<any> {
    return this.post('validate/delivery/provider', body);
  }

  public deleteMultiCompany(body?: any): Observable<any> {
    return this.patch('company/deleteMany', body);
  }

  public uploadFile(file: File, companyType? ): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.post('import/company/delivery', formData, {companyType});
  }

  public downLoadFileDeliveries(body): Observable<any> {
    return this.download('export/deliveryCompanies', body);
  }

  public getCompanyDeliveryDetail(id: string): Observable<any> {
    return this.get('company/delivery/' + id);
  }

  public saveDeliveryCompany(id?: string, body?: any): Observable<any> {
    return id ? this.put('company/delivery/' + id, body)
      : this.post('company/delivery', body);
  }
}
