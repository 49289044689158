import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApi } from './base.api';

@Injectable({
  providedIn: 'root'
})
export class ReceiptApiService extends BaseApi {
  prefixURL = '';

  public getReceipt(id?): Observable<any> {
    return this.get(`delivery/receipt/${id}`);
  }

  public getValidateDeliveries(id?): Observable<any> {
    return this.get(`validate/deliveries/`, { deliveryId: id });
  }

  public createReceipt(body?): Observable<any> {
    return this.post('delivery/receipt', body);
  }

  public updateReceipt(id?: string, body?: object): Observable<any> {
    return this.put('delivery/receipt/' + id, body);
  }


  public uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.post('import/product', formData);
  }

  public downLoadFile(body): Observable<any> {
    return this.download('export/products', body);
  }

  public getCompanyByCode(code): Observable<any> {
    return this.get('company/customer/getByCode', { companyCode: code });
  }

  public getUserByDeliveryId(id): Observable<any> {
    return this.get(`delivery/vehicle/list/${id}`);
  }


  public uploadImage(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.post('upload', formData);
  }

  public confirmDelivery(idList?): Observable<any> {
    return this.put('delivery/declare', idList);
  }

  public acceptDelivery(idList?): Observable<any> {
    return this.put('delivery/accept', idList);
  }

  // non delivery report
  public getReasonNondelivery(body): Observable<any> {
    return this.post('delivery/nonDeliveryReport/reasonName', body);
  }

  public getNonDeliveryReport(id?): Observable<any> {
    return this.get(`delivery/nonDeliveryReport/${id}`);
  }

  public createNonDeliveryReport(body?): Observable<any> {
    return this.post('delivery/nonDeliveryReport', body);
  }

  public updateNonDeliveryReport(id?: string, body?: object): Observable<any> {
    return this.put('delivery/nonDeliveryReport/' + id, body);
  }

  public deliveryHistoryConfirmWeb(body): Observable<any> {
    return this.put('delivery/confirmation-web', body);
  }

  public checkUnique(body): Observable<any> {
    return this.post('delivery/receipt/warning', body);
  }
}
