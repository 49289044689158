<div class="receipt-form page-break" *ngFor="let item of  dataInput">
  <div class="row justify-content-center" style="overflow: hidden;">
    <div class="bg-light m-4 p-3">
      <div class="mx-0 my-2" style="margin-top:-30px !important  ;">
        <h2 class="header">未納入報告書</h2>
      </div>
      <div class="row mx-0 align-items-center mt-2">
        <div class="form-item">
          <div class="col-11 text-center">
            <span class="  " style="font-size: 18px;
            display: block;">{{item.customerCompanyName}}</span>
          </div>
          <div class="col-1 text-end">
            <span class="fontsize"> 殿</span>
          </div>
        </div>
        <div style="text-align: right; padding-right: 16px;font-size: 12px;" class=" p-0">
          <span style="  font-size: 12px;">コード &nbsp;&nbsp; &nbsp;&nbsp;{{item?.customerCompanyCode}}</span>
        </div>
      </div>
      <div class="row mx-0 align-items-center m-0">
        <div class="col-10 p-0">
          <div class="form-item border-0">
            <span class="fontsize span" style=" width: 135px;">
              <span>訪問⽇</span>
              <span style="margin-right: 10px;">:</span>
            </span>
            <div class="input-group fontsize align-items-end " style="margin-left: 15px;" *ngIf="!deliveryday">{{
              showDate(item) }}</div>
            <div class="input-group fontsize align-items-end " style="margin-left: 15px;" *ngIf="deliveryday">{{
              deliveryday }} &nbsp;&nbsp; &nbsp;&nbsp; {{item.endTime}}
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0 mt-2">
        <div class="col-8 pl-0 ">
          <label class="col-form-label none-padding custom-input-line fontsize input-group-text">
            <span class="span" style="width:143px">
              <span class="fontsize">乗務員名</span>
              <span style="margin-right: 10px;">:</span>
            </span>
            <span class="text-center" style="width:100%" *ngIf="item?.delivererUserName">
              {{item?.delivererUserName}}
            </span>
            <span class="text-center" style="width:100%" *ngIf="item?.deliveryUserName">
              {{item?.deliveryUserName}}
            </span>
          </label>
        </div>
        <div class="col-4 pr-0">
          <label class="col-form-label none-padding fontsize input-group-text">
            <span class="span" style="width:50px;">
              <span class="fontsize"> {{'B4.VEHICLE_CODE'| translate}}</span>
              <span style="margin-right: 10px;">:</span>
            </span>
            <div class="text-center" style="width:100%; margin-right: 10px;">
              {{item?.vehicleCode}}
            </div>
          </label>
        </div>
      </div>
      <div style="height: 42px;">
        <div class="row align-items-end mx-0 mt-2" *ngIf="item.saleBillingCompanyName != '―'">
          <div class="col-10 pl-0">
            <label class="col-form-label fontsize none-padding input-group-text">
              <span class="span">
                <span class="fontsize"> {{'B4.SALE_FONT_COMPANY_NAME'|
                  translate}}</span>
                <span style="margin-right: 10px;">:</span>
              </span>
              {{item?.saleBillingCompanyName}}</label>
          </div>
        </div>
      </div>
      <div class="row mx-0 align-items-stretch mt-2">
        <div class="col-4 pl-0">
          <div class="form-item">
            <span class="span" style="width:80px">
              <span class="fontsize">タンク在庫</span>
              <span>:</span>
            </span>
            <span></span>
            <span>{{item?.tankPercentageBefore}}&nbsp;%</span>
          </div>
        </div>
      </div>
      <div class="row mx-0 align-items-stretch text-center mt-2">
        <h5 class="fontsize" style="font-size: 16px; font-weight: 600;">今回は以下の理由により未納入となりました。</h5>
      </div>
      <div class="row mx-0 align-items-stretch">
        <div class="col-3 pl-0"></div>
        <div class="col-6 pr-0 justify-content-center">
          <input type="text" class="form-control fontsize  none-padding text-center" aria-label="Username"
            style="border-bottom: 1px solid; font-size: 15px; font-weight: 500;" aria-describedby="basic-addon1"
            maxlength="24" [value]="item?.nonDeliveryReasonName">
        </div>
      </div>
      <div class="row mt-2 p-3">
        <div class="custom-textarea">
          {{item.note}}
        </div>
      </div>
      <div class="row align-items-center mx-0 mt-4">
        <div class="col-12 p-0">
          <div class="row">
            <label class="col-form-label text-start fontsize"
              *ngIf="item?.deliveryCompanyOfficialName || item?.deliveryCompanyOfficeName"
              style="padding-top: 0px !important;">{{'B4.DELIVERY_COMPANY_NAME' | translate}}
              : <span *ngIf="item?.deliveryCompanyOfficialName">{{item?.deliveryCompanyOfficialName}}</span>
              <span *ngIf="item?.deliveryCompanyOfficeName"> {{item?.deliveryCompanyOfficeName}}</span>
            </label>
            <label class="col-form-label text-start fontsize"
              *ngIf="item?.deliveryOfficialName || item?.deliveryOfficeName"
              style="padding-top: 0px !important;">{{'B4.DELIVERY_COMPANY_NAME' | translate}}
              : <span *ngIf="item?.deliveryOfficialName">{{item?.deliveryOfficialName}}</span>
              <span *ngIf="item?.deliveryOfficeName"> {{item?.deliveryOfficeName}}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row align-items-center mx-0 mt-1">
        <div class="col-5">
        </div>
        <div class="col-7 py-2 text-end">
          <label class="fontsize">伝票番号: {{' ' + item?.receiptNumber}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
