import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[appNumberOnly]'
})
export class NumberOnlyDirective {
  @Input() isInteger1 = false;
  @Input() isInteger2 = false;
  @Input() isInteger = false;
  @Input() isNumber = false;
  @Input() isTimeStamp = false;

  beforeValue: any;
  @HostListener('keypress', ['$event']) onKeyPress(event): void {


    if (this.isNumber) {
      const charCode = (event.which) ? event.which : event.keyCode;
      const key = event?.key;
      if ((!(charCode > 31 && (charCode < 48 || charCode > 57)))
      || (event.target.value  && event.target.value.indexOf('.') < 0 && charCode === 46 && !this.isInteger && !this.isInteger1)||((key === '+' || key === '-')  && !this.isInteger2) ) {
        // todo
      } else {
        event.preventDefault();
      }
    }

    if (this.isTimeStamp) {
      const charCode = (event.which) ? event.which : event.keyCode;
      if ((!(charCode > 31 && (charCode < 48 || charCode > 57)))
        || (event.target.value && charCode === 46)) {
        // todo
      } else {
        event.preventDefault();
      }
    }
  }

  // @HostListener('keyup', ['$event']) onKeyUp(event): void {
  //   if (this.isNumber) {
  //     if(event.target.value !== '' && event.target.value !== '+' && event.target.value !== '-'){
  //       const regex = new RegExp(/^[\+\-]?\d+(\.\d*)?$/g);
  //       const value = event.target.value.trim().replaceAll(',' , '').replace(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0))
  //       .replace(/\u3000/g, '\u0020');
  //       const checkNumber  = regex.test(value);
  //       if (checkNumber === false) {
  //           event.target.value = this.beforeValue;
  //       }else{
  //         this.beforeValue = event.target.value
  //       }
  //     }
  //   }
  // }

  @HostListener('paste', ['$event']) onPaste(event): void {
    if (this.isNumber) {
      const pasteData = event.clipboardData.getData('text').replace(/\,/g, '');
      const regex = new RegExp(/^[\+\-]?\d+(\.\d+)?$/g);
      if (regex.test(pasteData)) {
        // todo
      } else {
        event.preventDefault();
      }
    }
  }

  constructor() { }
}
