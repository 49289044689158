export enum UserRoles {
  DELIVERY_COMPANY = 'ROLE_DELIVERY', // 0
  SALE_COMPANY = 'ROLE_SALE', // 1
  CUSTOMER = 'ROLE_CUSTOMER', // 2
  PROVIDER_COMPANY = 'ROLE_PROVIDER', // 3
  SYSTEM_ADMIN = 'ROLE_SYSTEM_ADMIN'
}

export enum UserState {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum UserProviderRoles {
  EDIT_IMPORT_HISTORY_DETAIL = 'EDIT_DELIVERY_HISTORY_DETAIL',
  CONFIRM_DELIVERY = 'CONFIRM_DELIVERY',
  APPROVE_DELIVERY = 'APPROVE_DELIVERY',
  EDIT_CUSTOMER = 'EDIT_CUSTOMER',
  EDIT_SALE = 'EDIT_SALE',
  EDIT_DELIVERY = 'EDIT_DELIVERY',
  EDIT_PROVIDER_USER = 'EDIT_PROVIDER_USER',
  VIEW_PROVIDER_OTHER = 'VIEW_PROVIDER_OTHER',
  EDIT_PROVIDER_OTHER = 'EDIT_PROVIDER_OTHER',
  EDIT_SALE_USER = 'EDIT_SALE_USER',
  EDIT_CUSTOMER_USER = 'EDIT_CUSTOMER_USER',
  EDIT_DELIVERY_USER = 'EDIT_DELIVERY_USER',
  VIEW_PROVIDER_USER = 'VIEW_PROVIDER_USER',
  EDIT_PROVIDER_USER_NEW = 'EDIT_PROVIDER_USER_NEW',
  EDIT_PROVIDER_USER_OTHER = 'EDIT_PROVIDER_USER_OTHER',
  EDIT_VENDOR = 'EDIT_VENDOR',
  EDIT_SHIPMENT_LOCATION = 'EDIT_SHIPMENT_LOCATION',
  EDIT_GASFILLING_HISTORY = 'EDIT_GASFILLING_HISTORY',
  CONFIRM_GASFILLING = 'CONFIRM_GASFILLING'
}

export enum CompanyType {
  DELIVERY = 0,
  SALE = 1,
  CUSTOMER = 2,
  PROVIDER = 3,
  SYSTEM = 4
}

export const USER_ROLES_PROVIDER_SCREEN = ['B3'];

