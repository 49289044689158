// max size import 10mb/ 10485760 bytes
export const MAX_SIZE_IMPORT = 10485760;
export const IMPORT_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const IMPORT_TYPE_XLS = 'application/vnd.ms-excel';
export const OFFSET_PAGE = 100;
export const PAGE_NUMBER = 0;
// max size import 2mb/ 2097152 bytes
export const MAX_SIZE_IMG = 2097152;

export enum confirmationStatus {
  NONE = 0,
  NEW = 1,
  ACCEPTED = 2,
  ACCEPTED_BY_PROXY = 3
}

export enum deliveryStatus {
  TEMPORARY = 0,
  REGISTERED = 1,
  ACCEPTED = 2,
  DECLARED = 3
}

export const WEEKDAYS = [
  { text: 'すべて', value: '' },
  { text: '月', value: 1 },
  { text: '日', value: 0 },
  { text: '火', value: 2 },
  { text: '水', value: 3 },
  { text: '木', value: 4 },
  { text: '金', value: 5 },
  { text: '土', value: 6 },
];

export const WEEKDAYS_B3 = [
  { text: 'すべて', value: '' },
  { text: '日', value: 7 },
  { text: '月', value: 1 },
  { text: '火', value: 2 },
  { text: '水', value: 3 },
  { text: '木', value: 4 },
  { text: '金', value: 5 },
  { text: '土', value: 6 },
];

export const PHONE_VALIDATOR = /^(?=.*\d)[\d-]+$/;
export const PHONE1_VALIDATOR = /^[-\d]+$/;

// export const LOGIN_ID_VALIDATOR = /^[a-zA-Z0-9]([](?![])|[a-zA-Z0-9]|(?<![_.-])[_.-](?![_.-])){2,18}[a-zA-Z0-9]$/;
export const LOGIN_ID_VALIDATOR = /^[a-zA-Z0-9]([](?![])|[a-zA-Z0-9]|[_.-]){2,18}[a-zA-Z0-9]$/;
export const PASSWORD_VALIDATOR = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,15}$/;

export const PHONE_REGEX_1 = /^([0][0-9]{1})-([0-9]{4})-([0-9]{4})$/;
export const PHONE_REGEX_2 = /^([0][0-9]{2})-([0-9]{3})-([0-9]{4})$/;
export const PHONE_REGEX_3 = /^([0][0-9]{3})-([0-9]{2})-([0-9]{4})$/;
export const PHONE_REGEX_4 = /^([0][0-9]{4})-([0-9]{1})-([0-9]{4})$/;
export const PHONE_REGEX_5 = /^([0][0-9]{2})-([0-9]{4})-([0-9]{4})$/;

export const FAX_REGEX_1 = /^([0-9]{3})-([0-9]{4})-([0-9]{4})$/;
export const FAX_REGEX_2 = /^([0-9]{3})-([0-9]{3})-([0-9]{4})$/;
export const FAX_REGEX_3 = /^([0-9]{2})-([0-9]{4})-([0-9]{4})$/;

export const SPACE_REGEX = /^\S*$/;

export const SALE_COMPANY_TYPES = [
  { text: '供給窓口会社', value: 0 },
  { text: '当社請求窓口会社', value: 1 },
  { text: '納入先フロント窓口会社', value: 2 },
  { text: '情報確認窓口会社', value: 3 }
];

export enum CheckBoxType { DateThisMonth, DateLastMonth, checkDate }

export const STATUS_RECEIPT = {
  TEMPORARY: 0,
  REGISTERED: 1,
  ACCEPTED: 2,
  DECLARED: 3,
  ACCEPTED_AND_DECLARED: 4
};

export const URL_IMG = 'https://kftstolpgprd.blob.core.windows.net/image/';

export const SALE_ORDER = {
  1: 1,
  2: 2,
  3: 3
};
