import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dynamic-title-print',
  templateUrl: './dynamic-title-print.component.html',
  styleUrls: ['./dynamic-title-print.component.scss']
})
export class DynamicTitlePrintComponent implements OnInit {
  model: any;
  offPrintDetail = false;
  isPrintDetail: any;
  constructor(
    public dialogRef: MatDialogRef<DynamicTitlePrintComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public fb: FormBuilder
  ) { }
  form = this.fb.group({
    titleOne: ['受領書', [Validators.required]],
  });
  ngOnInit(): void {
  }

  printDoneList(): void {
    this.offPrintDetail = false;
  }
  submit(): void {
    this.dialogRef.close({ check: 'open', data: this.form.value });
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

}
