import {
  Component,
  ElementRef, EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, fromEvent} from 'rxjs';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {BaseApi} from '../../../services/api-service/base.api';
import {LoadingService} from '../../service/loading.service';
import {PagingConfig} from '../../../configs/paging-config';
import {TabService} from '../../service/tab-index.service';


@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSelectComponent),
      multi: true,
    },
  ],
})
export class SearchSelectComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() ngClass: string;
  @Input() placeholder = '';
  @Input() hasError: any;
  @Input() index = null;
  @Input() options: {
    apiUrl?: string,
    keyName?: string,
    keyValue?: string,
    keySearch?: string,
    keySearch2?: string,
    valueSearch2?: string,
    params?: {}
  };
  @Input() nameShow = '';
  @Output() emmitData = new EventEmitter<object>();
  @Output() checkValidate = new EventEmitter<boolean>();
  private sub;
  itemSelect: {
    name: '',
    value: ''
  };

  option = {
    apiUrl: '',
    keyName: 'name',
    keyValue: 'id',
    keySearch: 'keySearch',
    keySearch2: 'keySearch2',
    params: {},
  };
  showDrop = new BehaviorSubject(false);
  isDisable: boolean;
  searchKey = new FormControl('');
  optionList: Array<any> = [];
  searchInProgress = false;
  overlayEl = null;

  @ViewChild('optListScroll') optListScroll: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;

  private onChangeModel = (v: any) => {
  }
  protected onTouchModel = (v: any) => {
  }

  constructor(
    private el: ElementRef,
    private baseApi: BaseApi,
    private loading: LoadingService,
    private tabService: TabService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue) {
      const newOpt = changes.options.currentValue;
      Object.keys(newOpt).forEach((key) => {
        if (this.option.hasOwnProperty(key)) {
          this.option[key] = this.options[key];
        }
      });
    }
  }

  ngOnInit(): void {
    this.showDrop.subscribe(flag => {
      if (!flag) {
        if (this.sub) {
          this.optionList = [];
          this.sub.unsubscribe();
        }
      } else {
        this.callData();
      }
    });

    this.searchKey.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => {
      this.callData();
    });
  }

  /**
   * Activate loading animation
   * @param el ElementRef
   */
  private activeLoadingBox(el): void {
    if (this.overlayEl) {
      return;
    }
    this.overlayEl = this.loading.openInBox(el);

  }

  /**
   * Close loading animation
   */
  private closeLoadingBox(): void {
    if (this.overlayEl) {
      this.overlayEl.close();
    }
  }

  /**
   * Retrieve data from server
   */
  callData(): void {
    if (!this.option.apiUrl) {
      return;
    }
    this.optionList = [];
    this.activeLoadingBox(this.optListScroll);

    let params = this.options.params ? Object.assign({}, this.options.params) : {};
    params[this.option.keySearch] = this.searchKey.value;
    params['page'] = PagingConfig.FirstPage;
    this.searchInProgress = true;
    this.baseApi.get(this.option.apiUrl, params)
      .subscribe((res: any) => {
        this.optionList = res;
        this.closeLoadingBox();
      }, (err) => {
      }, () => {
        this.searchInProgress = false;
        if (this.optListScroll.nativeElement.getElementsByClassName('opt-list-scroll')[0]) {
          setTimeout(() => {
            this.optListScroll.nativeElement.getElementsByClassName('opt-list-scroll')[0].scroll({top: 0});
          }, 10);
        }
      });
  }

  /**
   * Option changed handling function
   * @param val: any
   */
  chooseOpt(val: any): void {
    this.itemSelect = {
      value: val ? val[this.option.keyValue] : '',
      name: val ? val[this.option.keyName] : ''
    };
    this.onChangeModel(val[this.option.keyValue]);
    this.emmitData.emit(val);
    this.toggleDrop();
  }

  /**
   * Toggle dropdown shown
   */
  toggleDrop(show?: boolean): void {
    if (show) {
      this.showDrop.next(true);
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 10);
    } else {
      this.tabService.selectedInput.next(this.searchInput.nativeElement.tabIndex);
      this.showDrop.next(!this.showDrop.value);
    }

    if (this.showDrop) {
      this.sub = fromEvent(document.body, 'click').subscribe((ev: MouseEvent) => {
        if (!this.el.nativeElement.contains(ev.target)) {
          this.searchKey.setValue('');
          this.tabService.selectedInput.next(this.searchInput.nativeElement.tabIndex);
          this.showDrop.next(false);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchModel = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisable = isDisabled;
  }

  writeValue(obj: any): void {
    this.itemSelect = {
      value: obj ? obj[this.option.keyValue] : '',
      name: obj ? obj[this.option.keyName] : ''
    };
  }

  checkValidateForm(): void {
    this.checkValidate.emit(true);
  }

  actionKeyDown(event): void {
    if (event.shiftKey && event.keyCode == 9 || event.keyCode == 9) {
      this.toggleDrop(false);
    }
  }
}
