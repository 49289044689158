import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

export class CustomValidators {
  static required(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (Validators.required(control)) {
        return {required: true};
      } else if (typeof control.value === 'string' && control.value.trim() === '') {
        control.setValue('');
        return {required: true};
      } else {
        return null;
      }
    };
  }

  static regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  static regexPhoneValidator(regex: RegExp, regex2: RegExp, regex3: RegExp, regex4: RegExp, regex5: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      const valid2 = regex2.test(control.value);
      const valid3 = regex3.test(control.value);
      const valid4 = regex4.test(control.value);
      const valid5 = regex5.test(control.value);
      return (valid || valid2 || valid3 || valid4 || valid5) ? null : error;
    };
  }

  static regexFaxValidator(regex: RegExp, regex2: RegExp, regex3: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      const valid2 = regex2.test(control.value);
      const valid3 = regex3.test(control.value);
      return (valid || valid2 || valid3) ? null : error;
    };
  }

  static minLength(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (typeof control.value === 'string' && control.value.trim().length < min) {
        return {min: true};
      } else {
        return null;
      }
    };
  }

  static maxLength(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (typeof control.value === 'string' && control.value.trim().length > max) {
        return {max: true};
      } else {
        return null;
      }
    };
  }

  static max(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value) {
        const value = control.value.toString().replaceAll(/[\uff01-\uff5e]/g, fullwidthChar => String.fromCharCode(fullwidthChar.charCodeAt(0) - 0xfee0))
          .replaceAll(/\u3000/g, '\u0020').replaceAll(',', '').replaceAll('-', '').replaceAll('.', '');
        if (typeof control.value === 'string' && value > max) {
          return {max: true};
        } else {
          return null;
        }
      }

      return null;
    };
  }
}
