<div class="content-form">
  <div class="mt-2">
    <app-breadcrumb [breadcrumbData]="listBreadcrumb"></app-breadcrumb>
  </div>
  <div class="header-content mt-2">
    <div class="act-vertical-buttons" [class]="(checkEditOtherProvider || checkViewOtherProvider)? '' : 'none'">
      <button [disabled]="!statusBtn.editBtn"
        class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2"
        *ngIf="(!modeEdit || !disableEditFormDelivery) && checkCanEdit"
        (click)="saveReceipt(statusReceipt.REGISTERED)">{{'BTN.REGISTRATION' | translate}}</button>
      <ng-container *ngIf="checkEditOtherProvider">
        <button class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2" (click)="activeEdit()"
          [disabled]="!statusBtn.editBtn"
          *ngIf="modeEdit && disableEditFormDelivery && checkCanEdit">{{'BTN.EDIT'|translate}}</button>
        <!-- <button [disabled]="statusBtn.saveDraftBtn" (click)="saveReceipt(statusReceipt.TEMPORARY)"
          class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2"
          *ngIf="statusBtn.saveDraftBtnHide  && checkCanEdit">{{'BTN.DRAFT'| translate}}</button> -->
        <button [disabled]="formReceipt.pristine"
          class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2" (click)="resetForm()"
          *ngIf="checkCanEdit">{{'BTN.DISCARD_CHANGE' | translate}}</button>
        <button [disabled]="!statusBtn.acceptBtn || !disableEditFormDelivery"
          class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2" (click)="doConfirmDelivery()"
          *ngIf="globalData.checkPermissionAction(permissionConfig.ACTIONS_CODE.DELIVERIES_HISTORY_CONFIRM) && !authService.isCustomer">{{'BTN.DELIVERY_CONFIRMATION'|
          translate}}</button>
        <button [disabled]="!statusBtn.approveBtn ||  !disableEditFormDelivery"
          class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2" (click)="doAcceptDelivery()"
          *ngIf="globalData.checkPermissionAction(permissionConfig.ACTIONS_CODE.DELIVERIES_HISTORY_APPROVE) && !authService.isCustomer">{{'BTN.RECEIPT_APPROVAL'|
          translate}}</button>

      </ng-container>
      <button [disabled]="isConfirmWeb" (click)="onConfirmWeb()"
              *ngIf="(authService.isSale && isConfirmWeb1) || (authService.isCustomer && isCheckConfirmWeb() && isConfirmWeb1)"
              class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2">{{'BTN.WEB_CONFIRM'|
        translate}}</button>
      <button [disabled]="!modeEdit || !disableEditFormDelivery" (click)="openModalExport(content)"
        *ngIf="checkViewOtherProvider"
        class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2">{{'BTN.PRINTING'|
        translate}}</button>
    </div>
    <div class="act-vertical-buttons" *ngIf="maxLength > 0">
      <button [disabled]="(index - 1 === 0 || !disableEditFormDelivery)"
        class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2 font-icon"
        (click)="changeIndexReceipt(1)">|<img src="../../../../assets/images/backgrounds/left.png" alt="">
      </button>
      <button [disabled]="(index - 1 === 0 || !disableEditFormDelivery)"
        class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2 font-icon"
        (click)="changeIndexReceipt(2)">
        <img src="../../../../assets/images/backgrounds/left.png" alt="">
      </button>
      <button class="button m-2"
        style="width: 234px; font-size: 1.5rem; height: 55px; font-weight: bold; border: none !important; background: transparent; cursor: default">
        {{index + ' / ' + maxLength}}</button>
      <button [disabled]="(maxLength === 1) || index === maxLength || !disableEditFormDelivery"
        (click)="changeIndexReceipt(3)"
        class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2 font-icon">
        <img src="../../../../assets/images/backgrounds/right.png" alt="">
      </button>
      <button [disabled]="(maxLength === 1) || index === maxLength || !disableEditFormDelivery"
        (click)="changeIndexReceipt(4)"
        class="button btn-radius btn-outline-mpro-primary btn-outline-mpro-primary-2 icon-hover btn-header m-2 font-icon"> <img
          src="../../../../assets/images/backgrounds/right.png" alt="">|
      </button>
    </div>
  </div>
  <div class="receipt-form mt-1" [ngClass]="{'form-disable': formDisable || disableEditFormDelivery}"
    (click)="(formDisable || disableEditFormDelivery) ? $event.preventDefault(): ''">
    <div class="row justify-content-center">
      <div class="border border-dark col-lg-7 col-md-12 col-sm-12 bg-light mb-4">
        <h2 class="mt-4 mb-2">
          <label class="header">{{'B4.RECEIPT'| translate}}</label>
        </h2>
        <form class="mx-3 my-2 mt-3" [formGroup]="formReceipt">
          <div class="mx-3 mt-2 align-items-center ">
            <div class="row">
              <div class="col-12 input-group  pl-1 fs p-0">
                <div *ngIf="suggestProvider || isSystemAdmin" class="auto-width">
                  <span class="req" *ngIf="!id || !disableEditFormDelivery">※</span>
                  <app-autocomplete [formControl]="companyNameText" [options]="{apiUrl: 'company/suggest',
                                    displayKey: {param1:'companyCode', param2:'companyName'},
                                    searchKey:'searchKey',
                                    getData: 'companyList',
                                    paramPlus: paramPlus
                                    }" [hasError]="(submittedFormDelivery && form('customerCompanyName').errors)"
                    [background]='!disableEditFormDelivery' [maxLength]="16" [id]="'management_delivery_company_code'"
                    (chooseVal)="getValueCompany($event)" [placeholder]="'B4.COMPANY_NAME'" [formReceipt]="true"
                    [disable]="(formDisable || disableEditFormDelivery)" [checkDeliveryFinish]="true">
                  </app-autocomplete>
                </div>
                <div *ngIf="suggestDelivery" class="auto-width">
                  <span class="req" *ngIf="!id || !disableEditFormDelivery">※</span>
                  <app-autocomplete [formControl]="companyNameText" [options]="
                                    {apiUrl: 'company/customer/suggest',
                                    displayKey: {param1:'companyCode', param2:'companyName'},
                                    searchKey:'searchKey',
                                    getData: 'companyList',
                                    paramPlus: paramPlusDelivery
                                    }" [hasError]="(submittedFormDelivery && form('customerCompanyName').errors)"
                                    [background]='!disableEditFormDelivery' [maxLength]="16"
                                    [id]="'management_delivery_company_code'" (chooseVal)="getValueCompany($event)"
                                    [checkDeliveryFinish]="true"
                                    [placeholder]="'B4.COMPANY_NAME'" [formReceipt]="true"
                                    [disable]="(formDisable || disableEditFormDelivery)">
                  </app-autocomplete>
                </div>
                <div *ngIf="suggestProviderT1" class="auto-width">
                  <span class="req" *ngIf="!id || !disableEditFormDelivery">※</span>
                  <app-autocomplete [formControl]="companyNameText" [options]="
                                    {apiUrl: 'company/suggest',
                                    displayKey: {param1:'companyCode', param2:'companyName'},
                                    searchKey:'searchKey',
                                    getData: 'companyList',
                                    paramPlus: paramPlus
                                    }" [hasError]="(submittedFormDelivery && form('customerCompanyName').errors)"
                    [background]='!disableEditFormDelivery' [maxLength]="16" [id]="'management_delivery_company_code'"
                    (chooseVal)="getValueCompany($event)" [placeholder]="'B4.COMPANY_NAME'" [formReceipt]="true"
                    [disable]="(formDisable || disableEditFormDelivery)" [checkDeliveryFinish]="true"></app-autocomplete>
                </div>
                <span class="none-padding margin-custom fontSize" [class]="{'text-code' : disableEditFormDelivery}"
                  style="line-height: 49px; text-align: end;">&nbsp;&nbsp;&nbsp;殿</span>
              </div>
              <div class="col-12">
                <div class="company-code fs border-code">
                  <span>コード<span class="req-1" *ngIf="!id || !disableEditFormDelivery">※</span>&nbsp;&nbsp;</span>
                  <div *ngIf="suggestProvider || isSystemAdmin">
                    <app-autocomplete [formControl]="companyCodeText" [options]="{apiUrl: 'company/suggest',
                                    displayKey: {param1:'companyCode', param2:'companyName'},
                                    searchKey:'searchKey',
                                    getData: 'companyList',
                                    paramPlus: paramPlus
                                    }" [hasError]="(submittedFormDelivery && form('customerCompanyName').errors)"
                      [background]='!disableEditFormDelivery' [maxLength]="16" [id]="'management_delivery_company_code'"
                      (chooseVal)="getValueCompany($event)" [placeholder]="'B4.COMPANY_CODE'" [formReceipt]="true"
                      [disable]="(formDisable || disableEditFormDelivery)" [checkDeliveryFinish]="true"></app-autocomplete>
                  </div>
                  <div *ngIf="suggestDelivery">
                    <app-autocomplete [formControl]="companyCodeText" [options]="
                                    {apiUrl: 'company/customer/suggest',
                                    displayKey: {param1:'companyCode', param2:'companyName'},
                                    searchKey:'searchKey',
                                    getData: 'companyList',
                                    paramPlus: paramPlusDelivery
                                    }" [hasError]="(submittedFormDelivery && form('customerCompanyName').errors)"
                      [background]='!disableEditFormDelivery' [maxLength]="16" [id]="'management_delivery_company_code'"
                      (chooseVal)="getValueCompany($event)" [placeholder]="'B4.COMPANY_CODE'" [formReceipt]="true"
                                      [checkDeliveryFinish]="true" [disable]="(formDisable || disableEditFormDelivery)">
                    </app-autocomplete>
                  </div>
                  <div *ngIf="suggestProviderT1">
                    <app-autocomplete [formControl]="companyCodeText" [options]="
                                    {apiUrl: 'company/suggest',
                                    displayKey: {param1:'companyCode', param2:'companyName'},
                                    searchKey:'searchKey',
                                    getData: 'companyList',
                                    paramPlus: paramPlus
                                    }" [hasError]="(submittedFormDelivery && form('customerCompanyName').errors)"
                      [background]='!disableEditFormDelivery' [maxLength]="16" [id]="'management_delivery_company_code'"
                      (chooseVal)="getValueCompany($event)" [placeholder]="'B4.COMPANY_CODE'" [formReceipt]="true"
                      [disable]="(formDisable || disableEditFormDelivery)" [checkDeliveryFinish]="true"></app-autocomplete>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="row align-items-center mx-3 mt-0 m-0">
            <div class="col-auto">
              <label class="col-form-label fontSize"
                style="padding-top: 10px; margin-bottom : 5px">{{'B4.DELIVERY_DATE'|
                translate}} <span class="req-1" *ngIf="!id || !disableEditFormDelivery">※</span></label>
            </div>
            <div class="col-7">
              <div class="input-group align-items-end p-0">
                <div class="d-flex"
                  [class]="{'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id }">
                  <input type="text" aria-label="First name" style="max-width: 50px" formControlName="deliveryDateYear"
                    [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors||validateUpdateTimeDiff ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id} "
                    class="form-control none-padding text-end fontSize border-none" (click)="dp1.toggle()" readonly>
                  <span class="input-group-text border-none none-padding fontSize"
                    (click)="dp1.toggle()"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors||validateUpdateTimeDiff ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id } ">{{'B4.YEAR'|
                    translate}}</span>
                  <input type="text" aria-label="Last name" style="max-width: 30px;" formControlName="deliveryDateMonth"
                    [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors||validateUpdateTimeDiff ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id} "
                    class="form-control none-padding border-none text-end fontSize" (click)="dp1.toggle()" readonly>
                  <span class="input-group-text none-padding fontSize border-none"
                     (click)="dp1.toggle()"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors||validateUpdateTimeDiff,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id} ">{{'B4.MONTH'|
                    translate}}</span>
                  <input type="text" aria-label="Last name" style="max-width: 30px; " formControlName="deliveryDateDay"
                    [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors||validateUpdateTimeDiff,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id} "
                    class="form-control none-padding text-end fontSize border-none" (click)="dp1.toggle()" readonly>
                  <span class="input-group-text none-padding fontSize border-none"
                     (click)="dp1.toggle()"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors||validateUpdateTimeDiff,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id} ">{{'B4.DAY'|
                    translate}}</span>
                  <span class="input-group-text none-padding fontSize border-none"
                        (click)="dp1.toggle()"
                    [ngClass]="{'hasError': submittedFormDelivery && form('deliveryDate').errors||validateUpdateTimeDiff,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id} ">&nbsp;&nbsp;&nbsp;{{dayOfWeek
                    ? '(' + dayOfWeek + ')' :
                    ''}}</span>
                </div>
                <input class="form-control text-center fontSize" name="dp1" ngbDatepicker formControlName="deliveryDate"
                  [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null" #dp1="ngbDatepicker" readonly
                  [maxDate]="maxDate" [minDate]="minDate"
                  style="display: none" (dateSelect)="actionUpdateDate($event)">
              </div>
            </div>
          </div>
          <div class=" align-items-center mt-2" style="margin-left: 13px !important;
          margin-right: 13px !important;">
            <div class="input-group" style="height: auto;">
              <span class="input-group-text custom-text " style="padding-bottom: 24px;"
                [ngClass]="{'harErrorBorder': submittedFormDelivery && form('productName').errors}">{{'B4.PRODUCT_NAME'|
                translate}}</span>
              <div class="form-control  fontSize custom-input custom-border">
                <input type="text" disabled class="form-control  border-none fontSize" style="height: 50px;"
                  aria-label="Username" formControlName="productName"
                  [ngClass]="{'harErrorBorder': submittedFormDelivery && form('productName').errors ,'background' :!disableEditFormDelivery}">
              </div>
              <span class="input-group-text custom-text " style="padding-bottom: 24px;"
                [ngClass]="{'harErrorBorder1': submittedFormDelivery &&  validateAmount  || validateAmountNumber}">{{'B4.AMOUNT'|
                translate}} <span class="req-1" *ngIf="!id || !disableEditFormDelivery">※</span></span>
              <div class="custom-border custom-input" style="border-right:none"
                [ngClass]="{'harErrorBorder': submittedFormDelivery &&  validateAmount ||validateAmountNumber }">
                <input type="text" class="form-control fontSize border-none text-end" *ngIf="counterType === 0"
                  aria-label="Username" (blur)="convertDecimalKg($event, 'amount')" formControlName="amount"
                  maxlength="6" disabled appNumberOnly [isNumber]="true" [isInteger1]="true" (focus)="onFocusAmount()"
                  style="max-width: 125px ; height: 50px;"
                  [class]=" counterType === 0 && !disableEditFormDelivery ? 'background' : ''">
                <input type="text" class="form-control border-none text-end fontSize"
                  *ngIf="counterType === 1 || counterType == undefined" aria-label="Username"
                  (blur)="convertDecimalKg($event, 'amount')" formControlName="amount" maxlength="6" appNumberOnly
                  [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null" [isNumber]="true"
                  style="max-width: 125px ;height: 50px;" [isInteger1]="true"
                  [isInteger2]="true" (focus)="onFocusAmount()"
                  [class]="counterType === 1 && !disableEditFormDelivery && !id ? 'background-blue' : counterType === 1 && !disableEditFormDelivery && id ?  'background-green' : '' ">
              </div>
              <span class="custom-kg margin-custom fontSize"
                [ngClass]="{'harErrorBorder': submittedFormDelivery && validateAmount || validateAmountNumber}">kg</span>
            </div>
          </div>
          <div class="row align-items-center mx-3 mt-4 m-0">
            <div class="col-7 ">
              <div class="input-group align-items-end mb-15" *ngIf="counterType === 0">
                <span class="input-group-text none-padding span fontSize" style="width:180px"
                  [ngClass]="{'hasError': submittedFormDelivery  && validateCounterFlowAmount && counterType === 0 || validateCounterFlowAmountNumber }"><span>カウンター流量</span>
                  <span style="margin-right: 10px;">:</span></span>
                <input type="text" aria-label="Last name" formControlName="counterFlowAmount"
                  [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                  (blur)="convertDecimalKg($event,'counterFlowAmount')"
                  (focus)="onFocusCounterFlowAmount()"
                  [ngClass]="{'hasError': submittedFormDelivery  && validateCounterFlowAmount && counterType === 0 || validateCounterFlowAmountNumber ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}"
                  [isNumber]="true" [isInteger1]="true" [isInteger2]="true"
                  class="form-control none-padding text-center fontSize" appNumberOnly maxlength="6">
                <span class="input-group-text none-padding fontSize"
                  [ngClass]="{'hasError': submittedFormDelivery  && validateCounterFlowAmount && counterType === 0  || validateCounterFlowAmountNumber,'text-view' : disableEditFormDelivery}">&nbsp;&nbsp;&nbsp;L</span>
              </div>
              <div class="input-group align-items-end mb-15">
                <label class="col-form-label none-padding input-group-text span fontSize" style="width:130px"
                  [ngClass]="{'hasError': submittedFormDelivery && form('delivererUserId').errors}">
                  <span>乗務員名</span> <span style="margin-right: 10px;">:</span>
                </label>
                <select formControlName="delivererUserId" (change)="updateDataVehicleUser()" *ngIf="!suggestDelivery"
                  style="text-align-last: center" autocomplete="off"
                  [attr.disabled]="(!form('customerCompanyId').value || formDisable || disableEditFormDelivery) ? '' : null"
                  [ngClass]="{'hasError': submittedFormDelivery && form('delivererUserId').errors,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id  }"
                  id="delivererUserId" class="form-select custom-input-line none-padding input-group-text fontSize">
                  <option *ngFor="let item of listDeliveryUser" [value]="item?.userId">{{item?.userName}}</option>
                  <option [value]="delivererUserId"
                    *ngIf="(listDeliveryUser?.length === 0 && id ) || (!checkUserName && id)">
                    {{delivererUserName}} </option>
                  <option *ngIf=" listDeliveryUser?.length===0 && !delivererUserName && id" disabled>
                    {{'MESS.CM0003'| translate}}</option>
                  <option *ngIf=" listDeliveryUser?.length===0 && delivererUserName && !id" disabled>
                    {{'MESS.CM0003'| translate}}</option>
                </select>
                <img class="drop" src="assets/images/backgrounds/drop.png" alt="">
                <input type="text" class="form-control custom-input-line none-padding text-center fontSize"
                  aria-label="Username" [ngClass]="{'background' : suggestDelivery && !id }" disabled
                  [ngClass]="{'background' :!disableEditFormDelivery}" *ngIf="suggestDelivery"
                  aria-describedby="basic-addon1" formControlName="delivererUserName">
              </div>
              <div class="input-group align-items-end fontSize" style="border: none !important;">
                <span class="input-group-text none-padding" style="border: none !important;"></span>
                <input type="text" disabled style="visibility: hidden;" class="form-control none-padding text-center"
                  aria-label="Username" [value]="''">
              </div>
            </div>
            <div class="col-5">
              <div class="input-group align-items-end mb-15" *ngIf="counterType === 0">
                <span class="input-group-text none-padding span2 fontSize"
                  [class]="{'hasError': submittedFormDelivery && validateConversionRate}"><span>換算率</span> <span
                    style="margin-right: 10px;">:</span></span>
                <input type="text" disabled class="form-control none-padding text-center fontSize"
                  [class]="{'background' :!disableEditFormDelivery ,'hasError': submittedFormDelivery && validateConversionRate}"
                  formControlName="conversionRate" aria-label="Username" aria-describedby="basic-addon1">
              </div>
              <div class="input-group mb-15">
                <label class="col-form-label none-padding input-group-text span2 fontSize"
                  [ngClass]="{'hasError': submittedFormDelivery && form('vehicleCode').errors}"><span>車番</span> <span
                    style="margin-right: 10px;">:</span></label>
                <select formControlName="vehicleCode" id="vehicleCode" (change)="updateDataVehicle()"
                  style="text-align-last: center" autocomplete="off"
                  [attr.disabled]="(!form('customerCompanyId').value ||  !form('delivererUserId').value || formDisable || disableEditFormDelivery) ? '' : null"
                  [ngClass]="{'hasError': submittedFormDelivery && form('vehicleCode').errors,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}"
                  class="form-select custom-input-line none-padding input-group-text fontSize">
                  <option *ngFor="let item of listVehicle" [value]="item?.vehicleCode">{{item?.vehicleCode}}
                  </option>
                  <option [value]="vehicleCode"
                    *ngIf="(listVehicle?.length === 0 && id && suggestProvider) || (listVehicle?.length != 0 && id && suggestProvider && disableEditFormDelivery) || (listVehicle.length != 0 && !suggestProvider && id  && disableEditFormDelivery)|| ( listVehicle.length === 0 && !suggestProvider && id) ">
                    {{vehicleCode}} </option>
                  <option
                    *ngIf="(listDeliveryUser?.length===0 && !form('vehicleCode').value && id) || (listDeliveryUser?.length===0 && form('vehicleCode').value && !id)"
                    disabled>
                    {{'MESS.CM0003'| translate}}</option>
                </select>
                <img class="drop" src="assets/images/backgrounds/drop.png" alt="">
              </div>
              <div class="input-group align-items-end ">
                <span *ngIf="formReceipt.value.temperature !== null && formReceipt.value.temperature !== ''  && id && disableEditFormDelivery" class="input-group-text none-padding span2 fontSize"
                  [ngClass]="{'hasError': submittedFormDelivery && form('temperature').errors|| validateTemperatureForm, 'temperature-boder-none' : formReceipt.value.temperature === null && formReceipt.value.temperature !== '' && id && disableEditFormDelivery}">
                  <span >温度</span>
                  <span  style="margin-right: 10px;">:</span></span>
                <span *ngIf="!id || !disableEditFormDelivery" class="input-group-text none-padding span2 fontSize"
                      [ngClass]="{'hasError': submittedFormDelivery && form('temperature').errors|| validateTemperatureForm}">

                  <span >温度</span>
                  <span  style="margin-right: 10px;">:</span></span>
                <input type="text" class="form-control none-padding text-end fontSize" aria-label="Username"
                  (blur)="convertDecimalC($event,'')" formControlName="temperature" appNumberOnly [isNumber]="true"
                  [isInteger1]="true" maxlength="3"
                  [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                  [ngClass]="{'hasError': submittedFormDelivery && form('temperature').errors || validateTemperatureForm,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id, 'temperature-boder-none' : formReceipt.value.temperature === null && formReceipt.value.temperature !== '' && id && disableEditFormDelivery}">

                <span *ngIf="formReceipt.value.temperature !== null && formReceipt.value.temperature !== '' && id && disableEditFormDelivery" class="input-group-text none-padding margin-custom"
                  [ngClass]="{'hasError': submittedFormDelivery && form('temperature').errors || validateTemperatureForm}">&nbsp;&nbsp;&nbsp;℃</span>
                <span *ngIf="!id || !disableEditFormDelivery" class="input-group-text none-padding margin-custom"
                      [ngClass]="{'hasError': submittedFormDelivery && form('temperature').errors || validateTemperatureForm}">&nbsp;&nbsp;&nbsp;℃</span>
              </div>
            </div>
          </div>

          <div class="row align-items-end mx-3 mt-3 m-0">
            <div class="col-10">
              <div style="height: 30px;">
                <label class="col-form-label none-padding input-group-text fontSize  "
                  *ngIf="form('saleBillingCompanyName').value != '―'"
                  [ngClass]="{'hasError': submittedFormDelivery && form('saleBillingCompanyName').errors}">
                  <span class="span" style="width: 166px;">
                    <span>
                      {{'B4.SALE_FONT_COMPANY_NAME'|translate}}
                    </span>
                    <span style="margin-right: 10px;">:</span>
                  </span>
                  <span style="width: 100%;" class="text-start"
                    [class]="{'background' :!disableEditFormDelivery}">&nbsp;&nbsp;&nbsp;&nbsp;
                    {{form('saleBillingCompanyName').value}}</span></label>
              </div>
            </div>
          </div>
          <div class="row align-items-center m-center mt-4">
            <div class="row content-3">
              <div class="col-6 p-0">
                <div class="row">
                  <div class="col-2 p-0">
                    <div class="text-center align-center fontSize">納入前</div>
                  </div>
                  <div class="col-10">
                    <div class="input-group align-items-end mb-15 mt-2  fontSize"
                      [ngClass]="{'hasError': submittedFormDelivery && (form('startTime').errors) || !validStartTime}">
                      <span class=" span fontSize">
                        <span>
                          開始時間 <span class="req-1" *ngIf="!id || !disableEditFormDelivery">※</span>
                        </span>
                        <span style="margin-right: 10px;">
                          :
                        </span>
                      </span>
                      <!-- <span class=" none-padding btn-active ml-3" (click)="actionUpdateNowTime('startTime')"> NOW
                      </span> -->
                      <input type="text" aria-label="Last name"
                        class="form-control none-padding text-center border-none fontSize"
                        [class]="{'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}"
                        [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                        formControlName="startTime" id="startTime" [isNumber]="true" [isInteger2]="true"
                       (keypress)="onKeypress($event, 'startTime')"
                        (focus)="onFocus('startTime')" (blur)="onBlurTime('startTime')"
                        appNumberOnly [maxLength]="maxLengthTime">
                      <span class=" margin-custom1 border-none input-group-text none-padding pr-span"></span>
                    </div>
                    <div class="input-group align-items-end mb-15">
                      <span *ngIf="formReceipt.value.tankPercentageBefore !== null && formReceipt.value.tankPercentageBefore !== '' && id && disableEditFormDelivery" class=" none-padding span fontSize"
                        [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageBefore').errors ||validateTankDiff}"><span>タンク在庫</span>
                        <span  style="margin-right: 10px;">:</span></span>
                      <span *ngIf="!id || !disableEditFormDelivery" class=" none-padding span fontSize"
                            [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageBefore').errors ||validateTankDiff}"><span>タンク在庫</span>
                        <span  style="margin-right: 10px;">:</span></span>
                      <input  type="text" class="form-control none-padding text-end fontSize border-none" appNumberOnly
                        [isInteger1]="true" [isInteger2]="true" [isNumber]="true" (blur)="convertDecimal($event,'')"
                        aria-label="Username" aria-describedby="basic-addon1" formControlName="tankPercentageBefore"
                        [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                        [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageBefore').errors ||validateTankDiff,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}"
                        maxlength="2">
                      <span *ngIf="formReceipt.value.tankPercentageBefore !== null && formReceipt.value.tankPercentageBefore !== '' && id && disableEditFormDelivery" class=" margin-custom1  none-padding pr-span fontSize"
                        [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageBefore').errors ||validateTankDiff}">&nbsp;&nbsp;&nbsp;%</span>
                      <span *ngIf="!id || !disableEditFormDelivery" class=" margin-custom1  none-padding pr-span fontSize"
                            [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageBefore').errors ||validateTankDiff}">&nbsp;&nbsp;&nbsp;%</span>
                    </div>
                    <div class="input-group align-items-end mb-15">
                      <span *ngIf="formReceipt.value.pressureBefore !== null && formReceipt.value.pressureBefore !== '' && id && disableEditFormDelivery" class=" none-padding span fontSize"
                        [ngClass]="{'hasError': submittedFormDelivery && form('pressureBefore').errors }">
                        <span>圧力</span> <span style="margin-right: 10px;">:</span></span>
                      <span *ngIf="!id || !disableEditFormDelivery" class=" none-padding span fontSize"
                            [ngClass]="{'hasError': submittedFormDelivery && form('pressureBefore').errors }">
                        <span>圧力</span> <span style="margin-right: 10px;">:</span></span>
                      <input type="text" class="form-control none-padding text-end fontSize border-none"
                        aria-label="Username" (blur)="convertDecimalMpa($event,'')" aria-describedby="basic-addon1"
                        formControlName="pressureBefore" appNumberOnly [isNumber]="true" [isInteger2]="true"
                        [maxlength]="maxLengthTime" [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                        [ngClass]="{'hasError': submittedFormDelivery && form('pressureBefore').errors ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id} ">
                      <span  *ngIf="formReceipt.value.pressureBefore !== null && formReceipt.value.pressureBefore !== '' && id && disableEditFormDelivery" class=" margin-custom1  none-padding pr-span fontSize"
                        [ngClass]="{'hasError': submittedFormDelivery && form('pressureBefore').errors }">&nbsp;&nbsp;&nbsp;MPa</span>
                      <span  *ngIf="!id || !disableEditFormDelivery" class=" margin-custom1  none-padding pr-span fontSize"
                             [ngClass]="{'hasError': submittedFormDelivery && form('pressureBefore').errors }">&nbsp;&nbsp;&nbsp;MPa</span>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-2 p-0">
                    <div class="text-center align-center2 fontSize">納入後</div>
                  </div>
                  <div class="col-10">
                    <div class="input-group align-items-end mb-15  mt-2 fontSize"
                      [ngClass]="{'hasError': submittedFormDelivery && form('endTime').errors}">
                      <span class=" span">
                        <span>
                          終了時間 <span class="req-1" *ngIf="!id || !disableEditFormDelivery">※</span>
                        </span>
                        <span style="margin-right: 10px;">
                          :
                        </span>
                      </span>
                      <!-- <span class=" none-padding btn-active ml-3" (click)="actionUpdateNowTime('endTime')"> NOW
                      </span> -->
                      <input type="text" aria-label="Last name"
                        class="form-control none-padding text-center border-none fontSize"
                        [class]="{'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}"
                        formControlName="endTime" [isNumber]="true" [isInteger2]="true"
                        [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                        [maxLength]="maxLengthTime" (keypress)="onKeypress($event, 'endTime')"
                        appNumberOnly id="endTime" (focus)="onFocus('endTime')" (blur)="onBlurTime('endTime')"
                      >
                      <span class=" margin-custom border-none input-group-text none-padding pr-span">
                      </span>
                    </div>
                    <div class="input-group align-items-end mb-15">
                      <span *ngIf="formReceipt.value.tankPercentageAfter !== null && formReceipt.value.tankPercentageAfter !== ''  && id && disableEditFormDelivery" class=" none-padding span fontSize"
                        [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageAfter').errors ||validateTankDiff}">
                        <span>タンク在庫</span> <span style="margin-right: 10px;">:</span></span>
                      <span *ngIf="!id || !disableEditFormDelivery" class=" none-padding span fontSize"
                            [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageAfter').errors ||validateTankDiff}">
                        <span>タンク在庫</span> <span style="margin-right: 10px;">:</span></span>

                      <input type="text" class="form-control none-padding text-end fontSize border-none"
                        aria-label="Username" (blur)="convertDecimal($event,'')" formControlName="tankPercentageAfter"
                        maxlength="2" appNumberOnly [isNumber]="true" [isInteger1]="true" [isInteger2]="true"
                        [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                        [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageAfter').errors || validateTankDiff,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}">
                      <span *ngIf="formReceipt.value.tankPercentageAfter !== null && formReceipt.value.tankPercentageAfter !== '' && id && disableEditFormDelivery" class=" none-padding margin-custom pr-span fontSize"
                        [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageAfter').errors ||validateTankDiff}">&nbsp;&nbsp;&nbsp;%</span>
                      <span *ngIf="!id || !disableEditFormDelivery" class=" none-padding margin-custom pr-span fontSize"
                            [ngClass]="{'hasError': submittedFormDelivery && form('tankPercentageAfter').errors ||validateTankDiff}">&nbsp;&nbsp;&nbsp;%</span>
                    </div>
                    <div class="input-group align-items-end mb-15">
                      <span *ngIf="formReceipt.value.pressureAfter !== null && formReceipt.value.pressureAfter !== ''  && id && disableEditFormDelivery" class=" none-padding span fontSize"
                        [ngClass]="{'hasError': submittedFormDelivery && form('pressureAfter').errors }"><span>圧力</span>
                        <span style="margin-right: 10px;">:</span></span>
                      <span *ngIf="!id || !disableEditFormDelivery" class=" none-padding span fontSize"
                            [ngClass]="{'hasError': submittedFormDelivery && form('pressureAfter').errors }"><span>圧力</span>
                        <span style="margin-right: 10px;">:</span></span>
                      <input type="text" class="form-control none-padding text-end fontSize border-none"
                        aria-label="Username" (blur)="convertDecimalMpa($event,'')" formControlName="pressureAfter"
                        appNumberOnly [isNumber]="true" [isInteger2]="true" maxlength="4"
                        [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                        [ngClass]="{'hasError': submittedFormDelivery && form('pressureAfter').errors ,'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id}">
                      <span *ngIf="formReceipt.value.pressureAfter !== null && formReceipt.value.pressureAfter !== '' && id && disableEditFormDelivery" class=" none-padding margin-custom pr-span fontSize"
                        [ngClass]="{'hasError': submittedFormDelivery && form('pressureAfter').errors }">&nbsp;&nbsp;&nbsp;MPa</span>
                      <span *ngIf="!id || !disableEditFormDelivery" class=" none-padding margin-custom pr-span fontSize"
                            [ngClass]="{'hasError': submittedFormDelivery && form('pressureAfter').errors }">&nbsp;&nbsp;&nbsp;MPa</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="row align-items-center mx-3 mt-2 m-0">
            <div class="col-7">
              <!-- <span *ngIf="!dataTitle">&nbsp;</span> -->
              <span class="fontSize title-image" [class]="{'background' :!disableEditFormDelivery}">
                <span *ngIf="dataTitle"> {{dataTitle}}</span>
              </span>
              <div class=" border border-dark camera" (click)="selectFile()">
                <img *ngIf=" !disableEditFormDelivery " src="assets/images/backgrounds/camera.png" alt=""
                  class="camera-icon">
                <img class="camera-image" [src]="imageURL" *ngIf="imageURL">
              </div>
            </div>
            <div class="col-5">
              <span class="fontSize" style="margin-bottom: 5px; display: block;">備考</span>
              <div class="custom-textarea">
                <textarea class="form-control border border-dark fontSize" formControlName="note"
                  (click)="openDialogNote()" [attr.disabled]="(formDisable || disableEditFormDelivery) ? '' : null"
                  [class]="{'background-blue' :!disableEditFormDelivery&& !id  ,'background-green' :!disableEditFormDelivery&& id }"
                  style="height: 250px !important; font-size: 16px;" maxlength="{{textLenght}}"></textarea>
              </div>
            </div>
          </div>
          <div class="row align-items-end mx-3 mt-3 m-0">
            <div class="col-12">
              <label class="col-form-label none-padding input-group-text fontSize"
                [ngClass]="{'hasError': submittedFormDelivery && form('customerSignature').errors}">
                <span class="span" style="width: 90px;">
                  <span>
                    {{'B4.CUSTOMER_SIGNATURE'| translate}}
                  </span>
                  <span style="margin-right: 10px;">
                    :
                  </span>
                </span>
                <span class="text-start fontSize" [class]="{'background' :!disableEditFormDelivery}"
                  style="width:100%; height: 24px;">
                  <span>{{labelCompanyName}}</span> &nbsp; &nbsp; &nbsp;
                  <span *ngIf="form('customerCompanyName').value">{{' ' +form('customerCompanyName').value}}</span>
                </span>
              </label>
            </div>
          </div>
          <div class="row align-items-end mx-3 mt-3 m-0">
            <div class="col-12">
              <label class="col-form-label none-padding  fontSize d-flex" style="padding-top: 0px !important;"
                [ngClass]="{'hasError': submittedFormDelivery && form('deliveryOfficialName').errors ||form('deliveryOfficialName').errors }">
                <span class="span" style="width: 90px;">
                  <span>
                    {{'B4.DELIVERY_COMPANY_NAME' | translate}}
                  </span>
                </span>
                <span class="fontSize text-start fontSize" style="width:100%; height: 24px; padding-left: 15px;"
                  [class]="{'background' :!disableEditFormDelivery}">
                  <span *ngIf="form('deliveryOfficialName').value">
                    {{form('deliveryOfficialName').value}}
                  </span> &nbsp; &nbsp; &nbsp;
                  <span *ngIf=" form('deliveryOfficeName').value">
                    {{form('deliveryOfficeName').value}}
                  </span>
                </span>
              </label>
            </div>
          </div>
          <div class="row align-items-center mx-3 mt-4">
            <div class="py-2 text-end">
              <label class="fontSize">伝票番号: <span [class]="{'background' :!disableEditFormDelivery}"
                  *ngIf="form('receiptNumber').value">{{' ' + form('receiptNumber').value}}</span>
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<input type="file" class="input-file" #inputFile id="file" accept="image/*"
  (change)="handleFileInput($event.target?.files, $event)" style="display: none">
<ng-template #startTime>
  <ngb-timepicker [(ngModel)]="startTimeData" (ngModelChange)="actionUpdateTime($event, 'startTime',true)">
  </ngb-timepicker>
</ng-template>

<ng-template #endTime>
  <ngb-timepicker [(ngModel)]="endTimeData" (ngModelChange)="actionUpdateTime($event, 'endTime',false)">
  </ngb-timepicker>
</ng-template>


<ng-template #content let-modal size="'small'">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">印刷</h4>
  </div>
  <div class="modal-body">
    <p><strong>印刷する帳票を選択してください。</strong></p>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="checkBoxOption1">
      <label class="form-check-label" for="flexCheckDefault">
        受領書
      </label>
    </div>
    <br>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" [(ngModel)]="checkBoxOption2">
      <label class="form-check-label" for="flexCheckChecked">
        納品書
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-blue" (click)="print()"
      [disabled]="!checkBoxOption2 && !checkBoxOption1">印刷</button>
    <button type="button" class="btn btn-blue" (click)="modal.close()">キャンセル</button>
  </div>
</ng-template>

<div *ngIf="isPrintList">
  <app-template-print-receipt [dataInput]="dataForPrint" [titleHeader]="titleHeader"
    [labelCompanyName]="labelCompanyName" (printDone)="printDoneList()"></app-template-print-receipt>
</div>
